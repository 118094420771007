//media query responsive

@media (max-width:320px) {

  .rewards .bz-forms-list-new.formlist {
    grid-template-columns: none;
  }

  .bz-checkout-page .order-summary-form .order-summary-card .k-form-field {
    flex: 0 0 65% !important;
  }

  .bz-checkout-page .k-button {
    margin: 0rem !important;
  }

  .savedCard>.row>.col-9 {
    width: 72%;
  }

  .bzo-signature-options>.col:last-child {
    width: 50%;
    flex: 0 0 auto;
  }

  .bzo-footer .bzo-timings-sec>.bzo-timing {
    flex: 0 0 100%;
    margin-right: 0;
    margin-bottom: 1rem;
    padding-right: 0;
    border-right: 0;
  }

  .bz-about-service-details {
    font-size: 0.8rem;
  }

  .bzo-viewDetails>span {
    font-size: 0.8rem;
  }

  .bzo-mobile-menu {
    .title>img {
      width: 2.5rem;
      height: 1.8rem;
    }

    .bz-tenet-name {
      font-size: 1rem;
      padding: 0.5rem;
    }
  }

  .bzo-login-dialog>.k-dialog {
    min-width: 310px;
    max-width: 310px;
  }

  .public-layout .bz-navBar {
    padding: 0.6rem 1rem !important;
  }

  .public-layout .App-body {
    padding: 1rem !important;
  }

  .bzo-services-container .k-listview-content .buz-online-list-view-new .bz-service-select-btn-new {
    min-width: 5rem;
  }

  .bz-service-selection {
    .bz-back-btn {
      left: 0.5rem;
    }

    .bz-service-details {
      width: 85%;
      margin: auto;

      >label {
        line-height: 1.2;
      }
    }
  }

  .bz-service-selection.selected {
    .bz-back-btn {
      left: -1rem !important;
    }

  }

  .bz-service-card>.card-body {
    padding: 0.75rem;

    .bz-provider-desc {
      font-size: 90%;
      line-height: 1;
    }
  }

  .bzo-contact-navbar.bzo-fixed-phone-icon-mobile {
    display: none;
  }

  .k-widget.k-window.k-dialog {
    width: calc(100% - 10px) !important;
  }

  .promotion-form-flex-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 8px;
  }
}

@media (max-width:479px) {

  .rewards .bz-forms-list-new.formlist {
    grid-template-columns: 1fr;
  }

  .k-window-title.k-dialog-title {
    font-size: 1rem;
  }

  .bz-ol-login-card {
    >.k-card-body {
      padding: 0;
    }
  }

  .bz-User-profile-card {
    height: auto;
  }




  .bz-checkout-page .k-button {
    margin: 0 0.2rem;
  }

  .bz-checkout-page .bz-book-Appoinment-dialog .k-dialog {
    width: 100% !important;
    max-width: 19rem;

    .k-dialog-content>div {
      margin: 0 0 20px 0 !important;
    }

    .k-button {
      margin: 0 0.2rem !important;
    }
  }

  .bz-checkout-page {
    .checkout-panelbar-wrapper {
      .k-item.k-panelbar-header {
        .text-break>span {
          display: block;
        }
      }
    }
  }

  .public-layout {
    .bz-navBar {

      .bzo-mobile-menu {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }

      .bz-userDetails-sec {
        position: absolute;
        right: 2.25rem;
      }

      .k-icon.k-i-menu {
        font-size: 1.5rem;
      }
    }

  }

  .bz-ol-login-card {
    height: calc(100vh - 6.9rem);

    .k-button-group-stretched {
      flex-direction: column;

      .k-button {
        margin: 0.5rem 0.2rem;
        padding: 0.375rem 0.5rem;
        font-size: 0.875rem;
      }
    }
  }

  .bz-checkout-page {
    .k-grid-header .k-header>.k-link {
      font-size: 0.85rem;
      width: auto;
    }

    .bz-coupon-field .k-button {
      margin: 0;
    }

  }

  .bzo-notification {
    min-width: 96%;
    z-index: 999;

    .k-notification-wrap {
      align-items: center;
    }
  }

  .bzo-window {
    margin: 0.4rem;

  }

  .bz-service-dropdown>.form-group {
    margin-bottom: 0 !important;
  }

  .bz-fixed-service {
    .bz-upcomingappoinment-sec {
      background-color: white;

      .bz-upcoming-appoinments-card {
        padding: 0 1.5rem;
      }
    }
  }

  .bz-slotSelection-Container {
    .bz-provider-container {

      position: fixed;
      top: 12.4rem;
      background-color: #fff;
      z-index: 2;
      width: 100%;
      left: 0;
      right: 0;
      padding: 0 15px;
    }

    .bz-provider-container.selected {
      display: none;
    }
  }

  .bz-calender-sec>.k-calendar {
    z-index: 1;
  }

  .bzo-provider-sec {
    .provider-img-new {
      display: flex;
      justify-content: center;
      margin-bottom: 0;

      >img {
        width: 5rem;
        height: 5rem;
      }
    }


  }





  .bzo-services-container {
    padding-top: 0;
    margin-bottom: 1rem;
  }

  .bzo-mobile-menu {
    .title>img {
      width: 3rem;
      height: 2rem;
    }

    .bz-tenet-name {
      font-size: 1.3rem;
      padding: 0.5rem;
    }
  }

  .bzo-contact-navbar.bzo-fixed-phone-icon-mobile {
    bottom: 10% !important;
  }

  .bzo-services-container .bzo-service-list-card .service-title {
    display: block;
    margin-bottom: 0.25rem;
  }


  .bzo-footer .bzo-timings-sec>.bzo-timing {
    padding-right: 0.5rem;
    margin-right: 0.5rem;

  }

  .promotion-form-flex-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 8px;
  }
}

@media(min-width: 651px) and (max-width: 767px) {

  .custom-grid-box {
    .bzc-sessions-list-card-new.bz-classes-checkout {
      .card-body {
        padding: 1rem !important;
      }

      .bzc-sessions-list-row-new {
        display: grid;
        grid-template-columns: 1fr 1fr !important;

        .section-2 {
          border-left: 0 !important;
          border-right: 0 !important;
          width: 100% !important;
        }

        .section-3 {
          .bz-main-provider {
            gap: 10px 0 !important;
            flex-wrap: wrap;
          }
        }
      }
    }
  }

  .promotion-form-flex-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 8px;
  }
}

@media (min-width: 651px) and (max-width:800px) {
  .groupclass-session-list {
    grid-template-columns: 1fr !important;

    .section-1 {
      grid-area: unset !important;
    }

    .section-2 {
      grid-area: unset !important;
    }
  }

  .promotion-form-flex-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
  }
}

@media (max-width:650px) {
  .custom-grid-box {
    .bzc-sessions-list-card-new.bz-classes-checkout .bzc-sessions-list-row-new {
      display: grid;
      grid-template-columns: 1fr !important;
      padding: 0.5rem;
      grid-row-gap: 15px !important;

      .section-2 {
        border: 0 !important;
        width: 100% !important;
      }

      .section-3 {
        .bz-main-provider {
          gap: 10px 0 !important;
          flex-wrap: wrap;
        }
      }
    }
  }

  .bzc-sessions-list-card-new .bzc-sessions-list-row-new .section-3 {
    border: none;
    padding-left: 0;
  }

  .groupclass-session-list {
    grid-template-columns: 1fr !important;

    .section-1 {
      grid-area: unset !important;
    }

    .section-2 {
      grid-area: unset !important;
    }
  }

  .bzc-services-list-new {
    grid-template-columns: none;
    padding: 35px 20px 20px 20px !important;
    justify-content: left;

    .section-1 {
      border: 0px;
    }

    .section-2 {
      .duration-icon {
        margin-left: -2px;
      }
    }
  }

  .bzc-sessions-list-card-new {

    .card-body {
      padding: 35px 20px 20px 20px;
    }

    .bzc-sessions-list-row-new {
      grid-template-columns: none;
      justify-items: left;

      .section-1 {
        justify-self: left;
      }
    }
  }

  .bzc-packages-list-card-new {

    .card-body {
      padding: 35px 20px 20px 20px;
    }

    .bzc-packages-list-row-new {
      grid-template-columns: none;
      align-items: center;
      justify-items: left;

      .section-2 {
        border-left: 0;
      }

      .section-4 {
        justify-self: left;
      }

      .section-5 {
        grid-row: auto;
        justify-self: left;
        margin-bottom: 10px;
      }

      .section-6 {
        grid-area: auto;
        justify-self: left;
        width: 100%;
      }

      .section-3 {
        border-left: 0;
        margin-bottom: 0;
      }

      .grid-parent-container {
        padding-left: 0;
        display: block;
      }

      .child-2 {
        border-left: 0 !important;
      }
    }

  }

  @media (max-width: 650px) {
    .bzc-packages-list-card-new .bzc-packages-list-row-new .section-4 {

      text-align: right;

    }

    .appointmentlistmain {
      flex-direction: column;
    }

    .apptcardslect {
      justify-content: space-between;
    }
  }

  .bz-main-provider {
    .bz-calender-icon {
      img {
        width: 25px;
      }
    }
  }

  .bz-main-provider {
    .bz-provider-img {
      img {
        width: 50px;
        height: 50px;
      }
    }
  }

  .bz-time-section {
    .bz-calender-icon {
      img {
        width: 25px;
        height: 25px;
      }
    }
  }


  .bz-checkbox {
    padding: 10px 0px;
    display: block;
  }

  .bzc-packages-list-card-new .bzc-packages-list-row-new .child-2 .bz-main-provider>.bz-provider-name {
    padding-left: 0;
  }

  .promotion-form-flex-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 8px;
  }
}

@media (min-width:480px) and (max-width:767px) {
  .bz-ol-login-card {
    height: auto;
  }

  .public-layout .App-body {
    padding: 1rem 1rem !important;
  }

  .promotion-form-flex-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 8px;
  }


}

@media screen and (min-width:576px) and (max-width:742px) {

  .bz-bussiness-hours-row>.section-2 .bzo-timing:nth-child(2),
  .bz-bussiness-hours-row>.section-2 .bzo-timing:nth-child(4),
  .bz-bussiness-hours-row>.section-2 .bzo-timing:nth-child(6) {
    border-right: 0;
  }
}

@media screen and (max-width:575px) {
  .bzo-footer .bz-bussiness-hours-row {
    flex-direction: column;
    margin-top: 1.5rem !important;

    >.section-2 {
      padding-left: 0;
      margin-left: 0;
      border-left: 0;
      margin-top: 1rem;
    }

    >.section-1 {
      text-align: left;
    }
  }

  .bzo-footer .bz-bussiness-address-row {
    flex-direction: column;

    >.section-2 {
      padding-left: 0;
      margin-left: 0;
      border-left: 0;
      margin-top: 1rem;
    }
  }

  .bz-bussiness-hours-row>.section-2 .bzo-timing:nth-child(2),
  .bz-bussiness-hours-row>.section-2 .bzo-timing:nth-child(4),
  .bz-bussiness-hours-row>.section-2 .bzo-timing:nth-child(6) {
    border-right: 0;
  }

  .promotion-form-flex-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 8px;
  }
}

@media (max-width:767px) {
  .complimentary-btns .bz-schedule-btn-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .coupon-info {
    .coupon-flex {
      margin-bottom: 0 !important;
    }
  }

  .mble-title {
    font-size: 1rem !important;
    font-weight: 800 !important;
  }

  .public-layout .App-body {
    padding: 1rem 1.25rem !important;
    background-color: #fefefe;
  }

  .public-layout .bz-navBar {
    padding: 0.1rem 1.25rem !important;
  }

  .bz-navBar {
    position: sticky;
    z-index: 99;
    top: 0;
  }

  .bz-checkout-page {
    .k-dialog {
      margin: 0.5rem;

      .k-dialog-content {
        width: auto !important;

        .buzops-type-signature {
          font-size: 25px !important;
          height: auto !important;
        }
      }

      .signature-area {
        width: auto !important;
        height: auto !important;
        margin: 0 1rem;
      }

      .signature-upload {
        .k-upload .k-dropzone {
          padding: 0.3rem 0.7rem;

          .k-dropzone-hint {
            font-size: 0.88rem;
          }
        }
      }
    }
  }

  .bz-ol-login-card h5.h5 {
    font-size: 1.25rem !important;
  }

  .bz-service-card.bz-shadow {
    margin-bottom: 1rem !important;
  }

  .bz-about-service-sec {
    text-align: left;
  }

  .bzo-primary-heading {
    font-size: 1.25rem;
  }

  .bzo-footer h2 {
    font-size: 1.5rem;
  }

  .bzo-viewDetails {
    text-align: left !important;
  }

  .bzo-services-container .k-listview-content .buz-online-list-view-new {
    border: 0;
    background-color: #fff;
    box-shadow: 0px 0px 5px #dedede;
    -moz-box-shadow: 0px 0px 5px #dedede;
    -webkit-box-shadow: 0px 0px 5px #dedede;
    padding: 1.5rem;
    border-radius: 0.5rem;
    margin: 0.1rem;
    margin-bottom: 1.25rem;
    position: relative;

    .col-md-9 {
      padding: 0;

      >.align-middle {
        padding: 0 !important;
      }

      .price-blk {
        position: absolute;
        left: 0;
        width: auto;
        bottom: -1.4rem;
      }
    }

    .bz-name.appoinments {
      margin: 0.25rem 0;
    }

    .btn-blk {
      padding-right: 0 !important;

      .k-button {
        margin-right: 0;
      }

    }
  }

  @media (max-width: 767px) {
    .bzo-services-container .k-listview-content .section-5 {
      text-align: right;
    }
  }

  .bzo-services-container .k-listview-content .buz-online-list-view-new.bz-membership-rate .btn-blk {
    justify-self: flex-end
  }

  .bz-group-events-new {
    .card-body {
      padding: 1.5rem;

      .bzc-sessions-list-row-new {
        position: relative;
      }

      .section-4 {
        >.bz-time-section {
          h6 {
            padding-left: 0;
          }
        }
      }

      .section-5.rate {
        padding: 0;
        position: absolute;
        bottom: 0.4rem;
        left: 0;
      }
    }

  }

  .bz-privacyPolicy-dialog.bzo-auto-height {
    height: 98%;
    top: 0;
  }

  .bzo-leftside-filter-sec {

    .bzo-filter-heading,
    .bz-check-box-filter {
      display: none !important;
    }
  }

  .bzo-leftside-filter-sec .bzo-leftside-filter-container>.bzo-search-service {
    max-width: inherit;
  }

  .bzo-leftside-filter-sec .bzo-leftside-filter-container {
    background-color: transparent;
    padding: 0;
  }

  .bzo-leftside-filter-container-new {
    display: none;
  }


  .bzc-sessions-list-card-new {
    border-radius: 0.5rem;
    margin: 0.1rem;
    border: 0px;
    box-shadow: 0px 0px 5px #dedede;
    -webkit-box-shadow: 0px 0px 5px #dedede;
  }

  .bzo-services-filter-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    position: sticky;
    bottom: 0;
    background-color: #fff;
    z-index: 9;
    position: fixed;
    bottom: 0;

    &::after {
      content: "";
      background-color: #fefefe;
      position: absolute;
      top: 0;
      bottom: 0;
      right: calc((100vw - 100%) / -2);
      left: calc((100vw - 100%) / -2);
      z-index: 10;
      border-top: 1px solid #d9d9d9;
    }

    >div {

      text-align: center;
      text-transform: uppercase;
      padding: 14px 0 14px 0px;
      flex: 0 0 50%;
      font-size: 14px;
      letter-spacing: .9px;
      height: 50px;
      z-index: 99;

    }

    .bzo-sort {
      border-right: 0.1rem solid #d9d9d9;


      .fa-sort {
        margin-right: 0.4em;
        font-size: 1.4rem;
        vertical-align: bottom;
        color: #868080;
      }
    }

    .bzo-filters {

      cursor: pointer;

      .fa-filter {
        margin-right: 0.5rem;
        font-size: inherit;
        color: #868080;
      }

    }

  }

  .bzo-filter-dialog .k-checkbox-label {
    font-weight: 500;
    margin-left: 0.5rem;
  }

  .bzo-filter-dialog .form-check {
    margin-bottom: 0.75rem;
    padding-left: 0;
  }

  .bzo-provider-sec>div {
    flex-direction: column;
  }

  .provider-text {
    .bzo-provider-name {
      text-align: center;
      font-size: 1.75rem;

    }

    .bzo-provider-contact-details-sec {
      margin: 0.5rem 0;
      justify-content: center;

      div {
        flex: 0 0 100% !important;
        text-align: center;
      }
    }

    p {
      text-align: justify;
    }
  }

  .bzc-packages-list-card-new {
    .bzc-packages-list-row-new {
      display: block;

      .grid-parent-container {
        padding-left: 0;
        border-left: 0;
      }
    }
  }

  .bzc-packages-list-card-new.bz-pkg-new {
    border: 0;
    background-color: #fff;
    box-shadow: 0px 0px 5px #dedede;
    -moz-box-shadow: 0px 0px 5px #dedede;
    -webkit-box-shadow: 0px 0px 5px #dedede;
    border-radius: 0.5rem;
    margin: 0.1rem;
    margin-bottom: 1.25rem;

    .card-body {
      padding: 1.5rem;

      .bzc-packages-list-row-new {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0;
        position: relative;

        .section-1 {
          grid-row: initial;
          width: 100%;
          margin-bottom: 0.5rem;

          >.bzc-service-name {
            display: block;
          }

          .bz-availability-packages {
            >h5 {
              font-size: 0.875rem;
              font-weight: 500;
              color: #9e9e9e;
            }
          }
        }

        .section-2 {
          margin-bottom: 0.5rem;
          width: 100%;

          .child-2 {
            >.bz-main-provider {
              >.bz-calender-icon {
                display: none;
              }

              >.bz-provider-name {
                // padding-left: 0;
              }
            }
          }

          .bz-packages-tags-new .btn {
            padding-left: 0 !important;
          }
        }

        .section-3 {
          margin-bottom: 0.5rem;
          // position: absolute;
          left: 0;
          bottom: 0;
        }

        .section-4.bzc-col-btn {
          justify-self: flex-end;
          width: 100%;
        }

        .grid-parent-container {
          padding-left: 0;
          border-left: 0;
        }
      }
    }

  }

  .bzc-sessions-list-card-new {
    .bzc-sessions-list-row-new {
      grid-template-columns: none;
      justify-items: left;
      gap: 5px;

      .section-1 {
        justify-self: left;
      }
    }
  }

  .bzc-services-list-new {
    padding: 35px 20px 20px 20px !important;
    grid-template-columns: none;
    justify-items: left;
    justify-content: left;
    gap: 5px;

    .section-1 {
      border-right: 0;
    }

    .section-2 {
      .duration-icon {
        margin-left: -2px;
      }
    }
  }

  .order-summary-title {
    display: inline-block;
    width: 50px;
  }

  .bzc-purchase-class-list-view {
    .bz-purchase-class-list {
      .k-listview {
        .k-listview-content {
          .bz-group-events-new {


            .bzc-sessions-list-row-new {
              grid-template-columns: 1fr;
              justify-items: flex-start;
              align-items: flex-start;
              grid-row-gap: 12px;

              >.section-2 {
                display: none;
              }

              >.section-3 {
                grid-area: 1/1/1/2;
                margin-bottom: 0.5rem;
                border-left: 0px dotted #ccc;
                padding-left: 0;

                .bz-main-provider>.bz-provider-img img {
                  width: 50px;
                  height: 50px;
                }
              }

              >.section-4 {
                h6 {
                  padding-left: 0;
                }

                margin-top: 0.1rem;
              }

              >.section-5 {
                margin-top: 0.15rem;
              }

              >.section-6 {
                align-items: center;
                display: flex;

              }
            }
          }
        }
      }
    }
  }

  .bz-membership-rate {
    .col-md-9 .price-blk {
      position: unset !important;
    }
  }
}

@media screen and (min-width:992px) and (max-width:1056px) {
  .bz-bussiness-hours-row>.section-2 .bzo-timing {
    padding-right: 1rem;
    margin-right: 1rem;
  }

  .bz-bussiness-hours-row>.section-2 .bzo-timing:nth-child(2) {
    border-right: 0;
  }

  .bz-bussiness-hours-row>.section-2 .bzo-timing:nth-child(4) {
    border-right: 0;
  }

  .bz-bussiness-hours-row>.section-2 .bzo-timing:nth-child(6) {
    border-right: 0;
  }

  .promotion-form-flex-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
  }
}

@media screen and (min-width:901px) and (max-width:991px) {
  .bz-bussiness-hours-row>.section-2 .bzo-timing:nth-child(4) {
    border-right: 0;
  }
}

@media (max-width:991px) {

  .public-layout {
    .bz-navBar {
      .bz-verticalLine {
        padding: 0 0.5rem !important;
      }

      .k-menu-link {
        padding: 0.5rem !important;
      }

      .bzo-mobile-menu {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }

      .bz-side-menu {
        .k-drawer {
          color: #fff;
          background-color: #008db9;

          .k-drawer-item:hover,
          .k-drawer-item:focus,
          .k-drawer-item.k-state-selected {
            background-color: #077ca0;
            color: #fff;
          }

          .k-drawer-item:focus {
            box-shadow: none;
          }
        }
      }
    }

  }

  .k-menu.k-menu-horizontal:not(.k-context-menu) {
    padding: 0 0 0 1rem !important;
  }

  .bz-clock,
  .bz-logout,
  .bzo-greetmsg {
    display: none;
  }

  .bz-about-service-sec {
    .bzo-text {
      display: none;
    }

    .bzo-icon {
      margin-right: 0.5rem;
    }

    .bz-service-select-btn-new {
      padding: 0.4rem 0.6rem !important;
    }
  }

  .bz-showHide-description a {
    display: block;
    margin-top: 1rem;
    text-align: center;
    color: #008db9;
    text-transform: capitalize;
    font-weight: 600;
  }

  .bzo-about-section .card-body {
    padding-bottom: 0;
  }

  .bzo-contact-navbar.bzo-fixed-phone-icon-mobile {
    position: fixed;
    bottom: 50%;
    z-index: 999999999999;
    background-color: #008db9;
    right: 0;
    padding: 0.6rem;
    border-top-left-radius: 2.5rem;
    border-bottom-left-radius: 2.5rem;
    padding-right: 0;
    cursor: pointer;
    border: 1px solid #5caec7;
    border-right: 0;

    .fa-phone-volume {
      margin-right: 0.7rem;
    }



  }

  .bz-checkout-page .order-summary-form {
    padding-left: 0;
    padding-right: 0;
  }

  .public-layout .bz-navBar .bzo-mobile-menu .bz-userDetails-sec-mobile-view .k-button-icon {
    min-width: auto;
    margin: 0;
  }

  .bzo-footer .bz-bussiness-hours-row {
    margin-top: 3rem;
  }
}

@media screen and (min-width:743px) and (max-width:900px) {

  .bz-bussiness-hours-row>.section-2 .bzo-timing:nth-child(3),
  .bz-bussiness-hours-row>.section-2 .bzo-timing:nth-child(6) {
    border-right: 0;
  }
}

@media (min-width:768px) {
  .bzo-service-list-card {

    .bzo-service-btn {
      position: relative;
      bottom: 1rem;
    }
  }

  .bzo-services-filter-mobile {
    display: none;
  }

  .bzo-provider-sec {
    >.d-flex {
      >div:first-of-type {
        flex: 0 0 4.5625rem;
      }

      >div:last-of-type {
        flex: 0 0 80%;
        min-width: 80%;
      }
    }
  }

  .bzo-filter-dialog {
    display: none;
  }

  .bzo-hide-service-search {
    display: none;
  }

  .bzc-purchase-class-list-view {
    .bzc-purchase-class-list-row.bz-class-selected {
      .bzc-sessions-list-card-new.bz-noPaid-group {
        >.card-body {
          padding: 10px;

          >.bzc-sessions-list-row-new {
            // grid-template-columns: 1fr 1fr;
          }
        }
      }
    }
  }

  .promotion-form-flex-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
  }
}

@media (min-width:768px) and (max-width:1024px) {
  .custom-grid-box {
    .bzc-sessions-list-card-new.bz-classes-checkout {
      .bzc-sessions-list-row-new {
        .section-2 {
          border-left: 0 !important;
          border-right: 0 !important;
          padding-left: 0 !important;
        }
      }
    }
  }

  .add-class-form {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
  }

  .addClassForm-grid2 {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
  }

  .public-layout {
    .App-body {
      padding: 1rem 1rem !important;
    }

    .appsidebaronlinepage.App-body {
      padding-bottom: 2rem !important;
    }

    .k-appbar {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
  }

  .public-layout .bz-navBar .k-menu-link {
    padding: 0.5rem;
  }




  .bz-User-profile-card {
    height: calc(100vh - 117px);
  }

  .bzo-provider-sec .provider-text .bzo-provider-contact-details-sec>div {
    flex: 0 0 50%;
    width: 50%;
  }

  .bzo-leftside-filter-sec>h5 {
    margin-bottom: 1rem;
    font-size: 1rem;
  }
}

@media (max-width:767px) and (orientation:landscape) {

  .bzo-provider-sec .provider-text .bzo-provider-name {
    font-size: 1.25rem;
  }

  .bzo-provider-sec .provider-text .bzo-provider-contact-details-sec {
    margin: 0.5rem 0;
  }

  .bzo-provider-sec .provider-text .bzo-provider-contact-details-sec>div {
    flex: 0 0 auto;
  }

}

@media (max-width:991px) and (orientation:landscape) {

  .bz-User-profile-card,
  .bz-ol-login-card {
    height: auto;
  }

  .bz-checkout-page {
    .typed-signature-styles {
      max-height: 142px !important;
      overflow: auto;
    }
  }

  .bzo-signaturepad canvas {
    height: 170px;
  }

  .bz-privacyPolicy-dialog.bzo-auto-height {
    height: 98%;
    top: 0;
  }

  .bzo-login-dialog>.k-dialog {
    overflow: auto;
  }
}

@media (max-width:1024px) {
  .bzo-PoweredBy {
    left: -2.7rem !important;
  }

  .bzo-leftside-filter-sec {
    min-height: auto;
    padding-left: 0;
    padding-top: 0;
    border-right: 0;

    .bzo-leftside-filter-container {
      margin-top: 0.9rem;

      >div {
        display: flex;
      }

      .form-check {
        margin-right: 1rem;
        flex: 0 0 auto;

        label {
          vertical-align: baseline;
        }
      }
    }
  }



}

@media (min-width:1024px) {
  .bzo-window {
    width: 940px !important;
    right: 1rem !important;
    height: calc(100vh - 2rem) !important;
    z-index: 99999 !important;
    border: none;

  }

  .bz-about-service-sec .bzo-icon {
    display: none;
  }
}

@media (max-width:1023px) {
  .bzo-window {
    left: 1rem !important;
    right: 1rem !important;
    height: calc(100vh - 2rem) !important;
    max-width: calc(100% - 2rem) !important;
    z-index: 99999 !important;
    margin: 0;
    border: none;

  }
}

@media (min-width:1025px) {
  .bzo-provider-sec {
    >.d-flex {
      >div:first-of-type {
        flex: 0 0 4.5625rem;
      }

      >div:last-of-type {
        flex: 0 0 calc(100% - 6rem);
        max-width: calc(100% - 6rem);
      }
    }
  }
}

@media (min-width:1200px) and (max-width:1399px) {

  .bzo-window {
    max-width: 70%;
    width: 940px !important;
    border: none;
  }

  .promotion-form-flex-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
  }

}

@media screen and (min-width: 1366px) {
  .add-class-form {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .addClassForm-grid2 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .bzc-grid.bz-invoiceGrid {
    table {
      width: 100%;
    }

    colgroup {
      col:not(:last-child) {
        width: 13.5%;
      }
    }
  }


  .bzc-grid.bz-invoiceGrid.billings {
    table {
      width: 100% !important;
    }

    colgroup {
      col:first-child {
        width: 50px;
      }

      col:nth-child(2) {
        width: 180px;
      }

      col:nth-child(3) {
        width: 150px;
      }

      col:nth-child(4) {
        width: 170px;
      }

      col:nth-child(5) {
        width: 180px;
      }

      col:nth-child(6) {
        width: 150px;
      }

      col:nth-child(7) {
        width: 150px;
      }

      col:nth-child(8) {
        width: 180px;
      }

      col:nth-child(9) {
        width: auto;
        min-width: 200px;
      }
    }

  }

  .bzc-grid.bz-invoiceGrid.billings.can-make-payment {
    colgroup {
      col:nth-child(8) {
        width: auto;
        min-width: 200px;
      }
    }

  }

  .bzc-grid.bz-invoiceGrid.submenubillings {
    colgroup {
      col:nth-child(1) {
        width: 180px;
      }

      col:nth-child(2) {
        width: 150px;
      }

      col:nth-child(3) {
        width: 170px;
      }

      col:nth-child(4) {
        width: 180px;
      }

      col:nth-child(5) {
        width: 150px;
      }

      col:nth-child(6) {
        width: 150px;
      }

      col:nth-child(7) {
        width: auto
      }
    }

  }

  .promotion-form-flex-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
  }
}

@media (min-width:1057px) and (max-width:1389px) {
  .bz-bussiness-hours-row {
    >.section-2 {
      .bzo-timing:nth-child(2) {
        border-right: 0;
      }

      .bzo-timing:nth-child(4) {
        border-right: 0;
      }

      .bzo-timing:nth-child(6) {
        border-right: 0;
      }
    }
  }

  .promotion-form-flex-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
  }
}

@media only screen and (max-width:1500px) {
  .bzo-viewDetails {
    text-align: right;
  }
}

@media (min-width:1400px) and (max-width:1500px) {
  .toggle-switch-container {
    align-items: flex-end;

    >.section-classItem.toggle-switch-btn {
      .k-label {
        max-width: 14rem;
      }
    }
  }

  .promotion-form-flex-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
  }
}

@media (min-width:1501px) and (max-width:1535px) {
  .toggle-switch-container {
    >.section-classItem.toggle-switch-btn {
      .section-notification {
        margin-left: 0.2rem;
      }
    }
  }
}

@media (min-width:1025px) and (max-width:1261px) {
  .toggle-switch-container {
    align-items: flex-end;

    >.section-classItem.toggle-switch-btn {
      .k-label {
        max-width: 14rem;
      }
    }
  }
}

@media (min-width:1240px) and (max-width:1300px) {
  .toggle-switch-container {
    >.section-classItem.toggle-switch-btn {
      .section-notification {
        margin-left: 0.2rem;
      }
    }
  }
}

@media (min-width:1400px) and (max-width:1600px) {
  .bz-bookings-area>.row>.col-xxl-4 {
    flex: 0 0 auto;
    width: 37.3333333333%;
  }

  .bz-duration-section {
    max-height: calc(100vh - 28rem) !important;
  }

  .splitted .bz-calender-sec>.k-calendar>.k-calendar-view .k-calendar-tbody .k-calendar-th {
    width: 43px;
  }

  .splitted .bz-calender-sec>.k-calendar>.k-calendar-view .k-calendar-td>.k-link {
    width: 40px;
    height: 40px;
  }

  .splitted .bz-duration-section {
    max-height: calc(100vh - 30rem) !important;
  }

  .bz-recurence-row {
    padding-bottom: 1rem;
  }

  .row.bz-recurence-row.selected .bz-recurence {
    width: 85%;
  }

}

@media screen and (min-width:1400px) {
  .bz-group-services-list.bz-past-GroupServices.statuses-alignment {
    .bzc-sessions-list-card {
      .bzc-sessions-list-row {
        align-items: flex-start;
        grid-template-columns: 11.5rem 12rem 10rem 10rem 8rem;
        justify-content: space-between;

        >.bzc-col-1 {
          flex: 0 0 10rem;
          word-break: break-all;
        }
      }
    }
  }
}

@media (min-width:1500px) and (max-width:1600px) {
  .bzo-provider-sec {
    >.row {
      >.col-xxl-2 {
        flex: 0 0 auto;
        width: 20.4%;
      }

      >.col-xxl-10 {
        flex: 0 0 auto;
        width: 79.6%;
      }
    }
  }

  .bzo-footer .bzo-timings-sec>.bzo-timing {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 1rem;
    padding-right: 1.5rem;
    border-right: 0px solid #000;
    border-radius: 0;
    margin-bottom: 1rem;
    margin-right: 1.5rem;
    padding-bottom: 0;

    >span:first-child {
      font-weight: 600;
    }
  }
}

@media screen and (min-width:1400px) and (max-width:1720px) {
  .bz-bussiness-hours-row {
    >.section-2 {
      .bzo-timing:nth-child(3) {
        border-right: 0;
      }

      .bzo-timing:nth-child(6) {
        border-right: 0;
      }
    }
  }
}

@media screen and (min-width:1721px) {
  .bz-bussiness-hours-row>.section-2 .bzo-timing:nth-child(4) {
    border-right: 0;
    padding-right: 0;
    margin-right: 0;
  }

  .bz-myprofile-container .bz-content-text-row .bz-content-body {
    width: 600px;
  }
}

@media screen and (min-width:1998px) {
  .bz-bussiness-hours-row>.section-2 .bzo-timing:nth-child(4) {
    border-right: 2px solid #6eb8ce;
    padding-right: 1.5rem;
    margin-right: 1.5rem;
  }
}

@media only screen and (min-width:1524px) {
  .manage-tax-container {
    >.col-xl-3 {
      width: 20%;
    }
  }
}

@media only screen and (min-width:1500px) {
  .bzo-viewDetails {
    text-align: right;
  }

  .bz-cancellation-page-new .k-listview .bz-cancellation-card-row.bz-charge-amount {
    grid-template-columns: 14rem 11rem 18.5rem;
  }
}

@media screen and (min-width:991px) and (max-width:1200px) {
  .bz-coupon-field {
    .input-group {
      flex-wrap: nowrap;

      >.k-form-field {
        flex: unset !important;
      }
    }
  }

}

@media screen and (min-width:900px) and (max-width:1150px) {
  .bz-cancellation-inhouse.bz-cancellation-page-new {
    .k-listview {
      .bz-cancellation-card-row.bz-charge-amount {
        grid-template-columns: 13.125rem 20rem 18.75rem;
      }
    }
  }
}

@media screen and (min-width:621px) and (max-width:1290px) {
  .bz-cancellation-page-new .k-listview .bz-cancellation-card-row.bz-charge-amount {
    grid-template-columns: 14rem 21rem;
    justify-content: flex-start;
    justify-content: space-between;
  }
}

@media (max-width:380px) {
  .bzo-footer {
    padding: 2.5rem 2rem 1.5rem;
  }

  .bzo-footer .bzo-timings-sec>.bzo-timing {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .bzo-login-dialog>.k-dialog {
    min-width: 350px;
    max-width: 350px;
  }

  .bzo-login-dialog .k-dialog-content .bz-other-login-option .bzo-reset-pwd {
    margin-right: 0.5rem;
    padding-right: 0.5rem;
  }
}

@media (min-width:411px) and (max-width:479px) {
  .bzo-login-dialog>.k-dialog {
    min-width: 380px;
    max-width: 380px;
  }
}

@media (max-width:360px) {
  .bz-checkout-page .order-summary-form .order-summary-card .k-form-field {
    flex: 0 0 72%;
  }

  .bz-ol-login-card {
    height: auto;
  }

  .bz-about-service-details>div {
    padding: 0 0.3rem;
  }

  .bzo-mobile-menu .bz-tenet-name {
    font-size: 1.2rem;
  }


}

@media (max-width:670px) and (orientation:landscape) {
  .bzo-signaturepad canvas {
    height: 127px;
  }

  .bz-about-service-details>div {
    padding: 0 0.3rem;
  }
}

@media only screen and (min-width:480px) and (max-width:992px) {
  .bz-book-Appoinment-dialog .k-dialog {
    width: calc(100% - 1rem) !important;
  }

  .bz-book-Appoinment-dialog.bz-applyCoupon-dialog .k-dialog {
    max-width: 30rem;
  }

  .bz-book-Appoinment-dialog.bz-colon-centered .k-dialog {
    width: auto !important;
    min-width: 26rem;
  }

  .bz-book-Appoinment-dialog .k-dialog .k-dialog-content>div {
    margin: 0 auto 1.5rem !important;
  }

  .bz-package-purchased-success {
    .k-dialog {
      min-width: 26rem;
      width: 28rem !important;

      .k-dialog-content {
        >.ready-to-book-appt-dialog {
          margin-bottom: 0 !important;
        }
      }

    }
  }
}

@media (max-width:330px) {
  .bzo-footer .bzo-timings-sec>.bzo-timing {
    border-right: 0;
  }
}




// class css
//responsive css start
@media screen and (max-width:1920px) {
  .bz-payment-transanction-grid-blk.transactionspayments {
    max-width: 100%;
   > .k-grid{
     colgroup{
      col{
        &:nth-child(5){
          width: 6rem;
          min-width: auto;
        }
        &:nth-child(6){
          width: 6rem;
          min-width: auto;
        }
        &:nth-child(8){
          width: 8rem;
          min-width: auto;
        }
      }
     }
   }
  }
}
@media screen and (min-width:1920px) {
  .bz-inhouse-classes .bzc-purchase-class-list-view>.bzc-purchase-class-list-row.bz-class-selected .bzc-sessions-list-row {
    grid-template-columns: repeat(5, 1fr) 8rem;
  }

  .bz-inhouse-classes .bzc-purchase-class-list-view>.bzc-purchase-class-list-row.bz-class-selected .bzc-sessions-list-row>.bzc-col-btn {
    grid-area: auto;
  }

  .checkout-panelbar-wrapper {
    .package-redeem-card-blk>.package-redeem-card {
      .package-item {
        width: 22rem !important;
      }
    }
  }
}

@media (min-width: 1900px) {

  .bzc-purchase-class-list-view .k-listview-content .bzc-sessions-list-row {
    grid-template-columns: repeat(5, 1fr) 8rem;
  }
}

@media screen and (min-width:1750px) and (max-width:1920px) {
  .checkout-panelbar-wrapper {
    .package-redeem-card-blk>.package-redeem-card {
      .package-item {
        width: 33.33% !important;
      }
    }
  }
}

@media screen and (min-width:1601px) {
  .bz-cancellation-page .bz-cancellation-card-row.zero-dollar-payment.payment-profile {
    grid-template-columns: repeat(5, 1fr);

    .k-dropdown,
    .k-numerictextbox {
      width: 13rem;
    }

    .column-7 {
      .k-dropdown {
        width: 13rem;
      }
    }
  }

}

@media screen and (max-width:1600px) and (min-width:1500px) {
  .bz-cancellation-page .bz-cancellation-card-row.payment-profile {
    grid-template-columns: 15rem 7rem 8rem repeat(3, 1fr);

    .k-dropdown,
    .k-numerictextbox {
      width: 9rem;
    }

    .column-7 {
      .k-dropdown {
        width: 13rem;
      }
    }
  }

  .bz-cancellation-page .bz-cancellation-card-row.zero-dollar-payment.payment-profile {
    grid-template-columns: repeat(5, 1fr);

    .k-dropdown,
    .k-numerictextbox {
      width: 13rem;
    }

    .column-7 {
      .k-dropdown {
        width: 13rem;
      }
    }
  }

  .bz-cancellation-page .loading .bz-cancellation-card-row.payment-profile {
    grid-template-columns: 15rem 7rem 8rem repeat(3, 1fr) 6rem;

  }
}

@media screen and (max-width:1600px) and (min-width:1300px){
  .suspend-container{
     .bz-content-text-row{
      grid-template-columns: 38% 2.5rem calc(62% - 2.5rem);
    }
  }
}

@media screen and (max-width:1600px) and (min-width:1300px){
  .suspend-container{
     .bz-content-text-row{
      grid-template-columns: 38% 2.5rem calc(62% - 2.5rem);
    }
  }
}

@media screen and (max-width:1650px) {
  .bzc-pkg-overview-grid {
    colgroup {
      >col:nth-child(1) {
        width: 6.20rem;
      }
      >col:nth-child(2) {
        width: 9rem;
      }

      >col:nth-child(3) {
        width: 12rem;
      }

      >col:nth-child(4) {
        width: 7.9rem;
      }

      >col:nth-child(5) {
        width: 6.125rem;
      }
    }
  }
  .bz-overview-page-row.bz-no-right-section {
    >.left-section {
      flex-basis: 100%;
    }
  }
}

@media screen and (min-width:1500px) {
  .bzc-notice {
    width: 80%;
  }

  .update-session-page-new {
    .bzc-row {
      width: 80%;
    }

    .bzc-row-btn {
      width: 80%;
    }
  }

  .bzc-edit-session-body-new {
    .bzc-row {
      width: 80%;
    }
  }
}

@media screen and (max-width:1500px) {

  .bz-cancellation-page .bz-cancellation-card-row.charge-dropdown {
    grid-template-columns: 1fr 9rem 14rem 1fr 1fr !important;
  }

  .bz-cancellation-page .loading .bz-cancellation-card-row.charge-dropdown {
    grid-template-columns: 1fr 8rem 8rem 1fr 1fr 6rem;
  }
 
}

@media screen and (max-width:1480px) {
  .bzc-sessions-list-card .bzc-sessions-list-row>.bzc-col-1:first-child {
    flex: 0 0 11rem;
  }

  .bzc-purchase-class-list-view .bzc-purchase-class-list-row.bz-class-selected>.bzc-left-side-card .bzc-sessions-list-row {
    grid-template-columns: repeat(2, 1fr) 8rem;
    grid-row-gap: 1rem;
    padding: 0;
  }

  .bzc-purchase-class-list-view .bzc-purchase-class-list-row.bz-class-selected>.bzc-left-side-card .bzc-sessions-list-row>.bzc-col-btn {
    grid-area: 1 / 3 / 2 / 4;
    min-height: auto;
    grid-row: 1 / span 2;
    height: 100%;
  }

  .bz-online-classes .bzc-purchase-class-list-view>.bzc-purchase-class-list-row.bz-class-selected .bzc-sessions-list-row {
    grid-template-columns: repeat(4, 1fr) 8rem;
    grid-column-gap: 0.5rem;
  }

  .bz-online-classes .bzc-purchase-class-list-view>.bzc-purchase-class-list-row.bz-class-selected .bzc-sessions-list-row>.bzc-col-btn {
    grid-area: 1 / 4 / 2 / 5;
    grid-row: 1 / span 2;
    height: 100%;
  }
}

@media (max-width: 1499px) and (min-width: 1025px) {
  .bz-cancellation-page .bz-cancellation-card-row.payment-profile {
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 1rem;

    .k-dropdown,
    .k-numerictextbox {
      width: 12rem;
    }

  }

  .bz-cancellation-page .loading .bz-cancellation-card-row.payment-profile {
    grid-template-columns: repeat(3, 1fr) 6rem;

    .column-8 {
      grid-area: 1/4/span 3/span 1;
      height: 100%;
    }
  }
}

@media (max-width: 1399px) and (min-width: 1200px) {
  .bzc-right-side-card .bz-upcoming-apponments-sm-card .media-body {
    line-height: 1.5;
  }

  .bz-class-session-btns>.bz-btn-type2>.bz-white-btn>span {
    display: none;
  }

  .bz-class-session-btns>.bz-btn-type2>.bz-white-btn {
    min-width: auto;
    padding: 0.5rem;
    width: 2.2rem;
    height: 2.2rem;
    margin: 0 0.25rem;
    -webkit-transition: width 500ms;
    -moz-transition: width 500ms;
    transition: width 500ms;

  }

  .bz-sessionList-header-row .bz-class-session-btns>.bz-btn-type2>.bz-white-btn:hover {
    padding: .5rem 1rem;
    width: 10.5rem;

    >span {
      display: inline-block;
    }
  }
}

@media screen and (min-width:621px) and (max-width:1460px) {
  .bz-paynow-page.inc-taxes .card .bz-cancellation-card-row {
    grid-template-columns: 330px 230px 330px !important;
  }

  .bz-paynow-page .card .bz-cancellation-card-row>[class|="column"]:not(.column-3) {
    padding-bottom: 1rem;
  }
}

@media screen and (min-width:621px) and (max-width:1366px) {

  .bz-paynow-page .card .bz-cancellation-card-row {
    grid-template-columns: 240px 230px 315px !important;

    .column-3.complimentary {
      grid-area: 2 / 2 / 2/ 4;
    }

    .column-4 {
      grid-area: 1/3/1/4;
    }

    >[class|="column"]:not(.column-3) {
      padding-bottom: 1rem;
    }
  }

  .bz-paynow-page.redeem-by-pakage {
    .card {
      .bz-cancellation-card-row {
        grid-template-columns: 240px 82px 169px 131px 208px;
      }
    }
  }

  .bz-paynow-page.redeem-by-complementary {
    .card {
      .bz-cancellation-card-row {
        grid-template-columns: 240px 82px 169px 131px !important;

        .column-3.complimentary {
          grid-area: initial;
        }
      }
    }
  }
}

@media screen and (min-width:991px) and (max-width:1186px) {
  .bz-paynow-page {
    .card {
      .bz-cancellation-card-row {
        grid-template-columns: 240px 315px !important;

        .column-4 {
          grid-area: 1/3/1/4 !important;
        }
      }
    }
  }
}

@media screen and (max-width:1366px) {
  .bz-vl {
    display: none;
  }

  .progressBarRow .k-progressbar.k-progressbar-horizontal {
    width: 9rem;
  }

  .bz-package-list-container .bz-packages-list-view .bz-package-card .bzc-sessions-list-row>.bzc-col-btn,
  .bz-online-classes .bzc-purchase-class-list-view.bz-packages-list-view .k-listview-content .bzc-sessions-list-row>.bzc-col-btn {
    grid-area: 1 / 5 / 2 / 5;
  }

  .bz-purchase-checkout-list .bz-classes-checkout.bzc-sessions-list-card .bzc-sessions-list-row-useCredit {
    grid-template-columns: 10.5rem 1fr 6rem 5rem;
    grid-column-gap: 0.5rem;
  }

  .bz-cancellation-page .bz-cancellation-card-row {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 0.5rem;

    .k-dropdown {
      width: 12.5rem;
    }
  }

  .column-7.add-payment-profile {
    .k-dropdown {
      width: 15rem;
    }
  }

  .bz-cancellation-page .package .bz-cancellation-card-row {
    grid-template-columns: repeat(3, 1fr);

    .k-dropdown {
      width: 13rem;
    }
  }

  .bz-cancellation-page .loading .bz-cancellation-card-row {
    grid-template-columns: 1fr 10rem 10rem 15rem 6rem;

    .k-dropdown,
    .k-numerictextbox {
      width: 9rem;
    }
  }

  .bzc-pkg-overview-grid {
    max-width: 40rem;
  }

}

@media screen and (max-width:1365px) {
  .bzc-purchase-class-list-view .k-listview-content .bzc-sessions-list-row {
    align-items: flex-start;
  }

  .bzc-grid.bz-invoiceGrid.billings colgroup col:nth-child(9),
  .bzc-grid.bz-invoiceGrid.submenubillings colgroup col:nth-child(7) {
    width: auto;
    min-width: 200px;
  }

  .bzc-purchase-class-list-view .k-listview-content .bzc-sessions-list-row>.bzc-col-btn {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .bz-sessionList-header-row>.column-1 {
    flex: 0 0 28rem;
    max-width: 28rem;
  }

  .bz-sessionList-header-row>.column-2 {
    max-width: calc(100% - 28rem);
    flex-basis: calc(100% - 28rem);
  }

  .bz-classSessionList-container>.k-card-body {
    padding: 0 !important;
  }

  .bz-classSessionList-container>.k-card-header {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .bz-online-classes .bzc-purchase-class-list-view .k-listview-content .bzc-sessions-list-row {
    grid-template-columns: 10rem repeat(2, 1fr) 5rem 8rem 8rem;
  }

  .bz-online-classes .bzc-purchase-class-list-view>.bzc-purchase-class-list-row.bz-class-selected .bzc-sessions-list-row {
    grid-template-columns: repeat(2, 1fr) 8rem;
    grid-column-gap: 1rem;
  }

  .bz-online-classes .bzc-purchase-class-list-view>.bzc-purchase-class-list-row.bz-class-selected .bzc-sessions-list-row>.bzc-col-btn {
    grid-area: 1 / 3 / 2 / 4;
    grid-row: 1 / span 2;
    height: 100%;
  }

  .bzc-purchase-class-list-view .k-listview-content .bzc-sessions-list-row {
    grid-template-columns: repeat(3, 1fr) 8rem;
    grid-row-gap: 1rem;
  }

  .bzc-purchase-class-list-view .k-listview-content .bzc-sessions-list-row>.bzc-col-btn {
    grid-area: 1 / 4 / 2 / 5;
    grid-row: 1 / span 2;
    height: 100%;
  }

  .bz-cancellation-page .bz-cancellation-card-row.charge-dropdown {
    grid-template-columns: repeat(3, 1fr) !important;
    grid-row-gap: 1rem;

  }

  .bz-cancellation-page .loading .bz-cancellation-card-row.charge-dropdown {
    grid-template-columns: repeat(3, 1fr) 6rem;

    .column-8 {
      grid-row: 1/3;
      grid-column: 4/5;
      height: 100%;
    }
  }

  .bz-cancellation-page .bz-cancellation-card-row {
    grid-template-columns: repeat(3, 1fr) !important
  }

}

@media screen and (max-width:1366px) and (min-width:800px) {
  .bzc-sessions-list-card.bz-sessionRoaster-card .bzc-sessions-list-row {
    grid-template-columns: repeat(3, 1fr) 4rem;
    grid-row-gap: 1rem;
  }

  .bzc-sessions-list-card.bz-sessionRoaster-card .bzc-sessions-list-row>.bzc-arrow-icon {
    align-items: flex-start;
  }

  .bzc-sessions-list-card.bz-sessionRoaster-card .bzc-sessions-list-row>.bzc-arrow-icon {
    grid-area: 1 / 4 / 2 / 4;
  }

}

@media screen and (max-width:1280px) {
  .bz-class-session-btns>.bz-btn-type2>.bz-white-btn>span {
    display: none;
  }

  .bz-class-session-btns>.bz-btn-type2>.bz-white-btn {
    min-width: auto;
    padding: 0.5rem;
    width: 2.2rem;
    height: 2.2rem;
    margin: 0 0.25rem;
    -webkit-transition: width 500ms;
    -moz-transition: width 500ms;
    transition: width 500ms;

  }

  .bz-sessionList-header-row>.column-1 {
    flex: 0 0 28rem;
    max-width: 28rem;
  }

  .bz-sessionList-header-row>.column-2 {
    max-width: calc(100% - 28rem);
    flex-basis: calc(100% - 28rem);
  }

  .bz-sessionList-header-row .bz-class-session-btns>.bz-btn-type2>.bz-white-btn:hover {
    padding: .5rem 1rem;
    width: 10.5rem;

    >span {
      display: inline-block;
    }
  }
}

@media screen and (max-width:1366px) and (min-width:1200px) {
  .bzc-sessions-list-card .bzc-sessions-list-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr) 8rem 15rem 3rem;
    grid-column-gap: 0.5rem;
  }

  .bz-group-services-list .bzc-sessions-list-card .bzc-sessions-list-row {
    grid-template-columns: repeat(3, 1fr) 6rem 2rem;
  }

  .bz-group-services-list.bz-past-GroupServices .bzc-sessions-list-card .bzc-sessions-list-row {
    grid-template-columns: repeat(4, 1fr) 6rem 9rem;
  }

}

@media screen and (max-width:1375px) and (min-width:992px) {
  .bz-configure-payout-filter {
    grid-template-columns: repeat(4, 1fr) 14rem 13rem;
  }
}

@media screen and (max-width:1199px) and (min-width:1025px) {
  .bzc-sessions-list-card .bzc-sessions-list-row {
    display: grid;
    grid-template-columns: repeat(4, 1fr) 3rem;
    grid-column-gap: 0.5rem;
  }

  .bz-groupServices-inhouse .bz-group-services-card .bz-groupServices-card-row {
    grid-template-columns: repeat(4, 1fr);
  }

  .bz-online-classes .bzc-purchase-class-list-view .k-listview-content .bzc-sessions-list-row>.bzc-col-btn {
    grid-area: auto;
    grid-row: auto;
  }

  .bz-group-services-list .bzc-sessions-list-card .bzc-sessions-list-row {
    grid-template-columns: 11rem repeat(2, 1fr) 6rem 2rem;
  }

  .bz-group-services-list.bz-past-GroupServices .bzc-sessions-list-card .bzc-sessions-list-row {
    grid-template-columns: 11rem repeat(2, 1fr) 6rem 7rem;
  }

  .bz-group-services-list.bz-past-GroupServices.statuses-alignment .bzc-sessions-list-card .bzc-sessions-list-row {
    grid-template-columns: 11rem repeat(3, 1fr) 6rem 7rem;
  }

}

@media screen and (max-width:1230px) {
  .bz-privacyPolicy-dialog.bz-classes-dialog .k-dialog {
    width: 75%;
  }

  .bz-class-session-btns>.bz-btn-type2>.bz-white-btn>span {
    display: none;
  }

  .bz-class-session-btns>.bz-btn-type2>.bz-white-btn {
    min-width: auto;
    padding: 0.5rem;
    width: 2.2rem;
    height: 2.2rem;
    margin: 0 0.25rem;
  }

  .bz-book-Appoinment-dialog.bz-selectClient-dialog>.k-dialog {
    width: 28rem !important;
  }

  .bz-cancellation-page .bz-cancellation-card-row {
    grid-template-columns: 15rem repeat(3, 1fr);
    grid-row-gap: 1rem;

  }

  .bz-cancellation-page .loading .bz-cancellation-card-row {
    grid-template-columns: repeat(2, 1fr) 5rem;

    .column-8 {
      grid-area: 1/3;
    }
  }
}

@media screen and (max-width:1199px) and (min-width:1025px) {
  .bzc-sessions-list-card .bzc-sessions-list-row>.bzc-col-1:nth-of-type(3) {
    flex-basis: calc(100% - 36rem);
  }

  .bzc-purchase-class-list-view .k-listview-content .bzc-sessions-list-row {
    grid-template-columns: repeat(3, 1fr) 8rem;
    grid-row-gap: 1rem;
  }

  .bzc-purchase-class-list-view .k-listview-content .bzc-sessions-list-row>.bzc-col-btn {
    grid-area: 1 / 4 / 2 / 5;
    min-height: auto;
    grid-row: 1 / span 2;
    height: 100%;
  }

  .bzc-purchase-class-list-view .bzc-purchase-class-list-row.bz-class-selected>.bzc-left-side-card .bzc-sessions-list-row {
    grid-template-columns: auto;
    grid-row-gap: 1rem;
    display: flex;
    flex-direction: column;
  }

  .bz-purchase-class-filter {

    .k-dropdown,
    .k-datepicker {
      width: 12.5rem;
    }

  }

  .bz-online-classes .bzc-purchase-class-list-view>.bzc-purchase-class-list-row.bz-class-selected .bzc-sessions-list-row {
    grid-template-columns: repeat(2, 1fr) 8rem;
    grid-column-gap: 1rem;
    display: grid;
    padding: 0;
  }

  .bz-online-classes .bzc-purchase-class-list-view>.bzc-purchase-class-list-row.bz-class-selected .bzc-sessions-list-row>.bzc-col-btn {
    grid-area: 1 / 3 / 2 / 4;
    grid-row: 1 / span 2;
    height: 100%;
  }

  .bz-package-list-container .bz-packages-list-view .bz-package-card .bzc-sessions-list-row>.bzc-col-btn,
  .bz-online-classes .bzc-purchase-class-list-view.bz-packages-list-view .k-listview-content .bzc-sessions-list-row>.bzc-col-btn {
    grid-area: 1 / 3 / 2 / 4;
  }

  .bz-package-list-container .bz-packages-list-view .bz-package-card .bzc-sessions-list-row,
  .bz-online-classes .bzc-purchase-class-list-view.bz-packages-list-view .k-listview-content .bzc-sessions-list-row {
    grid-template-columns: repeat(2, 1fr) 10rem;
  }
}

@media (max-width: 1300px) and (min-width: 1024px) {
  .bzc-purchase-class-list-row.bz-class-selected .k-listview-content .bzc-sessions-list-row {
    display: grid;
    grid-template-columns: 40% 40% 8rem;
    column-gap: 0.5rem;
    justify-content: space-between;
    padding: 0 1rem;
  }

  .bzc-purchase-class-list-row.bz-class-selected .k-listview-content .bzc-sessions-list-row>.bzc-col-btn {
    grid-column: 3;
    grid-row: 1 / span 2;
  }

  .bz-purchase-checkout-list .bz-classes-checkout.bzc-sessions-list-card .bzc-sessions-list-row {
    grid-template-columns: 13rem auto;
    justify-content: flex-start;
  }

  .bz-purchase-checkout-list .bz-classes-checkout.bzc-sessions-list-card .bzc-sessions-list-row-useCredit {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width:1100px) and (min-width:1025px) {
  .bz-purchase-checkout-list.bz-multiplepackage-select .bz-row {
    align-items: flex-start;
    flex-direction: column;
  }

  .bz-purchase-checkout-list.bz-multiplepackage-select .bz-row>.select-column {
    width: 100%;
  }

  .bz-purchase-checkout-list.bz-multiplepackage-select .k-multiselect.bz-multiSelect .k-multiselect-wrap {
    margin-bottom: 0.5rem;
  }
}

@media screen and (max-width:1024px) {
  .bzc-purchase-class-list-view .k-listview-content .bzc-sessions-list-row {
    grid-template-columns: repeat(3, 1fr) 8rem;
  }

  .bz-purchase-checkout-list .bz-classes-checkout.bzc-sessions-list-card .bzc-sessions-list-row h5 {
    line-height: 1.1;
  }

  .bzo-class-window .bz-checkout-page .order-summary-form {
    padding: 0;
  }

  .bz-group-services-list .bzc-sessions-list-card .bzc-sessions-list-row {
    grid-template-columns: repeat(3, 1fr) 6rem 2rem;
  }

  .bz-cancellation-page .bz-cancellation-card-row.payment-profile {
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 1rem;

    .k-dropdown,
    .k-numerictextbox {
      width: 13rem;
    }

  }

  .bz-cancellation-page .loading .bz-cancellation-card-row.payment-profile {
    grid-template-columns: repeat(2, 1fr) 6rem;

    .column-8 {
      grid-area: 1/3/span 4/span 1;
      height: 100%;
    }
  }
}

@media screen and (max-width:1024px) {

  .bz-group-services-list .bzc-sessions-list-card .bzc-sessions-list-row {
    grid-template-columns: repeat(2, 1fr) 2rem;
    padding-right: 0;
    grid-row-gap: 0.5rem;

    >.bzc-close-icon {
      grid-area: 1/3/2/4;
      grid-row: 1 / span 2;
      display: flex;
      height: 100%;
      align-items: flex-start;
    }
  }

  .bz-group-services-list .bz-groupService-statuses {
    justify-content: flex-start;
  }

  .bz-group-services-list.bz-past-GroupServices .bzc-sessions-list-card .bzc-sessions-list-row {
    grid-template-columns: repeat(2, 1fr) 9rem;
  }

  .bz-group-services-list.bz-past-GroupServices .bzc-sessions-list-card .bzc-sessions-list-row .bz-past-statuses {
    align-items: flex-start;
  }

  .bz-pkg-filter-group.bz-classList-container {
    >.k-card-header {
      flex-direction: row;
    }
  }
}

@media screen and (min-width:700px) and (max-width:1199px) {
  .bz-paynow-page.inc-taxes {
    .card {
      .bz-cancellation-card-row {
        grid-template-columns: 200px 160px 330px !important;

        .k-dropdown {
          width: 15rem;
        }

        >[class|="column"]:not(.column-4) {
          padding-right: 1rem;
        }
      }
    }
  }
}

@media screen and (max-width:991px) and (min-width:700px) {
  .bzc-purchase-class-list-view .bzc-purchase-class-list-row.bz-class-selected {
    display: flex;
    grid-template-columns: auto 19rem;
    grid-column-gap: 1rem;
  }

  .bz-privacyPolicy-dialog.bz-classes-dialog .k-dialog {
    width: 95%;
    height: 52%;
  }
}

@media screen and (max-width:991px) and (min-width:621px) {
  .bz-configure-payout-filter {
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 1rem;
  }
}

@media (max-width: 1023px) and (min-width: 821px) {
  .bz-classList-container {
    >.k-card-header {
      flex-direction: column;

    }

  }

  .bzc-purchase-class-list-row.bz-class-selected .k-listview-content .bzc-sessions-list-row {
    justify-content: flex-start;
    flex-direction: column;
    align-items: self-start;
  }

  .bzc-purchase-class-list-row.bz-class-selected .k-listview-content .bzc-sessions-list-row>.bzc-col-time,
  .bzc-purchase-class-list-row.bz-class-selected .k-listview-content .bzc-sessions-list-row>.bzc-col-btn,
  .bzc-purchase-class-list-row.bz-class-selected .k-listview-content .bzc-sessions-list-row>.bzc-col-price {
    flex: 0 0 auto;
  }

  .bzc-purchase-class-list-row.bz-class-selected .k-listview-content .bzc-sessions-list-row>div {
    margin-bottom: 0.25rem;
  }

  .bzc-purchase-class-list-row.bz-class-selected .k-listview-content .bzc-sessions-list-row>.bzc-col-btn .k-button {
    margin-left: 0;
  }
}

@media screen and (max-width:991px) {
  .bzc-sessions-list-card .bzc-sessions-list-row {
    display: grid;
    grid-template-columns: 15rem auto;
    grid-column-gap: 4rem;
    padding-right: 5rem;
    padding-left: 1rem;
    justify-content: flex-start;
    grid-row-gap: 1rem;
  }

  .bzc-sessions-list-card .bzc-sessions-list-row .bzc-arrow-icon {
    position: absolute;
    right: 1rem;
  }

  .bzc-edit-session-options-row>.column-2>.d-flex {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .bzc-edit-session-options-row>.column-2>.d-flex>div {
    margin: 1rem 0 0 !important;
  }

  .bzc-edit-session-options-row>.column-2>.d-flex>div:first-child {
    margin-top: 0 !important;
  }

  .bzc-purchase-class-list-view .k-listview-content .bzc-sessions-list-row>.bzc-col-btn {
    min-height: auto;
  }

  .bzc-purchase-class-list-view .k-listview-content .bzc-sessions-list-row {
    grid-template-columns: repeat(2, 1fr) 8rem;
    grid-row-gap: 1rem;
    padding-right: 0;
  }

  .bz-purchase-checkout-list .bz-classes-checkout.bzc-sessions-list-card .bzc-sessions-list-row-useCredit {
    grid-template-columns: repeat(3, 1fr) 5rem;
  }

  .bzc-purchase-class-list-view .k-listview-content .bzc-sessions-list-row>.bzc-col-btn {
    grid-area: 1 / 3 / 2 / 4;
    min-height: auto;
    grid-row: 1 / span 2;
    height: 100%;
  }

  .bz-groupServices-inhouse .bz-group-services-card .bz-groupServices-card-row {
    grid-template-columns: repeat(4, 1fr);
  }

  .bz-online-classes .bzc-purchase-class-list-view .k-listview-content .bzc-sessions-list-row {
    grid-template-columns: repeat(3, 1fr) 8rem;
  }

  .bz-online-classes .bzc-purchase-class-list-view .k-listview-content .bzc-sessions-list-row>.bzc-col-btn {
    grid-area: 1 / 4 / 4 / 5;
    min-height: auto;
    grid-row: 1 / span 2;
    height: 100%;
  }

  .bz-package-list-container .bz-packages-list-view .bz-package-card .bzc-sessions-list-row>.bzc-col-btn,
  .bz-online-classes .bzc-purchase-class-list-view.bz-packages-list-view .k-listview-content .bzc-sessions-list-row>.bzc-col-btn {
    grid-area: 1 / 3 / 2 / 4;
  }

  .bz-package-list-container .bz-packages-list-view .bz-package-card .bzc-sessions-list-row,
  .bz-online-classes .bzc-purchase-class-list-view.bz-packages-list-view .k-listview-content .bzc-sessions-list-row {
    grid-template-columns: repeat(2, 1fr) 10rem;
  }


}

@media screen and (max-width:991px) and (orientation:landscape) {
  .bz-classSessionList-container.k-card>.k-card-body .k-listview {
    height: auto;
  }

  .bz-privacyPolicy-dialog.bz-classes-dialog .k-dialog {
    width: 95%;
    height: auto;
    overflow: hidden;
    margin: 1rem;
  }

  .bz-attendeesList-container.k-card>.k-card-body .k-listview.bzc-attendees-list {
    height: auto;
  }

  .bz-online-classes .bzc-purchase-class-list-view .bz-purchase-class-list .k-listview {
    max-height: initial !important;
  }

  .bz-classes-in-online .bzc-purchase-class-list-view .bzc-purchase-class-list-row>.bzc-right-side-card {
    height: auto;
  }
}

@media screen and (max-width:820px) and (min-width:621px) {
  .bz-purchase-checkout-list .bz-classes-checkout.bzc-sessions-list-card .bzc-sessions-list-row-useCredit {
    grid-template-columns: repeat(2, 1fr);
  }

  .bz-cancellation-page .bz-cancellation-card-row {
    grid-template-columns: repeat(2, 1fr);

  }

  .bz-cancellation-page .bz-cancellation-card-row.charge-dropdown {
    grid-template-columns: repeat(2, 1fr) !important;
    grid-row-gap: 1rem;

  }

  .bz-cancellation-page .loading .bz-cancellation-card-row.charge-dropdown {
    grid-template-columns: repeat(2, 1fr) 6rem;

    .column-8 {
      grid-area: 1/3/span 3/span 1;
      height: 100%;
    }
  }

  .bz-cancellation-page .bz-cancellation-card-row.payment-profile {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@media screen and (min-width:700px) {
  .k-dialog-wrapper.bz-purchaseClassSessionSuccess>.k-dialog {
    min-width: 28rem;
  }

  .bz-purchaseClassSessionSuccess.bz-groupEvent-success {
    >.k-dialog {
      width: 28rem;
      max-width: 28rem;
    }
  }

  .bz-date-filter-calendar #multiViewCalendar {
    min-width: 315px !important;
  }

  .bz-chargeback-dialog {
    >.k-dialog {
      max-width: 24rem;
      min-width: 20rem;
    }
  }

  .checkout-panelbar-wrapper {
    .package-redeem-card-blk>.package-redeem-card {
      .package-item {
        width: 50%;
      }
    }
  }
}

@media (max-width: 820px) {
  .bz-classList-container {
    >.k-card-header {
      flex-direction: column;

    }

  }

  .bz-classList-container>.k-card-header.groupmainview.align-items-center {
    flex-direction: column !important;
    align-items: start !important;
    gap: 10px;
  }

  .groupmainview .dflexBtn,
  .formsaddveiw .dflexBtn {
    flex-wrap: nowrap;
  }

  .bz-classList-container>.k-card-header.formsaddveiw.align-items-center {
    flex-direction: column !important;
    align-items: start !important;
    gap: 10px;
  }

  .bzc-purchase-class-list-view .k-listview-content .bzc-sessions-list-row>.bzc-col-btn .k-button {
    margin-left: 0;
  }

  .bzc-sessions-list-card.bz-sessionRoaster-card .bzc-sessions-list-row {
    grid-template-columns: auto auto auto;
    grid-row-gap: 1rem;
  }

  .bzc-sessions-list-card .bzc-sessions-list-row h5 {
    line-height: 1.3;
  }

  .bz-group-services-list .bzc-sessions-list-card .bzc-sessions-list-row {
    grid-column-gap: 1rem;

  }
}



@media (max-width: 850px) {
  .bzc-purchase-class-list-view .bzc-purchase-class-list-row.bz-class-selected {
    flex-direction: column;
    display: flex;
  }

  .bzc-purchase-class-list-view .bzc-purchase-class-list-row.bz-class-selected>.bzc-right-side-card {
    order: 1;
    flex: 0 0 auto;
    margin: 1.5rem 0;
  }

  .bzc-purchase-class-list-view .bzc-purchase-class-list-row.bz-class-selected>.bzc-right-side-card .k-listview-content {
    max-height: 18rem;
  }

  .bz-classes-in-online .bzc-purchase-class-list-view .bzc-purchase-class-list-row>.bzc-right-side-card {
    height: auto;
  }

  .bzc-purchase-class-list-view .bzc-purchase-class-list-row.bz-class-selected>.bzc-left-side-card {
    order: 2;
    padding-bottom: 3rem;
    width: 100%;
  }

  .bzc-purchase-class-list-view .bzc-purchase-class-list-row.bz-class-selected>.bzc-left-side-card.bz-groupservices-left-side-card {

    padding-bottom: 1rem;
  }

  .bzc-footer-fixed-btn {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    height: 3.5rem;
    background-color: #fff;
    border-top: 1px solid #d9d9d9;
    padding: 0.5rem 1rem;
  }

  .bzc-footer-unpoitioned-btn .k-fab {
    position: relative !important;
  }

  .bz-online-classes .bzc-footer-fixed-btn {
    position: unset;
    padding: 0;
    border: 0;
    height: auto;
  }

  .bz-online-classes>.bzc-purchase-class-list-view .bzc-purchase-class-list-row.bz-class-selected>.bzc-left-side-card {
    padding-bottom: 0.5rem;
  }

  .bz-online-classes .bzc-purchase-class-list-view .k-listview-content .bzc-sessions-list-row {
    grid-template-columns: repeat(2, 1fr) 8rem;
    grid-row-gap: 1rem;
    padding-right: 0;
  }

  .bz-online-classes .bzc-purchase-class-list-view .k-listview-content .bzc-sessions-list-row>.bzc-col-btn {
    grid-area: 1 / 3 / 2 / 4;
    min-height: auto;
    grid-row: 1 / span 2;
    height: 100%;
  }
}

@media screen and (max-width:768px) {
  .bz-classes-in-online .bzc-purchase-class-list-view .bzc-purchase-class-list-row.bz-class-selected>.bzc-right-side-card {
    height: auto;
  }

  .bz-openPath-credintials {
    >.mobile-onsite-checkins {
      display: flex !important;

      >.section-2,
      .section-1 {
        width: 50% !important;
      }
    }

  }

  .groupclass-session-list.bz-manage-attendees {
    grid-template-columns: 1fr !important;
    padding: 1.25rem !important;
    gap: 0;
    position: relative;

    >div {
      padding-left: 0;
    }

    .section-1 {
      grid-area: unset !important;
      order: 2;
      padding-bottom: 0.75rem;

      .bz-calender-icon {
        display: none;
      }

      .bz-availability-dates {
        padding-left: 0;
      }
    }

    .section-2 {
      grid-area: unset !important;
      order: 1;
      padding-right: 9.5rem;
      padding-bottom: 0.75rem;

      >.bz-main-provider {
        padding: 0;
      }
    }

    .section-3 {
      order: 3;

      .bz-calender-icon {
        display: none;
      }

      .bz-availability-dates {
        padding-left: 0 !important;

        h6 {
          padding-left: 0;
        }
      }
    }

    .section-4,
    .section-5 {
      position: absolute;
      width: auto;
      padding: 0;
    }

    .section-4 {
      order: 4;
      top: 1.25rem;
      right: 3.5rem;
    }

    .section-5 {
      order: 5;
      right: 0.5rem;
      top: 10px;
    }
  }
}

@media screen and (max-width:767px) {
  .add-class-form {
    display: block !important;
  }

  .addClassForm-grid2 {
    display: block !important;
  }

  .bz-sessionList-header-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .bz-sessionList-header-row>.column-1,
  .bz-sessionList-header-row>.column-2 {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
  }

  .bz-groupServices-inhouse .bz-group-services-card .bz-groupServices-card-row {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width:767px) and (min-width:621px) {
  .bz-configure-payout-filter {
    grid-template-columns: repeat(2, 1fr) 14rem;

    .payout-mode-col {
      grid-area: 1 / 3 / 2 / 4;
    }
  }

  .bz-paynow-page .card .bz-cancellation-card-row {
    grid-template-columns: 1fr 5rem 10rem 1fr;
  }
}

@media screen and (max-width:700px) {
  .bzc-edit-session-options-row {
    display: grid;
    grid-row-gap: 1rem;
  }

  .bzc-edit-session-options-row>.column-2 {
    flex: 0 0 100%;
    max-width: 100%;

    .switch-label {
      padding-left: 0.25rem;
      font-size: 0.8rem;
    }
  }

  .bzc-edit-session-options-row>.column-2>.k-dropdown,
  .bzc-edit-session-options-row>.column-2>.k-numerictextbox,
  .bzc-edit-session-options-row>.column-2>.k-timepicker {
    width: 100%;
  }

  .bzc-edit-session-options-row>.column-2 .bzc-input-group .k-numerictextbox,
  .bzc-edit-session-options-row>.column-2 .bzc-input-group>.input-group-append {
    width: 50%;
  }

  .bzc-edit-session-options-row>.column-2 .bzc-input-group .k-dropdown,
  .bzc-edit-session-options-row>.column-2>.d-flex>div {
    width: 100%;
  }

  .bz-show-packages-checkbox {
    position: unset;
  }

  .bz-classes-in-online .bz-purchase-class-filter {
    justify-content: flex-start;
  }

  .bz-openPath-credintials {
    padding: 1rem;
  }

  .bz-openPath-credintials.bz-onsiteAccess {
    >.mobile-onsite-checkins {
      display: block !important;

      >.section-2,
      .section-1 {
        width: 100% !important;
        text-align: left;
      }
    }
  }

  .bz-openPath-credintials.bz-checkins-barcode {
    >.mobile-onsite-checkins {
      display: block !important;

      >.section-3,
      .section-2,
      .section-1 {
        width: 100% !important;
        text-align: left;
        max-width: initial;
      }
    }
  }

  .bz-barcode-container {
    max-width: 25rem !important;
    margin-bottom: 1rem;

    >.bz-send-qr-icon {
      margin-top: 0;
    }
  }

  .bz-logs-filter {
    .filter-level-2 {
      >.bz-row {
        flex-direction: column;
        align-items: flex-start !important;
        width: 100%;

        >.col-auto {
          padding: 0 !important;
          width: 100%;

          >.k-button {
            float: right;
            margin-top: 0.375rem;
          }
        }

        .k-multiselect {
          margin-bottom: 0.25rem;
          width: 100% !important;
        }
      }
    }
  }

  .bz-addCard-dialog.bz-credit-topup-modal {
    >.k-dialog {
      min-width: initial;
      width: 98%;

      .bz-subsection-row.one-time-charge {
        max-width: inherit;
      }
    }
  }
}

@media screen and (min-width:621px) {
  .bz-date-filter-close.bz-close-Icon-mobile {
    display: none;
  }

  .bz-class-description.bz-share-link>.k-textbox {
    width: 23rem;
  }

}

@media (max-width: 620px) {
  .bz-classList-container {
    >.k-card-header {
      flex-direction: column;

      .bz-classes-action {
        // flex-wrap: wrap;
        justify-content: start !important;

        .bz-btns-container {
          width: 100%;

          .bz-white-btn {
            width: 50%;
            padding: 0.5rem;

            &:first-of-type {
              margin-left: 0.2rem;
            }

            &:last-of-type {
              margin-right: 0.2rem;
            }
          }

          .k-dropdown-button.bz-btn-type2 {
            width: 50%;

            >.k-button {
              width: 95%;

              &:first-of-type {
                margin-left: 0rem;

              }

              &:last-of-type {
                margin-right: 0rem;
              }
            }
          }
        }
      }
    }

  }

  .k-card.bz-classList-container>.k-card-header .bz-classList-selectbox {
    width: 100% !important;
  }

  .k-card.bz-classList-container>.k-card-header .bz-classes-action>.bz-classes-search {
    margin: 0rem;
    margin-bottom: 1rem;
    width: 100%;
  }

  .purchaseapptsquickslinks .k-card.bz-classList-container>.k-card-header .bz-classes-action>.bz-classes-search {
    width: auto;
    margin-bottom: 0;
  }

  .d-flex.flex-column.packackagespreset {
    width: 100% !important;
    margin-top: 8px;
  }

  .bz-classList-container.k-card>.k-card-body .k-listview .buz-online-list-view {
    align-items: center;
  }

  .bzo-class-window .k-window-content,
  .bzo-class-window .k-window-titlebar {
    padding: 0.5rem 1.25rem;
  }

  .bz-arrowicon-positon {
    position: absolute;
    right: 1rem;
  }

  .bz-package-list-container {
    .bz-arrowicon-positon {
      top: 1.2rem
    }
  }

  .bzc-viewDesc>span.btn {
    justify-content: flex-start;
  }

  .bzc-sessions-list-card .bzc-sessions-list-row {
    grid-template-columns: 10rem 13rem;
    padding-right: 3rem;
    grid-column-gap: 2rem;
    padding-left: 0;
  }

  .bzc-sessions-list-card.bz-sessionRoaster-card .bzc-sessions-list-row {
    grid-template-columns: auto 4rem;
    grid-row-gap: 1rem;
    grid-column-gap: 0.5rem;
  }

  .bzc-sessions-list-card.bz-sessionRoaster-card .bzc-sessions-list-row>.bzc-arrow-icon {
    grid-area: 1 / 2 / 5 / 3;
  }

  .bzc-sessions-list-card.bz-sessionRoaster-card .bzc-sessions-list-row>.bzc-arrow-icon {
    display: initial;
  }

  .bzc-purchase-class-list-view .k-listview-content .bzc-sessions-list-row {
    display: flex;
    grid-row-gap: 0.5rem !important;
    flex-direction: column;

    >div {
      margin-bottom: 0.75rem;
    }
  }

  .bz-purchase-class-filter {

    .k-dropdown,
    .k-datepicker {
      width: 13rem;
    }


  }

  .bz-purchase-checkout-list .bz-classes-checkout.bzc-sessions-list-card .bzc-sessions-list-row {
    display: flex;
  }

  .bz-purchase-checkout-list .bz-classes-checkout.bzc-sessions-list-card .bzc-sessions-list-row>.bzc-col-time {
    flex: 0 0 auto;
  }

  .bz-purchase-checkout-list .bz-classes-checkout.bzc-sessions-list-card .bzc-sessions-list-row>.bzc-col-price {
    flex: 0 0 auto;
  }

  .bz-privacyPolicy-dialog.bz-classes-dialog .k-dialog {
    width: 95%;
    height: 90%;
    max-width: 95% !important;
    margin: 0;
  }

  .bz-checkout-page .bz-book-Appoinment-dialog.bz-purchaseClassSessionSuccess .k-dialog {
    max-width: 100% !important;
    width: calc(100% - 1rem) !important;
  }

  .bz-date-filter {
    width: 100%;
  }

  .bz-attendeesList-container.k-card>.k-card-body .k-listview.bzc-attendees-list {
    height: auto;
  }

  .bz-sessionList-header-row>.column-1>.d-flex {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .bz-sessionList-header-row>.column-1 .bzc-date-sec {
    order: 1;
    margin-left: 0;
  }

  .bz-sessionList-header-row>.column-1>.d-flex .k-datepicker {
    order: 2;
    margin: 1rem 0;
  }

  .bz-online-classes .bzo-viewDetails,
  .bz-inhouse-classes>.bzc-purchase-class-list-view .bzo-viewDetails {
    margin-top: 1rem;
  }

  .bz-classes-in-online .bzc-purchase-class-list-view .bzc-left-side-card .bz-purchase-class-list .k-listview .k-listview-content {
    padding-right: 0;
  }

  .bz-group-services-list .bzc-sessions-list-card .bzc-sessions-list-row {
    grid-template-columns: repeat(1, 1fr) 2rem;
    grid-row-gap: 1rem;

    h5 {
      margin-bottom: 0;
      max-width: 100% !important;
    }
  }

  .bz-group-services-list.bz-past-GroupServices .bzc-sessions-list-card .bzc-sessions-list-row {
    grid-template-columns: calc(100% - 8rem) 8rem;
  }

  .bz-group-services-list .bzc-sessions-list-card .bzc-sessions-list-row>.bzc-close-icon {
    grid-area: 1/2/4/3;
    grid-row: 1 / span 4;
  }

  .bz-group-services-list.bz-past-GroupServices .bzc-sessions-list-card .bzc-sessions-list-row .bz-statuses.Notes {
    margin: 0;
  }

  .bzc-sessions-list-card.bz-sessionRoaster-card .bzo-viewDetails {
    margin-top: 1rem;
  }

  .k-window-title.k-dialog-title {
    max-width: 18rem;
    word-break: break-word;
    white-space: break-spaces;
  }

  .bz-chargeback-dialog .k-window-title.k-dialog-title,
  .bz-insertChargeback-dialog .k-window-title.k-dialog-title,
  .bz-addCard-dialog .k-window-title.k-dialog-title {
    max-width: initial;
  }

  .bz-book-Appoinment-dialog .k-window-titlebar {
    justify-content: space-between;
  }

  .bz-dateFilter-container>.bz-date-filter-calendar {
    display: flex;
    flex-direction: column;
    top: 2.25rem;
  }

  .bz-date-filter-calendar #multiViewCalendar {
    border-width: 0;
  }

  .k-calendar-header {
    min-width: 270px;
  }

  .popoverClassdatepciker {
    width: 300px !important;
    left: 10px !important;
  }

  .bz-filter-container>ul {
    width: 100%;
  }

  .appointmentstabs .ml-2.dropdown {
    position: static;
  }

  .bz-date-filter-calendar {
    max-width: none;
  }

  .bz-date-filter-calendar #multiViewCalendar {
    min-width: 0;
  }

  .bz-date-filter-close.bz-close-Icon-tab {
    display: none;
  }

  .bz-date-filter-main .bz-date-filter-textBox {
    width: 100%;
    margin-bottom: 0.5rem;
    justify-content: flex-start;

    >span {
      width: calc(100% - 2.5rem)
    }
  }

  .bzc-attendees-list .bzc-attndees-card>.card-body>.row>.col-sm-4 {
    padding: 0;
  }

  .bzc-attendees-list .bzc-attndees-card>.card-body .bz-elipsis-icon>.k-button-icon {
    margin-right: 0;
  }

  .bz-class-description {
    width: 90%;
  }

  .bz-class-description.redeempacages {
    width: 100%;
  }

  .bz-class-description.bz-share-link {
    >.k-textbox {
      width: 100%;
      margin-bottom: 0.5rem;
    }

    >.k-button {
      float: right;
      margin-right: 0;
    }
  }

  .bz-book-Appoinment-dialog.bz-selectClient-dialog {
    >.k-dialog {
      width: 25rem !important;
      max-width: 100% !important;
    }
  }

  .bz-configure-payout-filter {
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;
  }

  .bz-group-service-details .bz-custom-row {
    grid-template-columns: 7rem 1rem calc(100% - 8rem);
  }

  .bz-cancellation-page-new .k-listview .bz-cancellation-card-row {
    justify-content: flex-start;
    display: flex;
    flex-wrap: wrap;

    >.column-1,
    >.column-3,
    >.column-4,
    >.column-5,
    >.column-6,
    >.column-7 {
      width: 100%;
    }

    >.column-2,
    >.column-3 {
      width: 48%;
    }
  }
}

@media screen and (max-width:585px) {
  .bz-purchaseClassSessionSuccess .bz-successmessage-row {
    padding-top: 1rem;

    >h6 {
      margin-bottom: 0.25rem;

      >span {
        margin-bottom: 0.5rem;
      }
    }

  }
}

@media screen and (max-width:575px) and (min-width:480px) {
  .bz-purchase-class-filter .k-dropdown {
    margin-right: 1rem;
  }
}

@media screen and (max-width:479px) {
  .bzc-sessions-list-card .bzc-sessions-list-row {
    grid-template-columns: auto;
    grid-row-gap: 0.5rem;
  }

  .bz-classSessionList-container.k-card>.k-card-body .k-listview {
    height: auto;
  }

  .promotionrewardsgrid {
    display: block !important;
  }

  .promotiongridchild {
    width: 100% !important;
  }

  .bz-main-provider {
    margin-bottom: 5px;
  }

  .k-widget.k-window.k-dialog {
    width: 98% !important;

  }

  .payout-rewardheading {
    flex-direction: column;

    span {
      margin-bottom: 8px;
      display: block;
    }

    .k-button.k-primary.create-new-payout-btn {
      margin: 0 !important;
    }

    span.k-switch-label-on {
      display: none;
    }

    span.k-switch-label-off {
      display: none;
    }

    span.k-switch-container,
    span.affliatesswitch,
    span.k-switch-handle {
      margin-bottom: 0;
    }


  }

  .k-dialog-wrapper.bz-pipedrive-dialog {
    >.k-dialog {
      width: calc(100% - 2rem) !important;
    }
  }

  .bz-chargeback-dialog {
    .k-widget.k-window.k-dialog {
      width: 98% !important;
      max-width: initial;

      .ready-to-book-appt-dialog {
        grid-template-columns: 14rem 1fr;
      }
    }
  }

  .k-widget.k-window.k-dialog .k-dialog-buttongroup {
    padding: 1rem 0.5rem;
  }

  .k-dialog-content>p.dialogText,
  .k-dialog-content>p.dialogContainer {
    margin-top: 1rem !important;
  }

  .bz-attendeesList-container {
    >.k-card-header {
      flex-direction: column;

      >.k-card-title {
        margin-bottom: 1rem;
      }

      .k-form-buttons {
        justify-content: flex-end;
        margin-bottom: 1rem;
      }
    }


  }

  .bz-purchase-class-filter {
    flex-direction: column;
  }

  .bz-purchase-class-filter .k-dropdown,
  .bz-purchase-class-filter .k-datepicker {
    width: 100%;
  }

  .bz-sessionList-header-row {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 0.5rem;

    >.column-1 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;

      .bz-date-filter {
        margin-bottom: 1rem;
      }

    }

    >.column-2 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;

      .bz-classes-action-btn {
        margin-bottom: 0;
      }
    }
  }

  .bz-purchase-class-filter .k-widget.k-autocomplete,
  .bz-sessionList-header-row>.column-1 .k-datepicker {
    width: 100%;
  }

  .k-calendar-infinite .k-calendar-view {
    width: 270px;
    padding-left: 0;
  }

  .k-calendar .k-calendar-view table,
  .k-calendar .k-calendar-view .k-calendar-table {
    border-spacing: 0 !important;
  }

  .bz-calender-sec .k-calendar .k-calendar-view table,
  .k-calendar .k-calendar-view .k-calendar-table {
    border-spacing: 5px !important;
  }

  .popoverClassdatepciker .k-calendar .k-calendar-view table,
  .popoverClassdatepciker .k-calendar .k-calendar-view .k-calendar-table {
    border-spacing: 10px !important;
  }

  .bz-groupServices-inhouse .bz-group-services-card .bz-groupServices-card-row {
    grid-template-columns: repeat(2, 1fr);
    padding-right: 1.5rem;
  }

  .bz-class-description {
    width: 100%;
  }

  .bz-package-list-container .bz-packages-list-view .bz-package-card .bz-packages-tags .k-chip,
  .bz-package-list-container .bz-packages-search {
    max-width: 100%;
  }

  .k-calendar-container {
    width: auto;

    .k-calendar.k-calendar-infinite {
      width: auto;

      .k-calendar-navigation {
        width: 3rem;

        .k-content {
          ul {
            width: 3rem;
          }

          li {
            padding: 0;

            span {
              padding: 0;
            }
          }
        }

      }

      .k-calendar-view {
        .k-calendar-header {
          th {
            width: 26px;
            height: 26px;
            text-align: center;
            font-size: 0.875rem;
          }
        }

        .k-calendar-table {
          td {
            .k-link {
              width: 26px;
              height: 26px;
            }
          }
        }
      }
    }
  }

  .bz-cancellation-page .bz-cancellation-card-row.payment-profile .k-dropdown,
  .bz-cancellation-page .bz-cancellation-card-row.payment-profile .k-numerictextbox {
    width: 100%;
  }

}

@media screen and (max-width:479px) and (orientation:portrait) {
  .bzc-total-sessions-count {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 99;
    background: #f2f4f4;
    padding: 1rem 1.5rem;
    text-align: right;
    border-top: 1px solid #e4e4e4;
  }

  .bz-classSessionList-container.k-card>.k-card-body .k-listview {
    margin-bottom: 2rem;
  }
}

@media screen and (max-width:376px) {
  .bz-group-services-list.bz-past-GroupServices .bzc-sessions-list-card .bzc-sessions-list-row {
    grid-template-columns: 9.8rem 8rem;
    grid-column-gap: 0.5rem;
  }

  .bz-barcode-container {
    .k-barcode {
      max-width: 14.5rem;
    }
  }
}

//pakages responsive code
@media screen and (max-width:720px) {
  .bz-cancellation-page .package .bz-cancellation-card-row {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width:620px) {
  .bz-packages-subscription .k-tabstrip>.k-tabstrip-items {
    justify-content: flex-start;
  }

  .bz-package-details .bz-package-btns {
    margin-bottom: 0.5rem;
  }

  .bz-online-classes .bz-packages-search {
    max-width: 100%;
    width: 100%;
    padding: 0;
  }



  .bz-purchase-checkout-list.bz-multiplepackage-select .bz-row {
    align-items: flex-start;
    flex-direction: column;
  }

  .bz-purchase-checkout-list.bz-multiplepackage-select .bz-row>.select-column {
    width: 100%;
  }

  .bz-cancellation-page .bz-cancellation-type {
    display: flex;
    flex-direction: column;

  }

  .bz-cancellation-page .bz-cancellation-type .bzc-provider-name .k-dropdown,
  .bz-cancellation-page .bz-cancellation-type .bzc-provider-name .k-numerictextbox {
    width: 100%;
  }

  .bz-cancellation-page .bzc-sessions-list-card.bz-sessionRoaster-card .bzc-sessions-list-row {
    flex-direction: column;
    grid-row-gap: 0.5rem;
    align-items: flex-start;
  }

  .bz-cancellation-page .bz-cancellation-card-row,
  .bz-paynow-page .card .bz-cancellation-card-row,
  .bz-paynow-page.redeem-by-pakage .card .bz-cancellation-card-row,
  .bz-paynow-page.redeem-by-complementary .card .bz-cancellation-card-row,
  .bz-paynow-page.no-redeem-by-complementary .card .bz-cancellation-card-row,
  .bz-paynow-page.inc-taxes .card .bz-cancellation-card-row {
    grid-template-columns: 1fr;
    grid-row-gap: 0.5rem;

  }

  .bz-cancellation-page .loading .bz-cancellation-card-row {
    grid-template-columns: 1fr 5rem;

    .column-8 {
      grid-area: 1/2/span 3/span 1;
      height: 100%;
    }
  }

  .bz-cancellation-page .bz-cancellation-card-row.charge-dropdown {
    grid-template-columns: 1fr !important;
    grid-row-gap: 0.5rem;

  }

  .bz-cancellation-page .loading .bz-cancellation-card-row.charge-dropdown {
    grid-template-columns: 1fr;

    .column-8 {
      grid-area: 1/2/span 3/span 1;
    }
  }

  .bz-cancellation-page .bz-cancellation-card-row.payment-profile {
    grid-template-columns: 1fr !important;
    grid-row-gap: 0.5rem;

  }

  .bz-cancellation-page .loading .bz-cancellation-card-row.payment-profile {
    grid-template-columns: 1fr 5rem;

    .column-4,
    .column-5,
    .column-7 {
      grid-column: 1/3;
    }

    .column-8 {
      grid-area: 1/2/span 3/span 1;
      height: 100%;
    }
  }

  .bzc-attendees-list .bzc-attndees-card>.card-body .buzopsListArrow {
    float: unset;

    .bz-elipsis-icon {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
    }
  }

  .bzc-attendees-list .bzc-attndees-card>.card-body .bz-attendees-row {
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;

    .buzopsListItem {
      padding-right: 2.5rem;
    }

    .bz-statuses.Notes {
      margin-right: 0;
    }

    .k-avatar {
      display: none;
    }
  }
}

@media screen and (max-width: 700px) {

  .my-bookings,
  .my-bookings_booking-body_scheduler {
    flex-direction: column;
  }
}


//media query responsive


@media (max-width:360px) {

  .k-calendar .k-month .k-link,
  .k-calendar .k-calendar-monthview .k-link {
    width: 35px;
    height: 35px;
  }

  .bz-recurence .bz-recurence-count.count {
    width: 60% !important;
  }

  .bz-checkout-page .buz-online-list-view b,
  .bz-checkout-page .k-panelbar>.k-item>.k-link,
  .bz-checkout-page .k-panelbar>.k-panelbar-header>.k-link {
    font-size: 0.875rem !important;
  }

  .k-calendar .k-calendar-view table,
  .k-calendar .k-calendar-view .k-calendar-table {
    border-spacing: 0 8px !important;
  }

  .k-window.k-dialog {
    width: 98% !important;
    max-width: 21rem;
  }
}

@media (min-width:375px)and (max-width:410px) {
  .not-splitted .bz-recurence.bz-recurence-sm .bz-recurence-count.custom {
    width: 45% !important;
  }

  .bz-recurence.bz-recurence-sm .bz-recurence-count.count {
    width: 62% !important;
  }
}

@media(max-width:410px) {
  .bz-calender-sec>.k-calendar>.k-calendar-view .k-calendar-tbody .k-calendar-th {
    width: 40px;
  }

  .k-calendar .k-calendar-view table,
  .k-calendar .k-calendar-view .k-calendar-table {
    border-spacing: 3px;
  }
}

@media (min-width:411px) and (max-width:479px) {
  .bz-recurence {

    .bz-recurence-dropdown {
      width: 100%;
      float: left;
    }

    .bz-recurence-count {
      width: 66% !important;
      float: left;

      .k-numerictextbox {
        width: 100%;
      }
    }

    .bz-recurence-count.custom {
      width: 57% !important;
    }

    .bz-recurence-count.count {
      width: 65% !important;
    }

    .bz-check-btn {
      float: left;
      text-align: right;
      margin-top: 0.5rem;
    }

    .bz-recurence-count.count+.bz-check-btn {
      width: 6.6rem !important;
    }

  }

  .not-splitted {
    .bz-recurence {
      .bz-recurence-count.custom {
        width: 51% !important;
      }

      .bz-check-btn {

        width: 6.7rem !important;
      }

      .bz-check-btn.custom {
        width: 9.6rem !important;
      }
    }
  }

  .bz-calender-sec>.k-calendar>.k-calendar-view .k-calendar-tbody .k-calendar-th {
    width: 48px;
  }
}

@media (max-width:479px) {
  .bz-mybookings-dialog-box .k-dialog .k-dialog-content {
    padding: 1rem;
  }

  .bz-upcoming-appoinments-card {
    height: auto !important;
    margin-bottom: 0rem;

    .k-listview {
      height: calc(100vh - 28rem) !important;


    }
  }

  .bz-service-selection .bz-service-dropdown {
    min-height: inherit;

  }

  .bz-bookings-area {
    height: auto;
    padding: 0;


    .bz-duration-section {
      max-height: inherit;
    }
  }

  .bz-slot-section .bz-selected-service-card {
    height: auto;
  }

  .caendar-section-heading {
    margin-bottom: 0;
    text-align: center;
  }

  .bz-calender-sec {
    .k-calendar {
      .k-calendar-th {
        height: 3rem !important;
      }

      .k-next-view {
        right: 3%;
      }

      .k-prev-view {
        right: 17.5%;
      }
    }
  }


  .bz-recurence {

    display: block;

    div {
      margin: 0;
    }

  }


  .bz-custom-recurrence-box {
    width: 98%;
    margin: auto 1%;

    .k-widget.k-dropdown {
      width: 8em;
    }

    .dialog-options_custom_content_options {
      .k-dropdown {
        width: 100%;
      }
    }
  }

  .bz-slot-section .bz-upcoming-apponments-card>.card-body {
    padding: 1rem 0;
  }

  .bz-upcoming-appoinment-container {
    max-height: inherit;
  }

  .bz-checkout-page .order-summary-form .order-summary-card .k-form-field {
    flex: 1 0 70%;
  }

  .payment-profile-image {
    width: 1.5rem;
  }

  .k-dialog-content>p {
    margin: 0 !important;
  }

  .k-dialog-buttongroup.k-actions.k-actions-stretched>.k-button {
    margin: 0 0.3rem;
  }

  .bz-service-selection.selected>.col-md-12 {
    display: flex;
  }

  .bz-service-selection.selected .bz-back-btn {
    left: -2rem;
  }

  .public-layout .bz-navBar .bzo-mobile-menu .bz-userDetails-sec-mobile-view .k-button-icon {
    min-width: auto;
    margin: 0;
  }
}


@media (max-width:767px) {
  .mble-subscription {
    text-align: left !important;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .k-tabstrip-items {
    flex-flow: unset !important;
  }

  .colomn-section-heading {
    margin-bottom: 1rem;
    text-align: center;
    margin-top: 1rem;
  }

  .bz-selected-date {
    text-align: center;
  }

  .bz-recurence-row {
    margin-bottom: 2rem;
  }

  .bz-slot-section {
    .bz-upcoming-apponments-card {
      border: 0;
      box-shadow: none;
    }
  }

  .bz-servicebased-appoinments {
    display: none;
  }

  .bz-upcoming-appoinments-card {
    border: 0 !important;
    border-radius: 0;
    box-shadow: none;
    overflow: visible;

    .k-card-body {
      padding: 0;

      h5 {
        font-size: 1.25rem;
        padding-top: 0.5rem;
        text-align: center;
      }
    }
  }



  .bz-slotSelection-Container {

    .bz-avail-service,
    .bz-avail-providers {
      font-size: 1.25rem !important;
      font-weight: 400;
    }
  }

  .bz-service-dropdown label,
  .bz-provider-container>h5,
  .bz-bookings-area h5,
  .bz-slot-section h5,
  .bz-about-desc-card h5 {
    font-size: 1.25rem;
    font-weight: 400;
  }

  .bz-service-card {
    h5 {
      font-size: 1rem;
    }
  }

  .bz-slot-section {
    .small.text-muted {
      font-size: 100%;
    }
  }

  .bz-service-selection {
    .bz-service-dropdown {

      >.k-input>span {
        font-size: 1rem !important;

        >span {
          font-size: 0.875rem !important;
        }
      }
    }


  }

  .k-dropdown.customDropdown>.k-dropdown-wrap {

    >.k-input>span {
      font-size: 1rem !important;

      >span {
        font-size: 0.875rem !important;
      }
    }
  }

  .buz-online-list-view b,
  .k-panelbar>.k-item>.k-link,
  .k-panelbar>.k-panelbar-header>.k-link {
    font-size: 1rem !important;
  }

  .bz-service-dropdown>.form-group {
    margin-top: 10px;
    margin-bottom: 0.5rem !important;
  }

  .bz-service-dropdown .k-dropdown {
    width: 17rem !important;
  }

  .bz-recurence-label {
    font-size: 0.875rem;
    margin-top: 0.5rem;
    font-weight: 400;
    display: block;
    text-align: left;
  }

  .bz-provider-Name {
    z-index: 9;
    background-color: #fff;

    h6 {
      font-weight: 500;
      margin-left: 0.25rem;

    }
  }

  .bz-service-Name.bz-hide {
    display: none;
  }

  .bz-service-Name {
    display: block;
    font-size: 26px !important;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0;
    padding-bottom: 0.5rem;
    padding-top: 0.25rem !important;
    word-break: break-word;
    width: 100%;
  }

  .bz-service-selection.selected {

    .bz-back-btn {
      position: absolute;
    }
  }

  .bz-provider-container.selected {
    display: none;
  }

  .bz-slotSelection-Container {
    margin-bottom: 2rem;
  }

  .bz-service-card {

    min-height: 80px;
    max-height: 80px;

    >.card-body {
      padding: 0.75rem 1.75rem;

      .bz-arrow-icon {
        font-size: 1.5rem;
      }
    }
  }

  .bz-trainers-card {
    min-height: 108px;
    max-height: 108px;
  }

  .bz-slotSelection-Container .row>.col-sm-6:last-child>.bz-service-card {
    margin-bottom: 0 !important;
  }

  .bz-dot::before {
    width: 1.3rem;
    height: 1.3rem;
  }

  .bz-avail-service,
  .bz-avail-providers {
    margin-bottom: 1rem;
    text-align: left;
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 10px;
  }

  .bz-avail-service+hr,
  .bz-avail-providers+hr {
    display: none;
  }

  .bz-service-details {
    margin-bottom: 0.5rem;
    text-align: center;

    .card {
      border: 0;
      border-radius: 0;
    }

    >label {
      font-size: 1.25rem;
      font-weight: 400;
      margin-bottom: 0;
      margin-top: 0.25rem;
    }

  }

  .bz-upcomingappoinment-sec {
    margin-bottom: 0 !important;
  }

  .bz-service-details.bz-hide {
    display: none;
  }

  .bz-back-btn {
    position: absolute;
    background-color: #ffffff;
    border-color: #008DB9;
    color: #008DB9;
    min-width: 2.5rem;
    padding: 0.2rem;
    z-index: 9;
    left: 0.25rem;
  }

  .caendar-section-heading {
    margin-bottom: 1rem;
    text-align: center;
  }

  .bz-upcoming-appoinments-card .k-listview .media-body p {
    line-height: 1.8;
  }
}

@media (min-width:768px) {

  .bz-provider-Name,
  .bz-service-Name {
    display: none;
  }

  .k-calendar .k-calendar-view table,
  .k-calendar .k-calendar-view .k-calendar-table {
    border-spacing: 0 8px;
  }

  .bz-service-details.bz-hide,
  .bz-service-details {
    display: none;
  }
}

@media (max-width:991px) {

  .bz-calender-sec {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .bz-recurence-md,
  .bz-recurence-sm {
    justify-content: center;
    flex-wrap: wrap;
  }


  .bz-recurence {

    display: block;

    div {
      margin: 0;
    }

    .bz-recurence-dropdown {
      width: 100%;
      float: left;
    }

    .bz-recurence-count {
      width: 67%;
      float: left;
      margin-right: 3%;
      margin-top: 1rem;
    }

    .bz-recurence-count.custom {
      width: 44%;
    }

    .bz-recurence-count.count {
      width: 58%;
    }

    .bz-recurence-count.count+.bz-check-btn {
      width: 34%;
    }

    .bz-check-btn {
      float: left;
      width: 6.7rem;
      text-align: right;
      margin-top: 1rem;
    }

    .bz-check-btn.custom {
      width: 9.7rem;
    }

  }

}

@media (min-width:768px) and (max-width:1024px) {
  .bz-slotSelection-Container>div>div.row {
    max-height: calc(100vh - 12.4rem);
    overflow: auto;

  }

  .bz-slotSelection-Container .not-splitted .my-bookings_booking-body_trainers>div.row {
    max-height: calc(100vh - 12.1rem);
    overflow: auto;
  }

  .bz-slotSelection-Container {
    .bz-trainer-details-dropdown {
      margin-left: 0;
    }
  }

  .bz-slot-section .bz-selected-service-card {
    height: auto;
  }

  .bz-save-cancel-btn {
    margin: 2rem 0;
  }

  .bz-bookings-area {
    height: auto;

    .bz-duration-section {
      max-height: inherit;
    }
  }

  .bz-calender-sec {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .bz-recurence-md,
  .bz-recurence-sm {
    justify-content: center;
    flex-wrap: wrap;
  }

  .colomn-section-heading {
    margin-bottom: 1rem;
  }

  .bz-recurence-row {
    margin-bottom: 2rem;
  }

  .bz-calender-sec>.k-calendar>.k-calendar-view .k-calendar-tbody .k-calendar-th {
    width: 46px;
  }

  .bz-upcoming-appoinments-card {
    height: calc(100vh - 135px);
  }

  .bz-slotSelection-Container>.row.row-scroll {
    max-height: calc(100vh - 8rem);
    overflow: auto;
  }

  .bz-slot-section .bz-upcoming-apponments-card {
    border: 0;
    box-shadow: none;
  }

  .bz-recurence .bz-recurence-count.count {
    width: 63%;
  }

  .bz-recurence .bz-recurence-count.custom {
    width: 48%;
  }
}

@media (max-width:1024px) {


  .bz-save-cancel-btn {
    margin-bottom: 0;
    position: fixed;
    bottom: 0;
    z-index: 9;
    background-color: #fff;
    width: 100%;
    left: 0;
    right: 0;
    padding: 0.5rem 0rem;
    margin: 0;
  }

  .not-splitted .bz-save-cancel-btn {
    display: none;
  }


}

@media (min-width:1024px) {


  .bz-recurence {

    display: block;

    div {
      margin: 0;
    }

    .bz-recurence-dropdown {
      width: 100%;
      float: left;
    }

    .bz-recurence-count {
      width: 71%;
      float: left;
      margin-right: 1.5rem;
      margin-top: 1rem;

      .k-numerictextbox {
        width: 100%;
      }
    }

    .bz-recurence-count.custom {
      width: 61%;
    }

    .bz-recurence-count.count {
      width: 71%;
    }

    .bz-check-btn {
      float: left;
      width: 22%;
      text-align: right;
      margin-top: 1rem;
    }

    .bz-check-btn.custom {
      width: 32%;
    }

  }
}

@media (max-width:767px) and (orientation:landscape) {

  .bz-recurence {


    .bz-recurence-dropdown {
      width: 100%;
      float: left;
    }

    .bz-recurence-count {
      width: 72% !important;
      float: left;
      margin-right: 3%;
      margin-top: 1rem;

      .k-numerictextbox {
        width: 100%;
      }
    }

    .bz-recurence-count.count {
      width: 77% !important;
    }

    .bz-recurence-count.custom {
      width: 69% !important;
    }

    .bz-check-btn {
      float: left;
      width: 18% !important;
      margin-top: 1rem;
    }

    .bz-check-btn.custom {
      width: 25% !important;
    }

  }

  .bz-provider-Name {
    position: unset;
  }

  .bz-upcoming-appoinments-card {
    height: auto !important;
  }

  .bz-recurence-row {
    width: 23.5rem;
    margin: auto;
    margin-bottom: 2rem;
  }

  .bz-recurence-row.selected {
    width: auto;
    margin: unset;
    margin-bottom: 2rem;

    >div {
      padding: 0;
    }
  }

  .bz-upcoming-appoinment-container {
    max-height: inherit;
  }
}

@media (min-width:768px) and (max-width:991px) and (orientation:landscape) {
  .bz-slotSelection-Container .not-splitted .my-bookings_booking-body_trainers>div.row {
    max-height: calc(100vh - 9.5rem) !important;
  }

  .bz-service-card {

    min-height: 80px;
    max-height: 80px;
    margin-bottom: 1rem !important;

    >.card-body {
      padding: 0.75rem 1.75rem;

      .bz-arrow-icon {
        font-size: 1.5rem;
      }
    }
  }

  .bz-slotSelection-Container .not-splitted .my-bookings_booking-body_trainers>div.row {
    max-height: calc(100vh - 9.5rem);
  }

  .caendar-section-heading {
    margin-bottom: 1rem;
  }
}

@media (max-width:1024px) and (orientation:landscape) {


  .bz-upcoming-appoinments-card>.k-card-body>.my-bookings_upcoming-appointments_body>.bz-service-upcoming-appoinments.bz-servicebased-appoinments>.k-listview {
    height: calc(100vh - 34rem);
  }
}

@media (max-width:991px) and (orientation:landscape) {
  .bz-upcoming-appoinments-card {
    height: calc(100vh - 117px);
  }

  .bz-upcoming-appoinments-card>.k-card-body>.my-bookings_upcoming-appointments_body>.bz-service-upcoming-appoinments.bz-servicebased-appoinments>.k-listview {
    height: calc(100vh - 10rem) !important;
  }

  .bz-slotSelection-Container .not-splitted .my-bookings_booking-body_trainers>div.row {
    max-height: inherit;
  }

  .bz-bookings-area {
    height: auto;

    .bz-duration-section {
      max-height: inherit;
    }
  }

  .bz-slot-section .bz-selected-service-card {
    height: auto;
  }

  .bz-save-cancel-btn {
    margin: 0;
  }
}

@media (min-width:769px) and (max-width:991px) and (orientation:landscape) {
  .bz-recurence {

    display: block;

    div {
      margin: 0;
    }

    .bz-recurence-dropdown {
      width: 100%;
      float: left;
      margin-right: 2.5%;
    }

    .bz-recurence-count {
      width: 67% !important;
      float: left;
      margin-right: 3%;
      margin-top: 1rem;

      .k-numerictextbox {
        width: 100%;
      }
    }

    .bz-recurence-count.custom {
      width: 52% !important;
    }

    .bz-recurence-count.count {
      width: 62% !important;
    }

    .bz-check-btn {
      float: left;
      width: 6.7rem;
      margin-top: 1rem;
    }

  }

  .bz-upcoming-appoinment-container {
    max-height: inherit;
  }
}

@media (min-width:1200px) and (max-width:1399px) {
  .bz-upcomingappoinment-sec {
    flex: 0 0 auto;
    width: 29%;
  }

  .bz-slotSelection-Container {
    flex: 0 0 auto;
    width: 71%;
  }

  .bz-slot-section {
    flex: 0 0 auto;
    width: 28%;
  }

  .bz-upcoming-apponments-sm-card img.bz-user-img {
    width: 3rem;
  }

  .bz-upcoming-apponments-sm-card .media-body {
    line-height: 1.4;
  }

  .bz-elipsisis-icon {
    right: 0.25rem;
  }

  .bz-arrow-icon {
    right: 1rem;
  }

  .bz-slotSelection-Container h5 {
    font-size: 1.25rem !important;
  }

  .bz-slotSelection-Container>div>div.row,
  .bz-slotSelection-Container .not-splitted .my-bookings_booking-body_trainers>div.row {
    max-height: calc(100vh - 12.1rem);
    overflow: auto;
  }

  .bz-service-selection .bz-service-dropdown {
    min-height: 6.2rem;
  }

  .bz-service-selection .bz-service-dropdown.service-overview {
    min-height: 5rem;
  }

  .bz-bookings-area {
    margin-top: 0rem !important;

    .bz-duration-section {
      max-height: calc(100vh - 22rem);
      min-height: 13rem;
    }

    .bz-recurence {
      padding-top: 0.5rem !important;
    }
  }

  .splitted .bz-bookings-area {
    height: calc(100vh - 17.8rem);
  }

  .caendar-section-heading {
    margin-bottom: 0.5rem;
  }

  .colomn-section-heading {
    margin-bottom: 1.7rem;
  }

  .bz-duration-section {
    max-height: inherit;
  }

  .splitted .bz-calender-sec>.k-calendar>.k-calendar-view .k-calendar-tbody .k-calendar-th {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    padding-bottom: 2rem;
  }



  .splitted .bz-calender-sec>.k-calendar>.k-prev-view {
    right: 4.5rem;
  }

  .bz-upcoming-appoinments-card>.k-card-body>.my-bookings_upcoming-appointments_body>.bz-service-upcoming-appoinments.bz-servicebased-appoinments {
    >.k-listview {
      height: calc(100vh - 27rem);
    }
  }


  .not-splitted .bz-recurence {

    display: block;
    width: 353px;

    div {
      margin: 0;
    }

    .bz-recurence-dropdown {
      width: 100%;
      float: left;
    }

    .bz-recurence-count {
      width: 50%;
      float: left;
      margin-right: 1.5rem;
      margin-top: 1rem;
    }

    .bz-recurence-count.custom {
      width: calc(100% - 11.5rem);
      margin-right: 0rem;
    }

    .bz-recurence-count.count {
      width: calc(100% - 8rem);
      margin-right: 0rem;
    }

    .bz-recurence-count.count+.bz-check-btn {
      width: 8rem;

      >.k-button {
        margin-right: 0;
      }
    }

    .bz-check-btn {
      float: left;
      width: 47%;
      text-align: right;
      margin-top: 1rem;
    }

    .bz-check-btn.custom {
      width: 11.5rem;
    }

  }

  .splitted .bz-recurence {

    display: block;

    div {
      margin: 0;
    }

    .bz-recurence-dropdown {
      width: 100%;
      float: left;
    }

    .bz-recurence-count {
      width: 50%;
      float: left;
      margin-right: 3%;
      margin-top: 0.5rem;
    }

    .bz-recurence-count.custom {
      width: 36%;
    }

    .bz-check-btn {
      float: left;
      width: 47%;
      text-align: right;
      margin-top: 0.5rem;
    }


  }

  .bz-bookings-area {
    height: calc(100vh - 15.5rem);
    overflow: auto;
  }

}


@media (min-width:1400px) {
  .bz-upcoming-appoinments-card h5 {
    font-size: 1.5rem;
  }

  .bz-calender-sec>.k-calendar>.k-calendar-view .k-calendar-td>.k-link {
    width: 32px;
    height: 32px;
  }

  .bz-recurence-row.selected {
    .bz-recurence {
      width: 354px;
    }
  }

  .splitted {
    .bz-recurence-row.selected {
      .bz-recurence {
        width: 354px;
      }
    }
  }

  .not-splitted .bz-recurence {

    display: block;
    width: 100%;

    div {
      margin: 0;
    }

    .bz-recurence-dropdown {
      width: 100%;
      float: left;
    }

    .bz-recurence-count {
      width: 63%;
      float: left;
      margin-right: 3%;
      margin-top: 0.5rem;
    }

    .bz-recurence-count.custom {
      width: calc(100% - 11.5rem);
      margin-right: 0;
    }

    .bz-recurence-count.count {
      width: calc(100% - 8rem);
      margin-right: 0;
    }

    .bz-recurence-count.count+.bz-check-btn {
      width: 8rem;

      >.k-button {
        margin-right: 0;
      }
    }

    .bz-check-btn {
      float: left;
      width: 11.5rem;
      text-align: right;
      margin-top: 0.5rem;
    }

  }

  .splitted .bz-recurence {

    display: block;

    div {
      margin: 0;
    }

    .bz-recurence-dropdown {
      width: 100%;
      float: left;
    }

    .bz-recurence-count {
      width: 55%;
      float: left;
      margin-right: 3%;
      margin-top: 0.5rem;
    }

    .bz-recurence-count.custom {
      width: 39%;
    }

    .bz-check-btn {
      float: left;
      width: 41%;
      text-align: right;
      margin-top: 0.5rem;
    }


  }

  .bz-bookings-area {
    margin-bottom: 3rem;
  }
}


@media (min-width:361px) and (max-width:479px) {}

@media (max-width:360px) {

  .bz-recurence .bz-recurence-count.count {
    width: 60% !important;
  }

  .not-splitted .bz-recurence .bz-recurence-count.custom {
    width: 43% !important;
  }

}

@media (max-width:320px) {

  .k-calendar .k-month .k-link,
  .k-calendar .k-calendar-monthview .k-link {
    width: 28px !important;
    height: 28px !important;
  }

  .bz-calender-sec>.k-calendar>.k-calendar-view .k-calendar-tbody .k-calendar-th {
    padding-left: 0.2rem !important;
    padding-right: 0.2rem !important;
  }

  .bz-calender-sec .k-calendar .k-next-view {
    right: 0;
  }

  .bz-calender-sec>.k-calendar>.k-calendar-view .k-calendar-td {
    width: 40px;
    height: 40px;
  }

  .k-calendar .k-calendar-view table,
  .k-calendar .k-calendar-view .k-calendar-table {
    border-spacing: 2px 10px !important;
  }

  .bz-recurence {



    .bz-recurence-count {
      width: 60% !important;
      float: left;

      .k-numerictextbox {
        width: 100%;
      }
    }

    .bz-recurence-count.custom {
      width: 45% !important;
    }

    .bz-recurence-count.count {
      width: 53% !important;
      margin-right: 0.5rem;
    }

    .bz-check-btn {
      float: left;
      text-align: right;
      margin-top: 0.5rem;
    }

    .bz-recurence-count.count+.bz-check-btn {
      width: 6.65rem !important;
    }

  }

  .not-splitted {
    .bz-recurence {
      .bz-recurence-count.custom {
        width: 100% !important;
        margin-right: 0.5rem;
      }

      .bz-check-btn {

        width: 6.7rem !important;
        margin-top: 1rem;
      }

      .bz-check-btn.custom {
        width: 100% !important;

        >.k-button {
          width: 100%;
          margin: 0;
        }
      }
    }
  }

  .k-window.k-dialog {
    max-width: 19rem;
  }

  .bz-custom-recurrence-box {
    .dialog-options_recurrence {
      display: flex;
      flex-direction: column;

      >.k-numerictextbox {
        margin-top: 1rem;
        width: 100%;
      }

      .k-widget.k-dropdown {
        width: 100%;
      }
    }
  }

  .public-layout .bz-navBar .bzo-mobile-menu {
    .bz-userDetails-sec-mobile-view {
      .k-button-icon {
        min-width: auto;
        margin: 0;
      }
    }
  }
}

@media (max-width:568px) and (orientation:landscape) {
  .bz-recurence .bz-recurence-count.count {
    width: 74% !important;
  }

  .bz-recurence .bz-check-btn.custom {
    width: 29% !important;
  }

  .bz-recurence .bz-recurence-count {
    width: 68% !important;
  }

  .bz-recurence .bz-recurence-count.custom {
    width: 64% !important;
  }

  .public-layout .bz-navBar .bz-userDetails-sec {
    position: absolute;
    right: 2.25rem;
  }

  .bz-slotSelection-Container .row>.col-sm-6:last-child>.bz-service-card {
    margin-bottom: 1rem !important;
  }
}


//media query
@media screen and (min-width:1800px) {
  .bz-online-main-row>.bz-content-area .bz-appoinments-page.bz-appoinments-info {
    grid-template-columns: repeat(3, 1fr);
  }

  .bz-service-appoinment-info.bz-subsection-row {
    >.left-section .bz-appoinments-page.bz-appoinments-info {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .bz-related-transaction-grid {
    max-width: 67.3rem;
  }
}

@media screen and (min-width:1550px) {
  .bz-grid-colom-width {
    colgroup>col {
      width: 13.5%;

      &:nth-child(7) {
        width: 16.5%;
      }
    }
  }

}

@media screen and (min-width:1500px) {
  .bz-payment-options {
    max-width: 35rem;
  }

}

@media screen and (max-width:1460px) {
  .bz-add-card-field>.payment-card-input>.col-md-10 #field-wrapper>.row {
    flex-wrap: nowrap;
  }

  .bz-grid-colom-width {
    colgroup {

      >col {
        &:nth-child(1) {
          width: 130px;
        }

        &:nth-child(2) {
          width: 200px;
        }

        &:nth-child(3) {
          width: 200px;
        }

        &:nth-child(4) {
          width: 90px;
        }

        &:nth-child(5) {
          width: 90px;
        }

        &:nth-child(6) {
          width: 180px;
        }

        &:nth-child(7) {
          width: 150px;
        }
      }
    }
  }
}

@media screen and (max-width:1390px) {
  .bzc-purchase-class-list-row.bz-class-selected {
    .bz-show-packages-checkbox {
      position: initial;
    }

    .bz-purchase-class-filter {
      justify-content: flex-start;
    }
  }
}

@media screen and (max-width:1366px) {
  .bz-online-main-row>.bz-content-area .bz-payment-info-grid colgroup>col:nth-child(4) {
    width: 100px !important;
  }

  .bz-online-main-row>.bz-content-area .bz-payment-info-grid colgroup>col:nth-child(3) {
    width: 100px !important;
  }

  .bz-online-main-row>.bz-main-menu-area .main-menu .k-menu {
    padding: 0;
  }

  .bz-overview-page-row.bz-subsection-row {
    >.left-section {
      flex-basis: calc(100% - 16rem);
    }

    >.right-section {
      flex-basis: 25% !important;
      -webkit-flex-basis: 25% !important;
    }
  }
}

@media screen and (max-width:1365px) and (min-width:1200px) {
  .bz-service-appoinment-info.bz-subsection-row {
    flex-wrap: nowrap;

    >.left-section .bz-appoinments-page.bz-appoinments-info {
      grid-template-columns: 1fr;
    }
  }

  .bz-online-main-row>.bz-content-area .bz-appoinments-info {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width:1300px) {

  .bz-add-card-field>.payment-card-input>.col-md-10 #field-wrapper>.row>.col-6.col-sm-2,
  .bz-add-card-field>.payment-card-input>.col-md-10 #field-wrapper>.row>.col-6.col-sm-3 {
    flex-basis: 4rem;
    width: 4rem;
  }

  .bz-add-card-field>.payment-card-input>.col-md-10 #field-wrapper>.row>.col-8.col-sm-5 {
    flex-basis: calc(100% - 12rem);
    width: calc(100% - 12rem);
  }

  .bz-service-overview-page {
    .bz-subsection-row {
      >.left-section {
        .bz-appoinments-info {
          grid-template-columns: 1fr;
        }
      }

    }
  }
}

@media screen and (min-width:1249px) and (max-width:1310px) {
  .bz-subsection-row>.left-section:only-child {
    flex-basis: calc(100% - 15rem);
  }
}

@media screen and (max-width:1249px) {
  .bz-subsection-row.bz-myprofile-container {
    >.left-section {
      flex-basis: 95%;
    }
  }

  .bz-subsection-row.bz-overview-page-row {

    >.right-section {
      flex-basis: 95%;
    }
  }

  .bz-subsection-row.bz-plan-overview {
    >.left-section {
      flex-basis: 95%;
    }
  }
}

@media screen and (min-width:991px) and (max-width:1249px) {
  .bz-online-main-row>.bz-content-area .bz-appoinments-page.bz-appoinments-info {
    grid-template-columns: repeat(2, 1fr);
  }

}

@media screen and (max-width:1199px) and (min-width:991px) {
  .bz-service-appoinment-info.bz-subsection-row {

    >.left-section {
      flex-basis: 100%;
    }

    >.right-section {
      flex-basis: 100%;
    }
  }
}

@media screen and (max-width:1100px) {}

@media screen and (max-width:1200px) and (min-width:1025px) {
  .bz-online-main-row {
    >.bz-content-area {
      .bz-group-services-list.bz-past-GroupServices .bzc-sessions-list-card .bzc-sessions-list-row {
        grid-template-columns: repeat(2, 1fr) 9rem;

        >.bzc-close-icon {
          grid-area: 1/3/2/4;
          grid-row: 1 / span 2;
          height: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: flex-start;
        }
      }
    }
  }

  .bz-group-services-list.bz-past-GroupServices.statuses-alignment {
    .bzc-sessions-list-card .bzc-sessions-list-row {
      grid-template-columns: 10rem 9.5rem 10rem 6.5rem 7rem;
      justify-content: space-between;
    }
  }

}

@media screen and (min-width:1025px) {}

@media screen and (max-width:1024px) {

  .bz-menu-close-icon,
  .bz-menu-btn.k-button {
    display: inline-flex;
    width: 2.5rem;
    height: 2.5rem;
  }

  .bzo-mobile-responsive {
    >.bz-main-menu-area {
      display: none;

      .bz-summary-menus-card {
        border-radius: 0;
      }
    }

    >.bz-content-area {
      width: 100%;
      margin-left: 0;

    }

    .bz-menu-btn {
      position: unset;
      z-index: 999;
      top: 0.75rem;
      border: 0;
    }

    .bz-menu-btn.bz-menu-btn-staff {
      position: unset;
      padding-left: 0 !important;
      justify-content: flex-start;
      margin-right: 0.5rem;
    }
  }

  .bzo-mobile-responsive.active {
    >.bz-main-menu-area {
      display: block;
      position: fixed;
      top: 0 !important;
      left: 0;
      bottom: 0;
      height: 100% !important;
      z-index: 99999;
      background-color: #fff;
    }

    .k-overlay.bz-overlay-menu {
      display: block;
      z-index: 9;
    }
  }

  .mobile-view {
    .bz-navBar-logo-sec {
      padding-left: 0;
    }
  }

  .mobile-view.user-not-login {
    .bz-navBar-logo-sec {
      padding-left: 0;
    }
  }
}

@media screen and (max-height:991px) {
  .bz-service-card.bz-plans-card {
    max-height: inherit;
    min-height: 90%
  }
}

@media screen and (min-width:621px) and (max-width:1100px) {
  .bz-cancellation-page-new .k-listview .bz-cancellation-card-row {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width:621px) and (max-width:991px) {
  .bz-online-main-row>.bz-content-area .bz-appoinments-page.bz-appoinments-info {
    grid-template-columns: repeat(2, 1fr);
  }

  .bz-add-card-field>.payment-card-input>.col-md-10>.k-form-field>.credit-card-input-form {
    max-width: 80%;
  }
}

@media screen and (min-width:621px) and (max-width:860px) {
  .bz-paynow-page {
    .card {
      .bz-cancellation-card-row {
        grid-template-columns: 240px 315px !important;

        .column-4 {
          grid-area: 1/3/1/4 !important;
        }
      }
    }
  }
}

@media screen and (min-width:601px) and (max-width:991px) {
  .bz-service-appoinment-info.bz-subsection-row {

    >.left-section {
      flex-basis: 100%;
    }

    >.right-section {
      flex-basis: 100%;
    }
  }
}

@media screen and (min-width:767px) and (max-width:921px) {
  .bz-classList-container.k-card>.k-card-body {
    .k-listview {
      .bz-packages-list-new {
        grid-template-columns: 1fr 1fr 1fr;
        padding-top: 35px !important;

        .section-9 {
          grid-area: 4/1/4/4;
        }
      }
    }
  }

  .bz-service-name {
    padding-top: 0px !important;
  }
}

@media screen and (min-width:600px) and (max-width:767px) {
  .bz-classList-container.k-card>.k-card-body {
    .k-listview {
      .bz-packages-list-new {
        grid-template-columns: 1fr 1fr 1fr;
        padding-top: 30px !important;



        .section-9 {
          grid-area: 5/1/5/4;
        }
      }
    }
  }

  .bzc-sessions-list-card-new .card-body {
    padding: 35px 20px 20px 20px;
  }


  .group-serv-list .section-5.icons {
    display: flex;
    align-items: center;
    position: absolute;
    width: auto;
    bottom: 0;
    right: 12px;
    margin-bottom: 14px;
  }
}

@media screen and (max-width:800px) {

  .bz-overview-appoinmentsInfo>.card-body>.media .media-body .d-flex.apptrainername {
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
  }

  .bz-upcoming-apponments-sm-card img.bz-user-img {
    align-self: start !important;
  }

  .bz-overview-appoinmentsInfo {
    >.card-body {
      >.media {
        .media-body {
          .d-flex {
            .badge {
              margin: 0;
            }
          }

          .bz-service-type {
            margin-top: 0.4rem;
          }
        }
      }
    }
  }

  .widthnopay {
    width: 26px;
    margin-top: 0px;
  }
}

@media screen and (max-width:767px) {
  .rewards .bz-forms-list-new {
    max-height: none;
  }

  .bz-service-card.bz-plans-card {
    max-height: inherit;
  }

  .bz-service-overview-page {
    .bz-subsection-row {
      >.left-section {
        flex-basis: 100%
      }

      >.right-section {
        flex-basis: 100%
      }
    }
  }

  .bz-service-overview-page .bz-subsection-row>.left-section .bz-appoinments-info {
    grid-template-columns: repeat(2, 1fr);
  }

  .bz-schedule-btn-containet {
    width: 100%;
    justify-content: flex-end;
  }

  .bz-online-main-row>.bz-content-area .bz-appoinments-info {
    max-height: initial;
  }
}

@media screen and (min-width:601px) and (max-width:720px) {
  .bz-content-text-row .bz-payment-options.bz-content-body {
    flex-wrap: wrap;
  }
}

@media screen and (min-width:601px) {
  .bz-addon-cancellation {
    .k-dropdown-width-100 {
      max-width: 16rem;
    }

    .k-dropdown {
      max-width: 16rem;
    }

    textarea {
      max-width: 16rem;
    }

    .k-datetimepicker {
      max-width: 16rem;
      width: 100%;
    }

    p.notificxation-blue {
      max-width: 16rem;
      font-size: 80%;
    }

    .bz-plan-note .k-notification-container {
      max-width: 16rem;
    }
  }
}

@media screen and (max-width:600px) {
  .mble-mt-0 {
    margin-top: 0 !important;
  }

  .edit-btn {
    padding-left: 4px !important
  }

  .payment-txt {
    margin-bottom: 0 !important;
  }

  .Booking-Main {
    .two-panel {
      .calendar-main {
        gap: 15px;
        border-bottom: 1px solid #959595;

        .availableTimes-Item {
          .render-time-duration {
            grid-template-columns: 1fr !important;
          }
        }
      }
    }

    .recurrence-selection {
      display: grid !important;
      grid-template-columns: 1fr !important;
      gap: 5px !important;

      .form-group {
        margin-bottom: 0.5rem !important;
      }

      .k-numerictextbox {
        width: 100% !important;
      }

      .bz-recurence-btn {
        text-align: center;
      }
    }
  }

  .payments-grid {
    .payments-inr-grid {
      display: grid;
      grid-template-columns: 1.1fr 1fr;
    }

    .payments-inr-grid2 {
      display: grid;
      grid-template-columns: 2.2fr 0.25fr 2fr;
      align-content: center;
    }

    .bz-content-text-row {
      display: grid !important;
      grid-template-columns: 1fr 1fr !important;

      &:last-child {
        display: flex !important;
      }
    }

    .render-payment-methods {
      .bz-content-text-row {
        display: block !important;

        .bz-content-title {
          margin-bottom: 10px;
        }
      }
    }
  }

  .bz-classList-container.k-card>.k-card-body {
    .k-listview {
      .bz-packages-list-new {
        display: flex;
        grid-template-columns: 1fr 1fr;
        gap: 0 !important;

        .bz-calender-icon {
          >img {
            display: none;
          }
        }

        .bz-provider-name {
          padding-left: 0;
          margin-bottom: 0.5rem;
        }

        .section-1 {
          grid-area: 1/1/3/3;
        }

        .section-9 {
          grid-area: 9/1/6/3;
          margin-top: 10px;
        }
      }
    }
  }

  .bz-online-main-row>.bz-main-menu-area .main-menu .bz-back-btn {
    position: unset;
  }

  .toggle-buttons-class button {
    width: 115px !important
  }

  .bz-classList-container.k-card>.k-card-body {
    .k-listview {
      .buz-online-list-view {
        max-height: fit-content !important;
      }
    }
  }

  .bz-shadow {
    border-radius: 6px !important;
  }

  .time-work-txt {
    text-align: center !important;
  }

  .mb-15 {
    margin-top: 15px !important;
  }

  .bz-addon-cancellation {
    .bz-content-text-row {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .bz-content-title {
        width: 275px;
      }

      .bz-colon {
        display: unset !important;
      }

      .bz-content-body {
        padding-left: 10px;
      }
    }
  }

  .bz-content-text-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .bz-colon {
      display: none;
    }

    .bz-content-body {
      width: 100%;
      flex-wrap: wrap;

      >.subscription-name {
        max-width: 5rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .bz-content-title {
      margin-bottom: 0.25rem;
    }
  }

  .applied-coupons {
    .bz-content-text-row {
      .bz-content-body {
        width: 100% !important;

        .k-dropdown {
          width: 100% !important;
        }

        .bz-payment-options {

          >.subscription-name {
            min-width: 4rem;
            word-break: break-word;
            white-space: break-spaces;
          }
        }
      }
    }
  }

  .bz-online-main-row>.bz-content-area .bz-appoinments-info,
  .bz-online-main-row>.bz-content-area .bz-appoinments-page.bz-appoinments-info {
    grid-template-columns: 1fr;
    padding-right: 0.5rem;
  }

  .bz-online-main-row>.bz-content-area .bz-appoinments-page.bz-appoinments-info {
    max-height: calc(100vh - 250px);
  }

  .bz-subsection-row {
    flex-direction: column;

    >.left-section {
      flex-basis: 100%;
    }

    >.right-section {
      flex-basis: 100%;
    }
  }

  .bz-package-audit-history-list {
    >span {
      display: flex !important;
      flex-direction: row;

      .bz-activity-dis-div {
        padding: 0.25rem 0 0 !important;
      }
    }
  }

  .bz-content-area {
    .bz-mt-2rem.bz-mb-2_5rem {
      margin: 1rem 0;
    }
  }

  .bz-online-main-row>.bz-content-area h5 {
    padding-top: 0 !important;
  }

  .bz-profile-overview {
    .btn-link {
      margin-left: 3.6rem;
    }
  }

  .bz-add-card-field>.payment-card-input>.col-md-10 #field-wrapper>.row {
    display: grid;
    grid-template-columns: 2rem calc(100% - 12rem) 4rem 4rem;
    grid-column-gap: 0.5rem;
    align-items: center;

    >.col-2.col-sm-1 {
      width: 100%;

      img {
        height: 1rem;
      }
    }
  }

  .bz-add-card-field {
    #field-wrapper {
      img {
        margin-right: 0.1rem;
      }

      .col-3 {
        label.fNHWY {
          margin-left: 0;
        }
      }

    }
  }

  .bz-service-overview-page .bz-subsection-row>.left-section .bz-appoinments-info {
    grid-template-columns: 1fr;
  }

  .bz-appoinments-overview-card {
    .bz-content-text-row {
      display: grid;
      grid-template-columns: 7rem 1.5rem 9.6rem;
      .bz-colon {
        display: block;
      }
    }
  }

  .bz-service-payments-page {
    .installemt-grid.bz-installment-details {
      width: 100% !important;
    }
  }

  .subscription-blk {
    .bz-content-text-row.appliedcoupons {
      flex-direction: column !important;

      .bz-content-body {
        justify-content: flex-end;
      }
    }

    .bz-content-text-row {
      flex-direction: row !important;

      .bz-content-title {
        min-width: 10rem !important;
      }

      .bz-colon {
        display: unset !important;
      }

      .bz-content-body {
        padding-left: 10px;
      }
    }

    .update-recurrence {
      .bz-content-text-row {
        flex-direction: row !important;
        display: flex !important;

        .bz-content-title {
          min-width: 10rem !important;
        }

        .bz-colon {
          display: unset !important;
          margin: 0 0.5rem;
        }

        .bz-content-body {
          padding-left: 0px;
        }
      }

      .bz-notify-row.bz-content-text-row {
        flex-direction: column !important;

        >.bz-colon {
          display: none !important;
        }

        >.bz-content-body {
          width: 100% !important;
        }
      }

    }
  }

  .general-info-blk {
    .bz-content-text-row {
      flex-direction: row !important;

      .bz-content-title {
        min-width: 10rem !important;
      }

      .bz-colon {
        display: unset !important;
      }

      .bz-content-body {
        padding-left: 10px;
      }
    }
  }

  .quicksight-flex {
    flex-direction: row !important;

    .bz-content-title {
      min-width: 10rem !important;
    }

    .bz-colon {
      display: unset !important;
    }

    .bz-content-body {
      padding-left: 10px;
    }
  }

  .bz-packages-tags-new .k-chip {
    margin-left: 0 !important;
  }

  .order-summary-class-details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .order-summary-class-name {
      font-size: 13px;
    }

    .order-summary-class-cost {
      font-size: 13px;
    }
  }

  .bzo-footer {
    padding: .5rem 20px 1.5rem !important;
  }

  .Booking-Main .second-panel .calendar-main .availableTimes-Item .render-time-duration {
    grid-template-columns: 1fr !important;
  }

  .suspend-container {
    .bzc-failedTransaction-container {
      .bzc-failedTransactionGrid {
        colgroup {
          >col {
            width: 10rem;
          }
        }
      }
    }
  }

  .bz-subsection-row.one-time-charge {
    max-width: 20rem;

    >.left-section {
      >.bz-mt-2rem {
        margin-top: 0.5rem;
        margin-bottom: 1rem;
      }
    }
  }

  .bz-cancellation-page-new .k-listview .bz-cancellation-card-row {
    grid-template-columns: 1fr;

    .k-dropdown,
    .k-textbox {
      width: 100%;
    }

    .add-payment-profile {
      >.bzc-provider-name {
        width: calc(100% - 5rem);
      }

      >.addNewBtn {
        .k-button {
          margin-right: 0 !important;
        }
      }
    }
  }

  .bz-cancellation-page-new .k-listview {
    .bz-cancellation-card-row.charge-dropdown.refund-amount {

      >.column-4,
      .column-6 {
        grid-area: auto;
      }
    }
  }

  .bz-openPath-credintials {
    .bz-openPath-actions {
      flex-wrap: wrap;

      >.text-danger {
        width: 100%;
      }
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (max-width: 767px) and (min-width: 576px) {
  .Booking-Main .first-panel {
    width: 100%;
  }

  .Booking-Main .second-panel {
    width: 100% !important;
  }

  .Booking-Main .three-panel {
    width: 100%;
  }

  .Booking-Main .one-panel {
    width: 100%;
  }

  .Booking-Main .two-panel {
    width: 100% !important;
  }

  .second-panel.hide-available-times {
    width: 100% !important;
  }

  .Booking-Main .first-panel,
  .Booking-Main .second-panel,
  .Booking-Main .one-panel,
  .Booking-Main .two-panel,
  .Booking-Main .three-panel {
    height: auto !important;
    max-height: auto !important;
    min-height: auto !important;
    padding: 0.75rem;
  }

  .Booking-Main .three-panel {
    height: auto !important;

    .bz-selected-appoinment-container {
      height: auto !important;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 0.75rem;
    }
  }

  .Booking-Main .three-panel .selected-slot-footer {
    margin-top: 0px;
  }

  .Booking-Main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .Booking-Main .second-panel .calendar-main {
    display: flex;
    flex-direction: column;
  }

  .Booking-Main .selected-service-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .Booking-Main .selected-Items-booking .selected-provider-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .Booking-Main .second-panel .calendar-main .calendar-item {
    width: 100% !important;
  }

  .success-component .success-info {
    height: auto !important;
    max-height: auto !important;
    min-height: auto !important;
  }

  .success-component .success-info .card-content {
    width: 100% !important;
    padding: 0px;
  }

  .two-panel {
    .calendar-main {
      .availableTimes-Item {
        .render-time-duration {
          display: grid;
          grid-template-columns: repeat(1, 1fr) !important;
        }
      }
    }
  }

  .bz-subsection-row {
    flex-direction: column;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (max-width: 991px) and (min-width: 768px) {
  .Booking-Main .first-panel {
    width: 40%;
  }

  .Booking-Main .second-panel {
    width: 55% !important;
  }

  .Booking-Main .three-panel {
    width: 100%;
  }

  .Booking-Main .one-panel {
    width: 40%;
  }

  .Booking-Main .two-panel {
    width: 55% !important;
  }

  .second-panel.hide-available-times {
    width: 55% !important;
  }

  .one-panel,
  .two-panel {
    max-height: 85vh !important;
    min-height: 85vh !important;
    overflow: auto;
    padding: 0.75rem;
  }

  .Booking-Main .three-panel {
    height: auto;

    .bz-selected-appoinment-container {
      height: auto;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 0.75rem;
    }
  }

  .Booking-Main .three-panel .selected-slot-footer {
    margin-top: 0px;
  }

  .Booking-Main {
    display: flex;
    flex-direction: row;
  }

  .Booking-Main .second-panel .calendar-main {
    display: flex;
    flex-direction: column;
  }

  .Booking-Main .selected-service-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .Booking-Main .selected-Items-booking .selected-provider-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .success-component .success-info .card-content {
    width: 100% !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199px) {}


/* Extra small devices (phones, 600px and down) */
@media (max-width: 575px) {
  .Booking-Main .first-panel {
    width: 100%;
  }

  .Booking-Main .second-panel {
    width: 100% !important;
  }

  .Booking-Main .three-panel {
    width: 100%;
  }

  .Booking-Main .one-panel {
    width: 100%;
  }

  .Booking-Main .two-panel {
    width: 100% !important;
  }

  .second-panel.hide-available-times {
    width: 100% !important;
  }

  .Booking-Main .first-panel,
  .Booking-Main .second-panel,
  .Booking-Main .one-panel,
  .Booking-Main .two-panel,
  .Booking-Main .three-panel {
    height: auto !important;
    max-height: auto !important;
    min-height: auto !important;
    padding: 0.75rem;
  }

  .Booking-Main .three-panel {
    height: auto !important;

    .bz-selected-appoinment-container {
      height: auto !important;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 0.75rem;
    }
  }

  .Booking-Main .three-panel .selected-slot-footer {
    margin-top: 0px;
  }

  .Booking-Main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .Booking-Main .second-panel .calendar-main {
    display: flex;
    flex-direction: column;
  }

  .Booking-Main .selected-service-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .Booking-Main .selected-Items-booking .selected-provider-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .Booking-Main .second-panel .calendar-main .calendar-item {
    width: 100% !important;
  }

  .success-component .success-info {
    height: auto !important;
    max-height: auto !important;
    min-height: auto !important;
  }

  .success-component .success-info .card-content {
    width: 100% !important;
    padding: 0px;
  }

  .two-panel {
    .calendar-main {
      .availableTimes-Item {
        .render-time-duration {
          display: grid;
          grid-template-columns: repeat(2, 1fr) !important;
        }
      }
    }
  }

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}



@media(max-width: 350px) {
  .disable-renewal-btns {
    flex-direction: column;
    gap: 10px;
  }
}

@media(max-width: 300px) {
  .book-appt-btns {
    text-align: right;

    button {
      margin-bottom: 10px;
    }
  }
}

@media(min-width: 601px) and (max-width: 991px) {
  .bz-classList-container.k-card>.k-card-body {
    .k-listview {
      .bz-groupServices-list-new {
        gap: 10px 0;
        grid-template-columns: 1fr 1fr;
        max-height: fit-content !important;
      }
    }
  }
}

@media(min-width: 768px) {
  .custom-grid-box {
    .bzc-sessions-list-card-new.bz-classes-checkout {
      .card-body {
        padding: 1rem !important;
      }

      .bzc-sessions-list-row-new {
        display: grid;
        grid-template-columns: 1fr 1fr !important;
        justify-items: flex-start !important;

        .section-2 {
          border-left: 1px dotted #ccc !important;
          border-right: 0 !important;
          width: 100% !important;
        }

        .section-3 {
          .bz-main-provider {
            gap: 10px 0 !important;
            flex-wrap: wrap;
          }
        }
      }
    }
  }
}

@media(min-width: 1200px) {
  .custom-grid-box {
    .bzc-sessions-list-card-new.bz-classes-checkout {
      .card-body {
        padding: 1rem !important;
      }

      .bzc-sessions-list-row-new {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr !important;
        justify-items: flex-start !important;

        .section-2 {
          border-left: 1px dotted #ccc !important;
          border-right: 0 !important;
          width: 100% !important;
        }

        .section-3 {
          .bz-main-provider {
            gap: 10px 0 !important;
            flex-wrap: wrap;
          }
        }
      }
    }
  }

  .bz-payment-method-grid {
    width: 800px;
  }

  .linked-subscription-grid {
    width: 800px;
  }

  .bzc-sessions-list-card-new.bz-noPaid-group .bzc-sessions-list-row-new .bzc-session-price>.status-container {
    flex-grow: 1;
    min-width: 70px;
  }

  .bz-custom-fields-grid.bzc-grid {
    colgroup {
      >col {
        &:nth-child(1) {
          width: 25%;
        }

        &:nth-child(2) {
          width: 25%;
        }

        &:nth-child(3) {
          width: 15%;
        }

        &:nth-child(4) {
          width: 15%;
        }

        &:nth-child(5) {
          width: 20%;
        }
      }
    }
  }

  .bz-redeem-package {
    .k-chip-list {
      margin-top: 0 !important;
    }
  }

  .bz-book-Appoinment-dialog.bz-add-group .k-dialog {
    max-width: 30rem;
  }
}

@media(max-width: 1200px) {
  .bz-custom-fields-grid.bzc-grid {
    colgroup {
      >col {
        &:nth-child(1) {
          width: 15rem;
        }

        &:nth-child(2) {
          width: 13rem;
        }

        &:nth-child(3) {
          width: 5.5rem;
        }

        &:nth-child(4) {
          width: 5.5rem;
        }

        &:nth-child(5) {
          width: 15rem;
        }
      }
    }
  }

  .bz-migration-filter-container {
    flex-direction: column;
    align-items: start;

    >.bz-leftSide-filter {
      margin-bottom: 1rem;
    }
  }

  .bz-pakage-overview.bz-subsection-row {
    >.right-section {
      flex: 0 0 100%;
    }

    >.left-section {
      flex-basis: 100%;
    }
  }
}

@media(min-width: 1366px) {
  .custom-grid-box {
    .bzc-sessions-list-card-new.bz-classes-checkout {
      .card-body {
        padding: 1rem !important;
      }

      .bzc-sessions-list-row-new {
        display: grid;
        grid-template-columns: 0.8fr 1fr 0.8fr auto !important;
        justify-items: flex-start !important;

        .section-2 {
          border-left: 1px dotted #ccc !important;
          border-right: 0 !important;
          width: 100% !important;
        }

        .section-3 {
          .bz-main-provider {
            gap: 10px 0 !important;
            flex-wrap: wrap;
          }
        }

      }
    }
  }


  .bz-cancellation-page {
    .package {
      .bz-cancellation-card-row.zero-dollar-payment {
        max-width: initial;
        grid-template-columns: 13rem 13rem 13rem;
        justify-content: space-between;
      }

      .bz-cancellation-card-row.zero-dollar-payment.charge-dropdown {
        grid-template-columns: 13rem 13rem 13rem 10.5rem;
      }
    }
  }

}

@media (min-width:768px) and (max-width:1024px) {
  .custom-grid-box {
    .bzc-sessions-list-card-new.bz-classes-checkout {
      .bzc-sessions-list-row-new {
        .section-2 {
          border-left: 0 !important;
          border-right: 0 !important;
          padding-left: 0 !important;
        }
      }
    }
  }

  .groupclass-session-list.bz-group-card.bz-group-waitlist.addgroupmultisolts {
    grid-template-columns: 1fr 1fr auto;
  }

  .site-checkin-livestream .bzc-checkins-list>.k-listview-content>.ba-activity-card .livestream_list>.row {
    grid-template-columns: 0.5fr 1fr 1fr;
  }
}

@media(max-width: 389px) {
  .bz-addon-cancellation {
    .bz-content-text-row {
      display: block;

      .bz-content-title {
        width: 100%;
        margin-bottom: 10px !important;
      }

      .bz-colon {
        display: none !important;
      }

      .bz-content-body {
        padding-left: 0;
      }
    }
  }
}

// new media query
@media screen and (min-width:1720px) {
  .overview-grid .bz-payment-options {
    grid-template-columns: 1fr 6rem 4.5rem 0.9fr 2.7rem;

    .bz-card-details {
      >img {
        margin-right: 0.4rem;
      }
    }
  }
}

@media screen and (min-width:1500px) {
  .overview-grid .bz-payment-options {
    max-width: 40rem !important;
  }

  .overview-grid .bz-payment-options {
    display: grid;
    grid-gap: 1.5rem;

    >.badge {
      margin: 0;
    }
  }

  .overview-grid .bz-payment-options.test {
    grid-template-columns: 1fr 4rem;
    grid-gap: 0.5rem;

    p {
      display: flex;
      align-items: center;
      margin-bottom: 0;

      .bz-card-details {
        display: inline-flex;
      }
    }

    >.btn-link {
      grid-row: 1 / 2;
      grid-column: 2/3;
    }

  }

  .bz-addon-cancellation {
    .left-sec {
      max-width: 50rem;

    }
  }
}

@media screen and (min-width:1300px) and (max-width:1600px) {
  .overview-grid .bz-payment-options {
    max-width: 28rem !important;
    display: grid;
    grid-template-columns: 6rem 4.5rem 9rem auto;
    grid-row-gap: 0.5rem;
    grid-column-gap: 1rem;
    padding: 0.75rem;

    >.subscription-name {
      grid-area: 1/1/1/4;
    }

    >.btn-link {
      grid-row: 1 / 3;
      grid-column: 4/5;
    }
  }
}

@media screen and (min-width:1080px) and (max-width:1580px) {
  .service-titlename2 {
    max-width: 200px;
  }
}

@media screen and (min-width:651px) and (max-width:1550px) {

  .payment-card-input .credit-card-input-form .bz-custom-card-input,
  .bz-plan-puchase-page .payment-card-input .credit-card-input-form .bz-custom-card-input {
    min-width: initial;
  }

  .payment-card-input .credit-card-input-form .bz-custom-card-input>.card-number-sec {
    flex-basis: calc(100% - 12.8rem);
    margin-right: 0.75rem;
    width: calc(100% - 12.8rem);

    >label {
      width: 165px;
    }
  }

  .payment-card-input .credit-card-input-form .bz-custom-card-input>.zipcode-sec {
    margin-left: 0.75rem;
  }
}

@media screen and (max-width:1499px) {
  .bz-overview-page-row.bz-no-right-section {
    >.left-section {
      flex-basis: 100%;
    }
  }

  .overview-grid .bz-payment-options {
    display: grid;

    >.badge {
      margin: 0;
    }

    >.bz-card-details {
      >img {
        margin-right: 0.2rem;
      }
    }
  }

  .Booking-Main .second-panel.bz-availableTimesCard>.calendar-main>.availableTimes-Item .render-time-duration,
  .Booking-Main .two-panel>.calendar-main>.availableTimes-Item .render-time-duration {
    grid-template-columns: repeat(2, 1fr);
  }

  .Booking-Main .three-panel {
    width: 16rem;
  }

  .k-calendar .k-calendar-view table,
  .k-calendar .k-calendar-view .k-calendar-table {
    border-spacing: 10px;
  }

  .bz-personalInfo-grid {
    max-width: 72rem;

    colgroup {
      >col:nth-child(1) {
        width: 9rem;
      }

      >col:nth-child(2) {
        width: 17rem;
      }

      >col:nth-child(3) {
        width: 12.5rem;
      }

      >col:nth-child(4) {
        width: 9.5rem;
      }

      >col:nth-child(5) {
        width: 9rem;
      }
    }
  }
}

@media only screen and (max-width: 1460px) {}

@media only screen and (max-width: 1400px) {
  .overview-grid .bz-payment-options {
    .btn-link {
      max-width: 3.5rem;
    }

    .mble-subscription-badges {
      >.badge {
        margin: 0;
      }
    }
  }

  .bz-group-services-list.bz-past-GroupServices {
    .bzc-sessions-list-card .bzc-sessions-list-row {
      align-items: flex-start;

      .bzc-col-1.group-name {
        h5 {
          line-height: 1.25;
          margin-top: 0.35rem;
          max-width: 80%;
        }
      }
    }
  }

  .bz-group-services-list.bz-past-GroupServices.statuses-alignment {
    .bzc-sessions-list-card {
      .bzc-sessions-list-row>.group-name {
        h5 {
          margin-top: 0;
        }
      }
    }
  }
}

@media screen and (min-width:1393px) and (max-width:1460px) {
  .bz-edit-appoinment {
    .payment-required-radio-group {
      >.k-radio-list {
        >.k-radio-item {
          align-items: flex-start;
          max-width: 15rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 1380px) {
  .bz-subsection-row.bz-overview-page-row {
    >.right-section.bz-inactive-error-card {
      flex: 0 0 20rem !important;
    }
  }

  .bz-cancellation-page .package .bz-cancellation-card-row {
    max-width: 55rem;
  }

  .bz-cancellation-page .bz-cancellation-card-row.charge-dropdown,
  .bz-cancellation-page .bz-cancellation-card-row.payment-profile {
    max-width: initial;
  }
}

@media only screen and (min-width: 1366px) {

  .bz-addon-cancellation {
    .left-sec {

      .k-dropdown,
      textarea {
        max-width: 100%;
      }
    }
  }

  .Booking-Main.prospect-user-main .second-panel.bz-availableTimesCard {
    width: 55rem;
  }

  .Booking-Main.prospect-user-main .second-panel.client-page.bz-thirdPanelNotActive {
    width: 25rem;
  }

  .Booking-Main.prospect-user-main .second-panel.bz-availableTimesCard>.calendar-main .render-time-duration.prospect-user {
    grid-template-columns: repeat(2, 1fr);
  }


}

@media screen and (min-width:1365px) and (max-width:1378px) {
  .enroll-client-col .enroll-attendees-blk {

    .btn-link {
      padding-left: 0.375rem;

      >h6 {
        padding-left: 0;
      }
    }
  }
}

@media screen and (min-width:849px) and (max-width:1450px) {
  .bzc-purchase-class-list-row.bz-class-selected {
    >.bz-groupservices-left-side-card {
      .bz-purchase-class-list {
        .k-listview-content {
          .bz-group-events-new {
            >.card-body {
              padding: 10px !important;
            }

            .bzc-sessions-list-row-new {
              grid-template-columns: 1fr 1fr 7.5rem;
              justify-items: flex-start;
              align-items: flex-start;

              >.section-2 {
                display: none;
              }

              >.section-3 {
                grid-area: 1/2/2/2;
                margin-left: 0.5rem;

                .bz-main-provider>.bz-provider-img img {
                  width: 50px;
                  height: 50px;
                }
              }

              >.section-4 {
                h6 {
                  padding-left: 0;
                }

                margin-top: 0.5rem;
              }

              >.section-5 {
                margin-left: 0.5rem;
                margin-top: 0.5rem;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width:1300px) and (max-width:1500px) {
  .bz-group-services-list.bz-past-GroupServices {
    .bzc-sessions-list-card .bzc-sessions-list-row {
      grid-template-columns: 11.5rem repeat(2, 1fr) 10rem 6rem 8rem;
    }
  }

  .bz-group-services-list.bz-past-GroupServices.statuses-alignment {
    .bzc-sessions-list-card .bzc-sessions-list-row {
      grid-template-columns: 11.5rem 12rem 10rem 10rem 8rem;
      align-items: flex-start;
      justify-content: space-between;
    }
  }
}

@media screen and (min-width:1200px) and (max-width:1450px) {
  .bzc-purchase-class-list-row.bz-class-selected {
    >.bz-groupservices-left-side-card {
      .bz-purchase-class-list {
        .k-listview-content {
          .bz-group-events-new {

            .bzc-sessions-list-row-new {
              >.section-6 {
                grid-area: 1/3/3/4;
                height: 100%;
                align-items: self-end;
                display: flex;
              }
            }
          }
        }
      }
    }
  }

  .bzc-sessions-list-card-new.bz-noPaid-group {
    .card-body {
      padding: 25px 25px 15px 25px;

      >.bzc-sessions-list-row-new {
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 15px;
      }
    }
  }

  .section-5.rate.redeem {
    width: 100%;
  }

  .bzc-sessions-list-card-new .bzc-sessions-list-row-new .bzc-session-price {
    align-items: start;
  }

  .bzc-purchase-class-list-view .bz-purchase-class-list .k-listview .k-listview-content .bz-group-events-new .bzc-sessions-list-row-new>.section-6 {
    grid-area: 1/3/3/4;
  }
}

@media screen and (min-width:1300px) and (max-width:1510px) {
  .bzc-purchase-class-list-row.bz-class-selected {
    >.bz-groupservices-left-side-card {
      .bz-purchase-class-list {
        .k-listview-content {
          .bz-group-events-new {

            .bzc-sessions-list-row-new {
              grid-template-columns: 1fr 1fr;

              >.section-3 {
                grid-area: 1/2/2/4;
                margin-left: 0.5rem;

              }

              .section-5.rate {
                margin-left: 0;
                width: 100%;

                >.bzc-session-price {
                  width: 100%;
                  justify-content: flex-end;
                }
              }

              >.section-6 {
                grid-area: 2/4/3/5;
                height: 100%;
                align-items: center;
                display: flex;
                margin-top: 5px;

                .bz-service-select-btn-new {
                  margin-right: 0;
                }
              }
            }
          }

          .bz-noPaid-group {
            .bzc-sessions-list-row-new {

              >.section-2 {
                display: none;
              }

            }
          }
        }
      }
    }
  }

  .groupappadmin .bzc-purchase-class-list-row.bz-class-selected>.bz-groupservices-left-side-card .bz-purchase-class-list .k-listview-content .bz-group-events-new .bzc-sessions-list-row-new {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width:1470px) {
  .bz-service-card.pkg-card-padding.bz-plans-card {
    p.card-subtitle.flex-txt {
      padding-right: 0rem;
    }
  }
}

@media screen and (min-width:1300px) and (max-width:1380px) {
  .bz-group-services-list.bz-past-GroupServices {
    .bzc-sessions-list-card .bzc-sessions-list-row {
      grid-template-columns: 11rem repeat(2, 11rem) 8rem 3rem 8rem;
    }
  }

  .bz-group-services-list.bz-past-GroupServices.statuses-alignment {
    .bzc-sessions-list-card {
      .bzc-sessions-list-row {
        align-items: flex-start;
        grid-template-columns: 11rem repeat(2, 11rem) 8rem 8rem;
        justify-content: space-between;
      }
    }
  }
}

@media screen and (min-width:1201px) and (max-width:1336px) {
  .bz-pakage-overview.bz-subsection-row {
    >.right-section {
      flex: 0 0 20rem;
    }
  }

  .bzc-pkg-overview-grid {
    max-width: 35rem;
  }
}

@media screen and (min-width:1200px) and (max-width:1344px) {
  // .flex-txt {
  //   grid-template-columns: 6rem auto 1fr;
  // }

  .bz-service-card.bz-plans-card p.card-subtitle.flex-txt {
    padding-right: 2rem;
  }

  .bz-schedule-link-btn {
    bottom: 0;
  }

}

@media screen and (min-width:1201px) and (max-width:1300px) {
  .bz-group-services-list.bz-past-GroupServices {
    .bzc-sessions-list-card .bzc-sessions-list-row {
      grid-template-columns: 10rem 9.5rem 10rem 6.5rem 7rem;
      justify-content: space-between;

      >.member-name,
      .group-name {
        padding-right: 0.5rem;
      }
    }

  }
}

@media screen and (min-width:1150px) and (max-width:1200px) {
  .payment-required-radio-group {
    >.k-radio-list {
      >.k-radio-item {
        align-items: flex-start;
        max-width: 15rem;
      }
    }
  }
}

@media screen and (min-width:768px) and (max-width:1200px) {
  .bzc-purchase-class-list-view {
    .bz-purchase-class-list {
      .k-listview {
        .k-listview-content {
          .bz-group-events-new {
            >.card-body {
              padding: 1rem !important;
            }

            .bzc-sessions-list-row-new {
              grid-template-columns: 1fr 1fr 7.5rem;
              justify-items: flex-start;
              align-items: flex-start;

              >.section-2 {
                display: none;
              }

              >.section-3 {
                grid-area: 1/2/2/2;
                margin-left: 0.5rem;

                .bz-main-provider>.bz-provider-img img {
                  width: 50px;
                  height: 50px;
                }
              }

              >.section-4 {
                h6 {
                  padding-left: 0;
                }

                margin-top: 0.5rem;
              }

              >.section-5 {
                width: 96%;
                margin-left: 0.5rem;
                margin-top: 0.5rem;

                .bzc-session-price {
                  padding: 20px;
                  justify-content: space-between;
                }
              }

              >.section-6 {
                grid-area: 1/3/3/4;
                height: 100%;
                align-items: center;
                display: flex;

              }
            }
          }
        }
      }
    }
  }

  .bzc-purchase-class-list-view .bz-purchase-class-list.groupappadmin .k-listview .k-listview-content .bz-group-events-new .bzc-sessions-list-row-new>.section-6 {
    flex-direction: column;
  }

  .bzc-purchase-class-list-view .bz-purchase-class-list.groupappadmin .k-listview .k-listview-content .bz-group-events-new .bzc-sessions-list-row-new {
    grid-template-columns: 1fr 1fr 1fr;


  }
}

@media (max-width: 767px) {
  .bzc-purchase-class-list-view .bz-purchase-class-list.groupappadmin .k-listview .k-listview-content .bz-group-events-new .bzc-sessions-list-row-new>.section-6 {


    flex-direction: column;
  }
}

@media screen and (min-width:768px) and (max-width:1328px) {
  .enroll-client-col .enroll-attendees-blk {
    flex-direction: column;
    align-items: flex-start;

    .btn-link {
      padding-left: 0.375rem;

      >h6 {
        padding-left: 0;
      }
    }

    .bz-vertical-line {
      display: none;
    }

    .bz-warning-text {
      padding-left: 0.375rem;
    }
  }
}

@media only screen and (max-width: 1366px) {
  .bz-subsection-row.bz-overview-page-row {
    >.right-section.bz-inactive-error-card {
      >.bz-overview-cards {
        max-height: 18.3rem;
      }
    }
  }

  .custom-grid-box {
    .bz-purchase-checkout-list {
      .bzc-sessions-list-card-new.bz-classes-checkout {
        .bzc-sessions-list-row-new {
          grid-column-gap: 0.5rem;

          .bz-main-provider {
            >.bz-provider-img {
              img {
                width: 50px !important;
                height: 50px !important;
              }
            }

            >.bz-attendees-icon {
              img {
                width: 35px !important;
                height: 35px !important;
              }
            }
          }

        }
      }
    }
  }

  .bz-add-pkg-dialog.bz-book-Appoinment-dialog.k-dialog-wrapper {
    .k-dialog {
      width: 31.25rem !important;

      .k-dialog-content {
        max-height: 28rem;
        margin-bottom: 1rem;
      }
    }

  }

  .bz-coupon-pkg {
    >.bz-subscription-card {
      .bz-payment-options {
        display: block;

        >.subscription-name {
          width: calc(100% - 5rem);
          padding-right: 1rem;
        }

        >.badge+.d-flex {
          display: inline-flex !important;
          padding-left: 0.5rem;
        }
      }
    }

  }

  .bz-credit-activity {
    .credit-activity-list {
      grid-template-columns: 10rem calc(100% - 20rem) 1fr;

      >.section-3 {
        padding-right: 0;
      }
    }
  }
}

.bzc-grid.bz-invoiceGrid.submenubillings table {
  width: 100% !important;
}

@media screen and (max-width:1365px) {

  .bzc-grid.bz-invoiceGrid.submenubillings table {
    width: 100% !important;
  }

  .bz-checkins-grid {
    colgroup {
      col:nth-child(1) {
        width: 200px;
      }

      col:nth-child(2) {
        width: 200px;
      }

      col:nth-child(3) {
        width: 200px;
      }

      col:nth-child(4) {
        width: 200px;
      }

      col:nth-child(5) {
        width: 200px;
      }

      col:nth-child(6) {
        width: 200px;
      }
    }
  }

  .bzc-grid.bz-invoiceGrid {

    colgroup {
      col:nth-child(1) {
        width: 140px;
      }

      col:nth-child(2) {
        width: 145px;
      }

      col:nth-child(3) {
        width: 140px;
      }

      col:nth-child(4) {
        width: 170px;
      }

      col:nth-child(5) {
        width: 122px;
      }

      col:nth-child(6) {
        width: 110px;
      }

      col:nth-child(7) {
        width: 240px;
      }
    }
  }

  .bzc-grid.bz-invoiceGrid.billings {
    table {
      width: 100% !important;
    }

    colgroup {
      col:nth-child(1) {
        width: 50px;
      }

      col:nth-child(2) {
        width: 180px;
      }

      col:nth-child(3) {
        width: 150px;
      }

      col:nth-child(4) {
        width: 170px;
      }

      col:nth-child(5) {
        width: 180px;
      }

      col:nth-child(6) {
        width: 150px;
      }

      col:nth-child(7) {
        width: 150px;
      }

      col:nth-child(8) {
        width: 150px;
      }

    }
  }

  .bzc-grid.bz-invoiceGrid.billings.can-make-payment {
    colgroup {

      col:nth-child(8) {
        width: auto;
        min-width: 200px;
      }
    }
  }

  .bzc-grid.bz-invoiceGrid.billings {
    col:nth-child(1) {
      width: 50px;
    }
  }

  .bzc-grid.bz-invoiceGrid.billings .submenubillings {
    col:nth-child(1) {
      width: 180px;
    }

    col:nth-child(2) {
      width: 150px;
    }

    col:nth-child(3) {
      width: 170px;
    }

    col:nth-child(4) {
      width: 180px;
    }

    col:nth-child(5) {
      width: 150px;
    }

    col:nth-child(6) {
      width: 150px;
    }

    col:nth-child(7) {
      width: auto;

    }
  }



  .bz-group-services-list .k-listview {
    max-height: 74vh;
  }
}

@media all and (min-width:1250px) and (max-width:1366px) {
  .custom-grid-box {
    .bz-purchase-checkout-list {
      .bzc-sessions-list-card-new.bz-classes-checkout {
        .bzc-sessions-list-row-new {
          grid-template-columns: 11rem 1fr 0.8fr 10rem !important;
          grid-column-gap: 0.5rem;
        }
      }
    }
  }

  .groupappadmin {
    .custom-grid-box {
      .bz-purchase-checkout-list {
        .bzc-sessions-list-card-new.bz-classes-checkout {
          .bzc-sessions-list-row-new {
            grid-template-columns: 1fr 1fr !important;
          }
        }
      }
    }
  }

}

@media screen and (min-width:1301px) {
  .edit-package-form-new-row-1 .upload-file-section {
    grid-area: 2/1/2/3
  }
}

@media only screen and (max-width: 1300px) {
  .overview-grid .bz-payment-options {
    grid-template-columns: auto auto auto;
    grid-column-gap: 1.5rem;
    padding: 0.75rem 4rem 0.75rem 0.75rem;

    >.subscription-name {
      grid-area: 1/1/1/4;
    }

    >.btn-link {
      grid-row: 1 / 3;
      grid-column: 4/5;
      position: absolute;
      right: 4rem;
    }

    .btn-link {
      max-width: 3.5rem;
    }

    .mble-subscription-badges {
      >.badge {
        margin: 0;
      }
    }
  }

  .edit-package-form-new-row-1 {
    grid-template-columns: 1fr 1fr;
  }

  .bz-migration-list-table {
    colgroup {
      >col {

        &:nth-child(2) {
          width: 12rem;
        }

        &:nth-child(3) {
          width: 11.5rem;
        }

        &:nth-child(4) {
          width: 11.5rem;
        }

        &:nth-child(5) {
          width: 7rem;
        }
      }
    }

    .bzc-grid {
      .k-grid-content {
        td {
          word-break: break-all;
        }
      }
    }
  }

}

@media screen and (min-width:1200px) and (max-width:1365px) {
  .bz-online-main-row>.bz-content-area .bz-appoinments-info {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width:1281px) and (max-width:1310px) {
  .Booking-Main .second-panel.bz-availableTimesCard>.calendar-main>.calendar-item {
    max-width: 22rem;
  }

  .Booking-Main .second-panel.bz-availableTimesCard>.calendar-main>.availableTimes-Item,
  .Booking-Main .two-panel,
  .Booking-Main .three-panel {
    margin-left: 1.5rem;
  }
}

@media only screen and (max-width: 1280px) {

  .Booking-Main .second-panel.bz-availableTimesCard>.calendar-main>.availableTimes-Item .render-time-duration,
  .Booking-Main .two-panel>.calendar-main>.availableTimes-Item .render-time-duration {
    grid-template-columns: 1fr;
    padding-right: 0.5rem;
  }

  .Booking-Main .second-panel.bz-availableTimesCard>.calendar-main.bz-guest-calendar-main>.availableTimes-Item .render-time-duration {
    grid-template-columns: repeat(2, 1fr);

  }

  .Booking-Main .three-panel {
    width: 15rem;
  }

  .Booking-Main .two-panel .calendar-main .availableTimes-Item .render-time-duration {
    max-height: 340px;
  }

  .second-panel.bz-availableTimesCard.bz-thirdPanelNotActive {
    width: 70%;
    margin-left: 2.5rem;

    >.calendar-main {
      align-items: flex-start;
    }
  }

  .Booking-Main.prospect-user-main .second-panel.bz-availableTimesCard {
    width: 75%;
  }

  .Booking-Main .first-panel {
    width: 20rem;
  }

  .Booking-Main .second-panel.bz-availableTimesCard.bz-thirdPanelNotActive>.calendar-main>.availableTimes-Item .render-time-duration {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 1250px) {
  .bz-overview-page-row.bz-appoinments-info-sec {
    flex-direction: column;

    >.left-section {
      flex-basis: 100%;
      order: 2;
      margin-right: 0;
    }

    >.right-section.bz-inactive-error-card {
      order: 1;
      margin-top: 1rem;
    }
  }

  .bz-content-text-row .bz-content-body.cards-items-grid {
    width: auto;
  }

  .bz-subsection-row.bz-overview-page-row {
    >.right-section.bz-inactive-error-card {
      >.bz-overview-cards {
        max-height: initial;
      }
    }
  }

  .Booking-Main.prospect-user-main {
    .second-panel.bz-availableTimesCard {
      width: 60%;

      >.calendar-main {
        >.availableTimes-Item .render-time-duration {
          grid-template-columns: 1fr;
        }
      }
    }
  }

  .edit-package-form-new.add-pkg-form-new {
    >.pkg-col-1 {
      .section-SessionType {
        justify-content: flex-start;

        .session-type-field {
          flex: 0 0 100%;
        }

        .expiry-after-field,
        .section-schedules {
          flex: 0 0 calc(50% - 0.75rem);
        }

        .section-schedules {
          margin-right: 1.5rem;
        }
      }
    }
  }

  .add-pkg-form-new.UnLimited-blk {
    >.pkg-col-2 {
      .section-Description {
        .k-textarea>.k-input {
          min-height: 233px;
        }
      }
    }
  }
}

@media all and (min-width:651px) and (max-width:1249px) {
  .custom-grid-box {
    .bz-purchase-checkout-list {
      .bzc-sessions-list-card-new.bz-classes-checkout {
        .bzc-sessions-list-row-new {
          // grid-template-columns: 11rem auto auto auto !important;
          // grid-column-gap: 0.75rem;


          .section-2 {
            width: 100% !important;
            grid-area: 1/1/1/5;
            padding-left: 0 !important;
            border-left: 0 !important;
            margin-bottom: 0.5rem;
          }

        }
      }
    }
  }

  .bzc-sessions-list-card-new .bzc-sessions-list-row-new .section-1 {
    margin-bottom: 10px;
    margin-right: 5px;
    padding-right: 5px;
  }
}

@media screen and (min-width:1100px) and (max-width:1299px) {
  .bzc-purchase-class-list-row.bz-class-selected {
    >.bz-groupservices-left-side-card {
      .bz-purchase-class-list {
        .k-listview-content {
          .bz-group-events-new {

            .bzc-sessions-list-row-new {
              grid-template-columns: 1fr 1fr;

              >.section-3 {
                grid-area: 1/2/2/2;
              }

              >.section-5.rate {
                .bzc-session-price {
                  >.status-container {
                    flex-basis: initial;
                  }
                }
              }

              >.section-6 {
                grid-area: 3/1/3/3;
                height: 100%;
                align-items: center;
                display: flex;
                margin-top: 0.5rem;

                >.k-button {
                  margin-left: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  .add-Session-Form,
  .addSessionForm-grid2 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width:992px) and (max-width:1190px) {
  .bz-pkg-form {
    >.row {
      >.col-sm-6.col-lg-3 {
        flex: 0 0 auto;
        width: 33%;
      }

      >.section-group-services {
        flex: 0 0 auto;
        width: 66%;
      }
    }
  }

  .add-Session-Form,
  .addSessionForm-grid2 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width:991px) and (max-width:1200px) {
  .bzc-purchase-class-list-view .bzc-purchase-class-list-row.bz-class-selected {
    flex-direction: column;
  }

  .bzc-purchase-class-list-view .bzc-purchase-class-list-row>.bzc-left-side-card {
    order: 2;
    width: 100% !important;
  }

  .bz-classes-in-online .bzc-purchase-class-list-view .bzc-purchase-class-list-row>.bzc-right-side-card {
    margin: 1.5rem 0;
    flex: 0 0 auto;
  }

  .bzc-purchase-class-list-view .bzc-purchase-class-list-row>.bzc-right-side-card {
    height: auto;
    z-index: 999999;
  }
}

@media screen and (min-width:1149px) {
  .Booking-Main.prospect-user-main .second-panel.bz-availableTimesCard {
    width: 43rem;
  }

  .Booking-Main.prospect-user-main .second-panel.bz-availableTimesCard.client-page {
    width: 28rem;
  }

  .Booking-Main.prospect-user-main .second-panel.bz-availableTimesCard>.calendar-main {
    padding-left: 0;
  }

  .bz-cancellation-page-new {
    .k-listview {
      .bz-cancellation-card-row.bz-charge-amount {
        grid-template-columns: 13.125rem 20rem 21rem;
        justify-content: space-between;
      }
    }
  }

  .bz-cancellation-page {
    .package {
      .bz-cancellation-card-row.zero-dollar-payment {
        max-width: initial;
        grid-template-columns: 13rem 13rem 13rem;
        justify-content: space-between;
      }

      .bz-cancellation-card-row.zero-dollar-payment.charge-dropdown {
        grid-template-columns: 13rem 13rem 13rem 10.5rem !important;
      }
    }
  }
}

@media screen and (max-width:1150px) {

  .Booking-Main .two-panel .calendar-main,
  .Booking-Main .second-panel .calendar-main {
    align-items: flex-start;
    flex-direction: column;
  }

  .Booking-Main .second-panel.bz-availableTimesCard,
  .Booking-Main .two-panel {
    width: 37%;
    max-height: 80vh;
    overflow: auto;
    padding-right: 0.5rem;
  }

  .Booking-Main .second-panel.bz-availableTimesCard>.calendar-main>.calendar-item,
  .Booking-Main .two-panel>.calendar-main>.calendar-item {
    max-width: initial;
    width: auto;
  }

  .Booking-Main .second-panel.bz-availableTimesCard>.calendar-main.bz-guest-calendar-main>.calendar-item,
  .Booking-Main .two-panel>.calendar-main.bz-guest-calendar-main>.calendar-item {
    max-width: 23rem;
    width: 100%;
  }

  .Booking-Main .one-panel,
  .Booking-Main .first-panel {
    width: 32%;
  }

  .Booking-Main .second-panel.bz-availableTimesCard>.calendar-main>.availableTimes-Item,
  .Booking-Main .two-panel>.calendar-main>.availableTimes-Item,
  .Booking-Main .two-panel.bz-thirdPanelActive>.recurrence-selection {
    width: 96%;
    margin: auto;
    margin-top: 1.5rem;
  }

  .Booking-Main .second-panel.bz-availableTimesCard>.calendar-main>.availableTimes-Item .render-time-duration,
  .Booking-Main .two-panel>.calendar-main>.availableTimes-Item .render-time-duration {
    grid-template-columns: 1fr 1fr;
  }

  .Booking-Main .recurrence-selection {
    max-width: 21rem;
  }

  .success-component .success-info {
    width: 65%;
  }

  .Booking-Main .second-panel.guestCalender.bz-availableTimesCard {
    width: 50% !important;
    padding-right: 0;
  }

  .Booking-Main.prospect-user-main {
    .second-panel.bz-availableTimesCard {
      width: auto;
      margin-left: 0.75rem;

      >.calendar-main {
        flex-direction: row;

        >.availableTimes-Item {
          margin-top: 0;
        }
      }
    }
  }

  .edit-package-form-new {
    >.pkg-col-1 {
      .section-SessionType {
        justify-content: flex-start;

        >.k-form-field {
          flex: 0 0 100%;
        }

        >.section-schedules,
        .section-Expiry {
          flex: 0 0 calc(50% - 0.75rem);
        }

        >.section-schedules {
          margin-right: 1.5rem;
        }
      }

      .section-SessionType.UnLimited {
        .section-Expiry {
          flex: 0 0 100%;
        }
      }
    }

    >.pkg-col-2 {
      .section-Description {
        .k-textarea>.k-input {
          min-height: 289px;
        }
      }
    }
  }

}

@media screen and (min-width:1025px) and (max-width:1379px) {
  .bz-cancellation-page .package .bz-cancellation-card-row {
    max-width: 40rem;
  }

  .bz-cancellation-page .bz-cancellation-card-row.charge-dropdown,
  .bz-cancellation-page .bz-cancellation-card-row.payment-profile {
    max-width: initial;
  }
}

@media screen and (min-width:821px) and (max-width:1365px) {
  .bz-cancellation-page .bz-cancellation-card-row.payment-profile {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}

@media screen and (min-width:991px) and (max-width:1099px) {
  .bzc-purchase-class-list-row.bz-class-selected {
    >.bz-groupservices-left-side-card {
      .bz-purchase-class-list {
        .k-listview-content {
          .bz-group-events-new {

            .bzc-sessions-list-row-new {
              grid-template-columns: 1fr 1fr;

              >.section-3 {
                grid-area: 1/1/1/3;
                margin-left: 0;
                margin-bottom: 0.5rem;
                border-left: 0;
                padding-left: 0;
              }

              >.section-5 {
                margin-left: 0;
              }

              >.section-6 {
                grid-area: unset;
                height: 100%;
                align-items: center;
                display: flex;
                margin-top: 0.5rem;

                >.k-button {
                  margin-left: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  .bzc-purchase-class-list-view {
    .bz-purchase-class-list {
      .k-listview {
        .k-listview-content {
          .bz-group-events-new {
            >.card-body {
              padding: 1rem !important;
            }

            .bzc-sessions-list-row-new {
              grid-template-columns: 1fr 1fr 7.5rem;
              justify-items: flex-start;
              align-items: flex-start;

              >.section-2 {
                display: none;
              }

              >.section-3 {
                grid-area: 1/2/2/2;
                margin-left: 0.5rem;

                .bz-main-provider>.bz-provider-img img {
                  width: 50px;
                  height: 50px;
                }
              }

              >.section-4 {
                h6 {
                  padding-left: 0;
                }

                margin-top: 0.5rem;
              }

              >.section-5 {
                margin-left: 0.5rem;
                margin-top: 5px
              }

              >.section-6 {
                grid-area: 1/3/3/4;
                height: 100%;
                align-items: center;
                display: flex;

              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width:1024px) {
  .Booking-Main.prospect-user-main .first-panel {
    max-width: 316px;
  }

  //signature dialog css
  .bz-signature-dialog {
    >.k-dialog {
      width: 70%;
      height: 70%;

      .k-dialog-content {
        width: 100% !important;
        height: 100% !important;
        max-height: initial !important;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

        .bz-signaturepad-container {
          height: 47vh;

          canvas {
            height: 100% !important;
          }
        }

        .k-tabstrip {
          .k-content {
            padding-bottom: 0;
            padding-top: 0.5rem;
          }
        }
      }
    }
  }

}

@media screen and (max-width:1024px) {

  .cards-items-grid {
    grid-column-gap: 0.5rem;
  }

  .Booking-Main.prospect-user-main {
    .second-panel.bz-availableTimesCard {
      width: 37%;

      >.calendar-main {
        flex-direction: column;

        >.availableTimes-Item {
          width: 100% !important;
          margin-left: 0 !important;
          margin-top: 0 !important;
        }
      }
    }
  }

  .bz-pipedrive-dialog {
    >.k-dialog {
      width: calc(100% - 2rem) !important;
    }
  }
}

@media screen and (max-width:991px) {
  .bz-plans-card.bz-arrow-right.bz-complementary {
    min-height: 60px;

    h5 {
      line-height: 1.625;
    }

  }

  .bz-plans-card.bz-arrow-right.bz-complementary .bz-arrow-icon {
    top: 0.6rem !important;
  }

  .Booking-Main .three-panel {
    width: calc(40% + 23rem + 2.5rem);
    margin-top: 1.5rem;
    margin-left: 0;

    .selected-slot-header {
      padding-right: 0;
      align-items: center;
    }
  }

  .Booking-Main .second-panel.bz-availableTimesCard,
  .Booking-Main .two-panel {
    width: 23rem !important;
  }

  .Booking-Main .one-panel,
  .Booking-Main .first-panel {
    width: 40%;
  }

  .Booking-Main .second-panel.hide-available-times {
    width: 24.5rem !important;
  }

  .success-component .success-info {
    width: 75%;
  }

  .chargeback-card {
    .main-grid .main-grid-item {
      grid-template-columns: 1fr 1fr 2rem;
    }
  }

  .signature-terms {
    display: flex;
    justify-content: space-between;
  }

  .bzc-grid.bz-selectSession {
    max-height: calc(100vh - 13rem);
  }

  .add-Session-Form,
  .addSessionForm-grid2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width:768px) and (max-width:991px) {
  .bzc-purchase-class-list-row.bz-class-selected {
    >.bz-groupservices-left-side-card {
      .bz-purchase-class-list {
        .k-listview-content {
          .bz-group-events-new {

            .bzc-sessions-list-row-new {
              display: grid;
              padding: 10px;

              div {
                margin-bottom: 5px;
              }

              >.section-3 {
                grid-area: 1/2/2/2;
              }

              >.section-6 {
                grid-area: 1/3/3/6;
                height: 100%;
                align-items: flex-end;
                display: flex;

              }
            }
          }
        }
      }
    }
  }

  .bz-classList-container.k-card>.k-card-body .k-listview .bz-groupServices-list-new .section-5 {
    order: 5;
    right: 1.25rem;
    top: 10px;
    position: absolute;
    width: auto;
  }
}

@media screen and (min-width:801px) and (max-width:1000px) {
  .groupclass-session-list {
    grid-template-columns: 16rem 0.6fr 0.6fr 0.3fr 0.3fr;
  }

  .groupclass-session-list.bz-manage-attendees {
    grid-template-columns: 16rem 0.6fr 0.6fr 0.3fr 1.5rem;
  }

}

@media all and (min-width:801px) {
  .groupclass-session-list.bz-group-card.bz-group-waitlist {
    >.section-3 {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
}

@media all and (max-width:800px) {
  .bz-recurrence-text-item {
    .k-panelbar-item-text {
      max-width: 22rem;
    }
  }

  .groupclass-session-list {
    grid-template-columns: 1fr auto 1fr;
    gap: 10px;

    .section-1 {
      grid-area: 1/1/2/5;
    }

    .section-2 {
      grid-area: 1/2/2/5;
    }

    .bz-main-provider .bz-provider-img img {
      width: 72px !important;
      height: 72px !important;
    }
  }

  .bz-subscribe-addon {
    .bz-colon {
      display: inline;
    }

    >.col-md-6 {
      display: flex;
      flex-direction: row;
      align-self: flex-start;

      >label {
        min-width: 10rem;
      }

      >.bz-colon {
        margin: 0 0.5rem;
      }

      >p {
        width: 100%;
        flex-wrap: wrap;
      }
    }
  }
}

@media screen and (min-width:601px) and (max-width:1150px) {
  .bz-notification-container {
    >.left-section {
      width: 100%;
      flex-basis: 100%;

      .bz-client-dropdown.bz-content-text-row {
        display: flex;

        >.bz-colon {
          padding: 0 1rem;
        }

        .k-dropdown {
          width: 15rem !important;
        }
      }
    }
  }
}

@media screen and (min-width:768px) {
  .plan-details.bz-plan-puchase-page {
    >.row {
      >.col-md-4 {
        >.bz-orderSummary-card {
          position: sticky;
          top: 4.75rem;
        }
      }
    }
  }

  .bz-addNewMemberBtn {
    position: sticky;
    top: 1.6rem;
  }

  .bz-planPurchase>.bz-colon {
    margin-right: 0.23rem;
  }
}

@media screen and (max-width:767px) {
  .bzo-leftside-filter-sec .bzo-leftside-filter-container {
    margin-bottom: 0.75rem;
  }

  .edit-package-form-new-row-1 {
    grid-template-columns: 1fr 1fr;
  }

  .bz-subsection-row {
    flex-wrap: initial;
  }

  .purchasepackebtn {
    gap: 10px;
  }
}

@media screen and (max-width:796px) {
  .Booking-Main .second-panel.guestCalender.bz-availableTimesCard {
    width: 100% !important;
  }

  .bz-groupServices-sessions-list-new {
    padding: 1.25rem !important;
    gap: 0;
    position: relative;

    >div {
      padding-left: 0;
    }

    .section-1 {
      order: 2;
      padding-bottom: 0.75rem;

      .bz-calender-icon {
        display: none;
      }

      .bz-availability-dates {
        padding-left: 0;
      }
    }

    .section-2 {
      order: 1;
      padding-right: 9.5rem;
      padding-bottom: 0.75rem;

      >.bz-main-provider {
        padding: 0;

        .bz-provider-img img {
          width: 3rem !important;
          height: 3rem !important;
        }
      }
    }

    .section-3 {
      order: 3;

      .bz-calender-icon {
        display: none;
      }

      .btn-link {
        padding-left: 0 !important;
      }
    }

    .section-4 {
      >.bz-price-section {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;

        h6 {
          margin-bottom: 0;
          margin-left: 0.5rem;
        }
      }
    }

    .section-4,
    .section-5 {
      position: absolute;
      width: auto;
      padding: 0;
    }

    .section-4 {
      order: 4;
      top: 1.25rem;
      right: 3.5rem;
    }

    .section-5 {
      order: 5;
      right: 0.5rem;
      top: 1.1rem;
    }
  }

  .enroll-client-col .enroll-attendees-blk .bz-warning-text {
    padding-left: 0 !important;
  }

  .groupclass-session-list.bz-group-card.bz-group-waitlist {
    .bz-attendees-blk {
      flex-direction: row;
      margin-bottom: 0.5rem;

      .bz-badge-warning.mt-md-3 {
        margin-top: 0 !important;
        margin-left: 0.5rem !important;
      }
    }
  }
}

@media screen and (max-width:750px) {

  .Booking-Main .one-panel,
  .Booking-Main .first-panel {
    width: 23rem;
  }

  .Booking-Main .second-panel.bz-availableTimesCard,
  .Booking-Main .two-panel {
    margin-left: 0;
    max-height: initial;
  }

  .availableTimes-Item {
    .column-section-heading {
      text-align: center;
    }
  }

  .Booking-Main .three-panel {
    width: 23rem;
    margin-top: 0;
  }

  .Booking-Main .three-panel .selected-slot-header {
    padding-right: 0.75rem;
  }

  .Booking-Main .second-panel.bz-availableTimesCard>.calendar-main>.availableTimes-Item,
  .Booking-Main .two-panel>.calendar-main>.availableTimes-Item,
  .Booking-Main .two-panel.bz-thirdPanelActive>.recurrence-selection {
    width: 100%;
    margin-bottom: 1rem
  }

  .recurrence-selection:empty {
    margin-top: 0;
  }

  .Booking-Main .second-panel.hide-available-times {
    margin-left: 0;
  }

  .success-component .success-info {
    width: 90%;

    .card-content {
      padding: 1.5rem;
    }
  }

  .Booking-Main .recurrence-selection .bz-recurence-btn {
    margin-bottom: 0.2rem;
  }

  .Booking-Main .second-panel.guestCalender.bz-availableTimesCard {
    width: 100% !important;
  }

  .update-session-page-new .bzc-row {
    width: 100%;
  }

  .edit-btn-container.staff {
    justify-content: flex-start;
    margin-left: 3.6rem;

    .btn-link {
      margin-left: 0;
    }
  }

  .overviewdashboard.edit-btn-container.staff {
    margin-left: 15px;
  }

  .bz-profile-overview .overviewdashboard.edit-btn-container.staff {
    justify-content: center;

  }

  .bz-profile-overview {
    .edit-btn-container {
      justify-content: flex-start;

      >.btn-link {
        margin-left: 3.6rem;
      }
    }

    .edit-btn-container.staff {
      >.btn-link {
        margin-left: 0;
      }
    }

  }

  .section-Description {
    .k-textarea {
      >textarea {
        max-height: 5rem;
        min-height: 5rem !important;
      }
    }
  }

  .bz-add-group-service {

    .section-CanClientBookOnline {
      margin-top: 1rem;
    }
  }

  .edit-package-form-new {
    gap: 0;
  }

  .bz-planPurchase {
    display: flex;
    align-items: center;

    >span:first-of-type {
      flex: 0 0 6.3rem;
    }

    >.bz-colon {
      margin: 0 0.5rem;
    }
  }

  .bz-pkg-utilization-grid {
    colgroup {
      >col:nth-child(1) {
        width: 230px;
      }

      >col:nth-child(2) {
        width: 300px;
      }

      >col:nth-child(3) {
        width: 220px;
      }
    }
  }
}

@media screen and (max-width:721px) {
  .enroll-client-col .enroll-attendees-blk {

    .btn-link {
      padding-left: 0.375rem;

      >h6 {
        padding-left: 0;
      }
    }
  }

  .edit-package-form-new.add-pkg-form-new {
    >.pkg-col-1 {
      .section-SessionType {

        .expiry-after-field,
        .section-schedules {
          flex: 0 0 100%;
        }

        .section-schedules {
          margin-right: 0;
        }
      }
    }
  }

  .bz-pkg-filter-group.bz-classList-container {
    >.k-card-header {
      flex-direction: column;
      align-items: flex-start !important;
    }
  }

  .bz-classList-container.k-card>.k-card-header>.bz-classes-action.group-event-flex {
    flex-basis: auto;
    margin-bottom: 0;
    width: 100%;

  }

  .bz-classList-container.k-card>.groupmainview.k-card-header>.bz-classes-action.group-event-flex {
    justify-content: start;
  }


  .bz-classList-container.k-card>.k-card-header.formsaddveiw>.bz-classes-action.group-event-flex {
    flex-basis: auto;
    margin-bottom: 0;
    margin-top: 10px;
  }

}

@media all and (max-width:650px) {
  .custom-grid-box .bzc-sessions-list-card-new.bz-classes-checkout .bzc-sessions-list-row-new .section-2 {
    padding-left: 0 !important;
  }

  .bzc-update-session-options-row-buttons {
    flex-direction: column;

    >.section-1 {
      margin-bottom: 1rem;


    }
  }

  .payment-card-input {
    min-width: initial;
    padding-right: 1.5rem;
    max-width: initial;
  }

  .payment-card-input .credit-card-input-form .bz-custom-card-input,
  .bz-plan-puchase-page .payment-card-input .credit-card-input-form .bz-custom-card-input {
    min-width: initial;
  }

  .bz-plan-puchase-page .payment-card-input {
    max-width: initial;
    width: inherit;
  }
}

@media screen and (max-width:620px) {
  .overview-grid .bz-payment-options>.btn-link {
    right: 3rem;
  }

  .group-event-top-blk {
    width: 100%;

    a[title="Download"] {
      min-width: 34.19px;
    }

    .bz-classes-search {

      >.form-control {
        min-width: initial;
      }
    }

    .bz-packages-btn-container {
      >.k-button {
        min-width: 34.19px;
        min-height: 34.19px;
        padding: 0 !important;
        margin-left: 0;
        margin-right: 0 !important;
      }
    }

    .k-dropdown-button {
      >.k-button {
        margin: 0 !important;
      }
    }
  }

  .bz-prorate-checkbox {
    >span {
      align-items: flex-start;

      .k-checkbox-label {
        max-width: 19rem;
      }
    }
  }

  .add-Session-Form,
  .addSessionForm-grid2 {
    grid-template-columns: repeat(1, 1fr);
  }

  .add-Session-Form {
    .section-classItem {
      .bz-duration-label {
        .k-dropdown {
          height: 100%;
        }
      }
    }
  }

  .bz-credit-activity {
    .credit-activity-list {
      display: flex;
      max-height: initial;
      padding: 1rem !important;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .bz-provider-name {
        padding-left: 0;
      }

      >.section-1,
      .section-3 {
        .bz-provider-name {
          display: flex;
          align-items: center;

          >h6 {
            margin-right: 0.35rem;
          }
        }
      }

      >.section-2 {
        margin: 0.25rem 0 0.15rem;
      }

      >.section-3 {
        .bz-provider {
          border-left: 1px solid #c9cbcb;
          display: inline-block;
          margin-left: 0.5rem;
          padding-left: 0.5rem;
        }

        justify-content: flex-start;
      }
    }
  }

  .bz-credit-activity+.k-pager {
    border: 0;
    padding-left: 0;

    .k-pager-numbers-wrap {
      margin-left: 0;
    }
  }

}

@media all and (min-width:601px) {
  .bz-no-add-icon {
    .fa-plus {
      display: none;
    }
  }

  .bz-delete-appoinment-dialog {
    .k-dialog {
      max-width: 36rem;
    }
  }
}

@media screen and (min-width:481px) {
  .k-dialog #card-container {
    min-width: 22.875rem;
  }
}

@media only screen and (max-width: 600px) {
  .bz-tenet-name {
    display: none;
  }

  .bz-myprofile-container .bz-content-text-row .bz-content-body {
    width: 100%;
  }

  .bz-quick-insight .overview-grid .bz-content-body,
  .Booking-Main .recurrence-selection .form-group {
    width: 100% !important;
  }

  .bz-recurence-label {
    margin-top: 0;
  }

  .Booking-Main .recurrence-selection .bz-recurence-btn .k-button {
    width: 100%;
    margin: 0;
  }

  .Booking-Main .recurrence-selection {
    grid-gap: 0 !important;
    gap: 0 !important;
    margin-left: 0;
  }

  .dFlexBtn.bz-addNewMemberBtn {
    align-items: center;

    >h6 {
      line-height: 1.5;
    }
  }

  .bz-myprofile-container {
    padding-bottom: 2rem;
  }

  .bz-main-provider {
    .bz-provider-img {
      img {
        width: 3rem;
        height: 3rem;
      }
    }

  }

  .bz-classList-container.k-card>.k-card-body .k-listview .group-serv-list {
    .bz-price-section .bz-status {
      text-align: left;
    }

    .bz-provider-name {
      >.bz-provider {
        padding-left: 0 !important;
      }

    }

    .bz-availability-dates {
      padding-left: 0;

      >h6 {
        >span {
          &:first-child {
            display: inline-block;
            margin-bottom: 0.1rem;
          }

          display: block;
          padding-left: 0 !important;
        }
      }
    }
  }

  .bz-classList-container.k-card>.k-card-body .k-listview .group-serv-list.bz-inhouse-group-events .bz-availability-dates>h6>span:last-of-type {
    padding-left: 0.5rem !important;
  }

  .bz-groupServices-list-new {
    .bz-price-section .bz-status {
      text-align: center !important;
      vertical-align: middle;
    }
  }

  .chargeback-card {
    .dflexBtn-chargeback {
      flex-direction: column-reverse;
      justify-content: flex-end;
      align-items: flex-end;

      >.dflexBtn {
        margin-top: 0.5rem;

        >div {
          width: 50%;
        }

      }
    }
  }

  .bz-complementaryCheckout {
    .k-datetimepicker {
      width: 100%;
    }
  }

  .bz-addon-cancellation.bz-complementaryCheckout {
    .bz-content-text-row {
      .bz-colon {
        display: none !important;
      }

      .bz-content-body {
        padding-left: 0;
      }
    }

  }

  .edit-package-form-new-row-1,
  .edit-package-form-new {
    grid-template-columns: 1fr;
  }

  .bz-content-text-row-mobile {

    .bz-content-text-row,
    .bz-content-child-text-row {
      display: flex !important;
      flex-direction: row;
      align-self: flex-start;

      >.bz-content-title {
        min-width: 10rem;
      }

      >.bz-colon {
        display: initial !important;
        margin: 0 0.5rem;
      }

    }
  }

  .bz-notify-row.bz-content-text-row {
    flex-direction: column !important;
    display: flex !important;

    >.bz-colon {
      display: none !important;
    }

    >.bz-content-body {
      padding-left: 0;
      text-align: center;
      max-width: none;
    }
    >.bz-content-body.plancancel {
      text-align: left;
    }
  }

  .bz-applied-coupon-details {
    width: 100%;
  }

  .bz-classList-container.k-card>.k-card-header>.bz-classes-action.group-event-flex {
    .group-event-top-blk {
      justify-content: flex-end;
    }
  }

  .overview-grid.bz-subscription-overview .bz-payment-options>.subscription-name .mble-subscription-badges {
    margin: 0;
  }

  .overview-grid.bz-subscription-overview .bz-content-body {
    max-width: initial !important;
  }

  .bz-package-audit-history .bz-package-audit-history-list .bz-activity-time-date,
  .bz-package-audit-history .bz-package-audit-history-list .bz-activity-dis-div {
    margin-right: 0.5rem;
  }

  .bz-addNotes-textarea {
    width: auto !important;
  }

  .bz-delete-appoinment-dialog {
    .k-dialog {
      .column-button-section {
        margin-top: 0.5rem;

      }

      .column-button-section.three-btns {
        flex-direction: column;
        margin-top: 0.5rem;

        >.k-button {
          margin: 0 0 0.5rem;
        }
      }
    }
  }

  .bz-classList-container.k-card>.k-card-body .k-listview .bz-packages-list-new {
    justify-content: flex-start;
    padding-top: 2rem !important;

    .section-1 {
      padding-right: 5rem;
      margin-bottom: 0.5rem;
    }

    .section-5 {
      max-width: 2 0rem;
    }

    .section-6 {
      position: absolute;
      top: 3rem;
      width: auto;
      right: 3rem;

      .bz-main-provider {
        padding: 0;
      }
    }

    .section-7 {
      position: absolute;
      top: 3rem;
      width: auto;
      right: 0.75rem;
    }

    .section-9 {
      margin-top: 0;

      .bz-provider-name {
        margin: 0 !important;
      }

      .bz-packages-tags-new .k-chip-list {
        margin-top: 0 !important;
      }
    }
  }

  .bz-classList-container.k-card {
    >.k-card-body {
      .k-listview {
        .bz-groupServices-list-new {
          gap: 0;

          >div {
            padding-left: 0;
          }

          .section-1 {
            order: 2;
            padding-bottom: 0.75rem;
          }

          .section-2 {
            order: 1;
            padding-right: 6.5rem;
            padding-bottom: 0.75rem;
            padding-top: 15px;

            >.bz-main-provider {
              padding: 0;
            }
          }

          .section-3 {
            order: 3;

            .bz-calender-icon {
              display: none;
            }

            .bz-availability-dates {
              padding-left: 0 !important;

              h6 {
                padding-left: 0;
              }
            }
          }

          .section-4,
          .section-5 {
            position: absolute;
            width: auto;
            padding: 0;
          }

          .section-4 {
            order: 4;
            top: 10px;
            right: 3.5rem;
          }

          .section-5 {
            order: 5;
            right: 1.25rem;
            top: 10px;
          }
        }
      }
    }
  }

  .bz-groupServices-sessions-list-new {
    padding: 1.25rem !important;
    gap: 0;
    position: relative;

    >div {
      padding-left: 0;
    }

    .section-1 {
      order: 2;
      padding-bottom: 0.75rem;

      .bz-calender-icon {
        display: none;
      }

      .bz-availability-dates {
        padding-left: 0;
      }
    }

    .section-2 {
      order: 1;
      padding-right: 7.5rem;
      padding-bottom: 0.75rem;

      >.bz-main-provider {
        padding: 0;

        .bz-provider-img img {
          width: 3rem !important;
          height: 3rem !important;
        }
      }
    }

    .section-3 {
      order: 3;

      .bz-calender-icon {
        display: none;
      }

      .btn-link {
        padding-left: 0;
      }
    }

    .section-4 {
      >.bz-price-section {
        display: flex;
        flex-direction: column;
        align-items: center;

        h6 {
          margin-bottom: 0;
          margin-left: 0.5rem;
        }
      }
    }

    .section-4,
    .section-5 {
      position: absolute;
      width: auto;
      padding: 0;
    }

    .section-4 {
      order: 4;
      top: 1.25rem;
      right: 3.5rem;
    }

    .section-5 {
      order: 5;
      right: 0.5rem;
      top: 1.1rem;
    }
  }

  .package-redeem-card {
    .reedem-summary {
      width: 100%;
    }

    .package-item {
      width: 50%;

      >.package-details {
        margin-left: 0;
      }
    }
  }

  .checkout-panelbar-wrapper {
    .package-redeem-card-blk>.package-redeem-card {
      .package-item {
        width: 100%;
      }
    }
  }

  .package-redeem-card-blk {
    flex-direction: column;

    >.reedem-summary {
      flex: 0 0 auto;

      >div {
        min-height: initial;
      }

      h6 {
        border-right: 0;
        min-height: initial;
      }
    }

    >.package-redeem-card {
      flex: 0 0 100%;
      width: 100%;
    }
  }

  .bzc-packages-list-card-new.bz-pkg-new .card-body .bzc-packages-list-row-new .section-2 .package-count {
    margin-bottom: 0.5rem;
  }

  .bzc-grid.bz-selectSession {
    max-height: calc(100vh - 23rem);
  }

  .bz-selectSession.edit-event-tbl {
    max-height: calc(100vh - 28rem);
  }

  .bz-notify-row {
    .k-datetimepicker {
      width: 100%;
    }
  }

  .subscription-blks .subscription-rates-blk .subscription-rates .offer-section {
    right: 0 !important;
  }

  .Booking-Main .three-panel .selected-slot-footer>.k-button {
    width: 100%;
    margin: 0;
  }

  .Booking-Main .first-panel .firstPanelContent,
  .Booking-Main .one-panel .firstPanelContent {
    padding: 1rem 0;
  }

  .Booking-Main {
    .second-panel {
      width: 23.8rem !important;
      margin-left: 0;
      padding: 0 0 1.5rem !important;
    }

    .selected-service-info {
      >h6 {
        margin-bottom: 0;
      }
    }

    .provider-info-photo {
      >img {
        width: 4rem !important;
      }
    }
  }

  .bz-edit-Package-form.bz-edit-pkg-new {
    .edit-package-form-new {

      >.pkg-col-2 {
        >.section-Description1 {
          margin-top: 1rem;

        }

        .k-textarea {
          >textarea {
            height: 6rem;
          }
        }
      }
    }

    .k-dropdown-button {
      >.k-button {
        .mble-ver-txt {
          display: initial;
        }
      }
    }

  }

  .bz-add-pkg-dialog.bz-book-Appoinment-dialog.k-dialog-wrapper {
    .k-dialog {
      .k-window-title.k-dialog-title {
        max-width: 20rem;
      }

    }

  }

  .bz-groupServices-inhouse .bz-group-services-card .bz-groupServices-card-row {
    grid-template-columns: repeat(2, 1fr);
  }

  .bz-add-pkg-dialog.bz-book-Appoinment-dialog.k-dialog-wrapper .k-dialog .k-dialog-content {
    max-height: 36rem;
  }

  .bz-cancellation-page-new {
    .k-listview {
      .bz-cancellation-card-row.charge-dropdown {
        >[class|="column"]:not(.column-6) {
          padding-right: 0;
        }

        .column-6,
        .column-5 {
          padding-right: 0;

          .k-textbox {
            width: 100%;
          }
        }

        .column-4 {
          .k-dropdown {
            width: 100%;
          }
        }
      }
    }
  }

  .bz-notification-container {
    .bz-content-text-row {
      display: flex;
      flex-direction: row;

      >.bz-content-title {
        padding-right: 1rem;
        width: calc(100% - 2.25rem);
      }

      >.bz-content-body {
        width: 2.25rem;
      }
    }

    .bz-client-dropdown.bz-content-text-row {
      flex-direction: column;

      >.bz-content-body {
        width: 100%
      }
    }
  }

  .section-CanClientBookOnline {
    >span {
      display: flex;

      .k-checkbox-label {
        max-width: 19rem;

        .k-dropdown {
          margin-top: 0.3rem;
        }
      }
    }
  }

  .bz-header-row {
    flex-direction: column;
    align-items: flex-start;

    >h5 {
      margin-bottom: 1rem !important;
    }

    .bz-schedule-link-btn2 {
      justify-content: flex-start;
      flex-wrap: wrap;

      .k-button {
        margin: 0.25rem 0.5rem 0.25rem 0 !important;
      }
    }
  }
}

@media screen and (min-width:501px) {
  .timezone-mobile {
    .bz-timezone-dropdown {
      display: none;
    }
  }
}

@media screen and (max-width:570px) {
  .bz-kiosk-link {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    >span {
      flex-basis: initial;
    }
  }

}

@media only screen and (max-width: 500px) {
  .bz-associated-groups>.card-body .bz-packages-tags .k-chip-list {
    grid-template-columns: repeat(2, 1fr);
  }

  .bz-refund-card {
    margin-bottom: 0 !important;
    bottom: 0;
  }

  .success-component .success-info {
    width: 100%;
    padding: 0.5rem;

    .card-content {
      padding: 1rem;
    }
  }

  .bz-recurrence-text-item {
    .k-panelbar-item-text {
      max-width: 13rem;
      line-height: 1.85;
    }
  }

  .serviceDetailsPanel-grid .section-1 {
    grid-area: 1 / 1 / 1 / 4;
  }

  .payment-card-input {
    .credit-card-input-form {
      .bz-custom-card-input {
        flex-wrap: wrap;

        >.card-number-sec {
          flex-basis: calc(100% - 2.5rem);
          margin-right: 0;
        }

        >.expiry-date-sec {
          margin-left: 34px;
        }
      }
    }
  }

  .bz-group-services-list .bz-groupService-statuses {
    .bz-statuses.Notes {
      margin-right: 0.5rem !important;
    }
  }

  .bz-classList-container.k-card>.k-card-header>.bz-classes-action.group-event-flex {
    .group-event-top-blk {
      justify-content: flex-end;
    }
  }

  .bz-classList-container.k-card>.groupmainview.k-card-header>.bz-classes-action.group-event-flex,
  .bz-classList-container.k-card>.formsaddveiw.k-card-header>.bz-classes-action.group-event-flex {
    .group-event-top-blk {
      flex-direction: column;
      gap: 10px;
      margin-top: 0px;

      .mb-3.mb-md-0 {
        margin-bottom: 0 !important;
      }

      .k-widget.k-dropdown {
        width: 100%;
      }

      .bz-classes-search {
        margin: 0 !important;
      }
    }

  }

  .bz-group-services-list .k-listview {
    max-height: calc(100vh - 13rem);
  }

  .withdraw-plan {
    .bzc-border-left {
      border-left: 0;
    }
  }

  .payment-card-input-new {
    padding-right: 0;

    >.payment-card-input-blk {
      >.k-form-field>.credit-card-input-form .bz-custom-card-input {
        flex-wrap: wrap;
        justify-content: flex-start;

        >.card-number-sec {
          width: 90%;
        }

        >.expiry-date-sec {
          margin-left: 2.2rem;
        }
      }
    }
  }

  .session-table {
    >.filter-row {
      .k-button {
        margin-left: 0 !important;
      }
    }
  }

  .bz-pkg-filter-group {
    .k-card-title {
      margin-bottom: 0rem !important;
    }
  }

  .bz-icon-btn {
    >.k-button {
      min-width: initial;
      border: 0;
      padding: 0 !important;
    }
  }

  .bz-openPath-credintials {
    >.mobile-onsite-checkins {
      display: block !important;

      >.section-1,
      .section-2 {
        width: 100% !important;
        text-align: left;
      }

    }

    .openpath-credintials.mobile-onsite-checkins {
      display: flex !important;
    }

    .bz-openPath-actions {
      justify-content: flex-start !important;

      .bz-checkins-actions-container {
        .k-button.k-button-sm {
          margin-left: 0;
        }
      }
    }
  }

  .plan-cards-lead {
    flex-direction: column;

    .plan-cards-lead-item {
      width: auto;
    }
  }

  .bz-schedule-btn-containet-oneonone {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .short-code {
    .input-group {
      >.form-control.k-textbox {
        flex: initial;
        width: 30%;
        padding: 0 0.4rem;
      }
    }

    .k-button {
      margin-left: 0;
    }
  }

}

@media only screen and (max-width: 480px) {
  .group-event-top-blk {
    justify-content: flex-end;
    margin-top: 6px;

    .bz-classes-search {
      width: calc(100% - 43px);
      margin-left: 0 !important;
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }

  .formsaddveiw {
    .group-event-top-blk {


      .bz-classes-search {
        width: 100%;

      }
    }

  }

  .bz-package-purchased-success .k-dialog {
    min-width: 97%;
  }

  .chargeback-card .dflexBtn-chargeback>.dflexBtn>div {
    width: 100%;
    padding-left: 0 !important;
  }

  .chargeback-card .dflexBtn-chargeback>.dflexBtn>.bz-classes-search {
    margin-bottom: 0.5rem;
  }

  .bz-group-events-staff {
    flex-direction: column;
    align-items: flex-start;

    .bz-assignClass-pkg-btn {
      margin-top: 1rem;

      .k-button {
        margin-left: 0;
      }
    }
  }

  .bz-multiplepackage-select {
    border: 0;

    .packageSelectionDropdown {
      width: 100% !important;
    }
  }

  .bz-checkout-switch-btns {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0;
  }

  .bz-bulk-group-edit {
    .k-dropdown {
      width: 100% !important;
    }
  }
}

@media screen and (max-width:450px) {
  .success-component .success-info {

    .card-content {
      .booking-date {
        display: flex;
        align-items: flex-start;

        .fa-calendar {
          margin-top: 0.21rem;
        }
      }

      .location-text-blk {
        align-items: flex-start !important;

        .location-icon-blk+span {
          word-break: break-all;
        }
      }
    }

  }
}

@media only screen and (max-width: 420px) {
  .overview-grid .bz-payment-options {
    grid-template-columns: 4.5rem 4.5rem 8rem;
    grid-column-gap: 0.75rem;
    padding-right: 0;
    position: relative;

    >.subscription-name {
      grid-area: 1/1/1/4;
    }

    >.btn-link {
      right: 1rem;
      top: 0rem;
    }

  }

  .bz-payUnpaid-dues-dropdown {
    width: 100%;
    max-width: initial;
  }

  .overview-grid.bz-subscription-overview .bz-content-body {
    .bz-payment-options {

      h6>.name {
        max-width: 12rem;
      }
    }

  }

  .bzo-services-container .k-listview-content .buz-online-list-view-new .col-md-9 h6.service-title {
    display: block;
  }

  .bzo-services-container .k-listview-content .buz-online-list-view-new.bz-one-on-one-purchase .col-md-9 h6.service-title {
    display: inline-flex;
    margin-right: 0.25rem;
    word-break: break-word;
    align-items: flex-start;

    &+span {
      margin-top: 0.2rem;
    }
  }

  .bzo-services-container .k-listview-content .bz-one-on-one-purchase .service-title .location-icon {
    margin-top: 0.3rem;
  }

  .bz-classList-container.k-card>.k-card-body .k-listview .bz-packages-list-new .section-5 {
    max-width: 14rem;
  }

  .short-code {
    .input-group {
      >.input-group-prepend {
        width: calc(100% - 7rem);
        min-height: 2.188rem;

        >.input-group-text {
          height: 100%;
          width: 100%;
        }
      }

      >.form-control.k-textbox {
        flex: initial;
        width: 7rem;
      }

    }
  }

  .edit-events-blk {
    .k-form-buttons {
      justify-content: flex-end;

      .k-button {
        min-width: 4rem;
        margin: 0;
        padding: 0.4469rem 0.75rem !important;
      }

      .k-button[type="submit"] {
        margin: 0 0.5rem;
      }
    }
  }

  .enroll-client-col {
    .enroll-attendees-blk {
      display: initial;
      max-width: 90%;

      >.badge {
        margin-left: 0;
        margin-top: 0.75rem;
      }

      .bz-vertical-line {
        vertical-align: middle;

        &:nth-last-of-type(2) {
          display: none;
        }
      }
    }
  }

  .enroll-attendees-blk {
    display: initial;

    .badge {
      margin-top: 0.75rem;
    }
  }
}

@media screen and (max-width:416px) {
  .package-redeem-card {
    align-items: flex-start;

    .reedem-summary {
      border: 0;
    }
  }

  .package-redeem-card .package-item .package-details {
    margin-left: 0;
  }

  .bz-group-services-list .bz-groupService-statuses {
    position: absolute;
    bottom: 0.8rem;
    right: 1rem;
  }

  .bz-group-services-list {
    .bz-groupService-statuses.bz-groups-actions {
      position: unset;

      .bz-statuses {
        padding: 0.3rem 0.5rem;
        min-width: 4.2rem;
      }

      .bz-statuses.Notes {
        min-width: auto;
        padding: 0;
        margin-right: 0.65rem !important;
      }

      .bz-badge {
        width: auto;
      }
    }
  }

}

@media screen and (max-width:400px) {
  .package-redeem-card-blk>.package-redeem-card {
    margin: 0;
  }

  .package-redeem-card .package-item {
    width: 100%;
    padding: 0;
  }
}

@media screen and (max-width:379px) {
  .complimentary-btns .bz-schedule-btn-container {
    .k-button {
      width: 100%;
      justify-content: flex-start;
    }

    .bz-vertical-line {
      display: none;
    }
  }

  .payment-card-input-new {
    >.payment-card-input-blk {
      >.k-form-field>.credit-card-input-form .bz-custom-card-input {
        padding: 0.25rem 0.5rem;

        >.card-number-sec {
          padding: 0 0 0 0.5rem;
          width: initial;
        }

        >.zipcode-sec {
          padding-left: 0.5rem;
        }

        >.expiry-date-sec {
          margin-left: 1.9rem;
        }
      }
    }
  }

  .bz-add-card-field {
    .payment-card-input-new {
      >.payment-card-input-blk {
        >.k-form-field>.credit-card-input-form .bz-custom-card-input {
          >.card-number-sec {
            padding: 0 0 0 0.5rem;
            width: 80%;
          }

          >.zipcode-sec {
            padding-left: 0.5rem;
            margin-top: 10px;
            margin-bottom: 10px;
          }

          >.expiry-date-sec {
            margin-left: 1.9rem;
            margin-top: 10px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

@media screen and (min-width:361px) {
  .another-payment {
    >.d-flex {
      align-items: center;
    }
  }
}


//chargeback responsive css

@media screen and (min-width:992px) {
  .chargeback-card .main-grid .main-grid-item.bz-chargeback-first-row>.column-3 {
    grid-area: 1/2/1/4;
  }

  .chargeback-card .main-grid .main-grid-item.bz-chargeback-second-row {
    grid-template-columns: 0.8fr 1fr;
  }
}

@media screen and (max-width:991px) {
  .chargeback-card .main-grid .main-grid-item {
    grid-template-columns: 1fr 1fr 2rem;
  }

  .site-checkin-livestream {
    >.k-card-body {
      padding: 0;
    }
  }


}

@media screen and (max-width:770px) {
  .chargeback-card .main-grid .main-grid-item.bz-chargeback-second-row {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }
}

@media all and (min-width:751px) {
  .bz-insertChargeback-dialog {
    .k-dialog {
      width: 730px;
    }
  }

  .bz-insertChargeback-dialog.bz-transaction-chargeback-dialog {
    .k-dialog {
      width: 47rem;
    }
  }
}

@media all and (max-width:750px) {
  .bz-chargeback-grid {
    colgroup {
      col:nth-child(1) {
        width: 130px;
      }

      col:nth-child(2) {
        width: 140px;
      }

      col:nth-child(3) {
        width: 200px;
      }

      col:nth-child(4) {
        width: 90px;
      }

      col:nth-child(5) {
        width: 80px;
      }
    }
  }

  .bz-insertChargeback-dialog .k-dialog {
    width: 95%;
    max-width: initial !important;
  }

  .k-tabstrip-top>.k-tabstrip-items-wrapper>.k-tabstrip-items {
    flex-flow: row wrap !important;
  }
}

@media screen and (max-width:600px) {
  .chargeback-card .main-grid .main-grid-item.bz-chargeback-second-row>.bz-btn-blk {
    flex-direction: column;
    align-items: flex-start;

    .column-2 {
      width: 100%;

      .bzc-provider-name {
        width: calc(100% - 83px);

      }

      .k-button {
        margin-right: 0 !important;
      }
    }
  }

  .chargeback-card .main-grid .main-grid-item.bz-chargeback-second-row>.bz-btn-blk>.column-3 {
    align-self: flex-start;
    width: 100%;

    .k-button {
      margin-left: 0;
      width: 49%;
    }
  }

  .chargeback-card .main-grid .main-grid-item {
    grid-template-columns: 1fr;
    gap: 0.5rem;

  }

  .chargeback-card .main-grid .main-grid-item.bz-chargeback-first-row {
    position: relative;
    padding-right: 2rem;
  }

  .chargeback-card .main-grid .main-grid-item.bz-chargeback-first-row>.column-3 {
    justify-content: flex-start;
  }

  .chargeback-card .main-grid .main-grid-item.bz-chargeback-first-row {
    .arrow-toggle {
      position: absolute;
      right: 0rem;
      top: 1.5rem;
    }
  }

  .bz-pakage-overview {
    .applied-coupons {
      margin-top: 1rem;

      .bz-coupon-pkg {
        .bz-content-text-row {
          display: block;

          &:first-of-type {
            .bz-colon {
              display: none !important;
            }
          }

          .bz-content-body {
            padding-left: 0;
          }
        }

        >.bz-subscription-card {
          .bz-payment-options {
            width: 100%;
          }
        }
      }
    }
  }

  .bz-custom-migration-tooltip {
    max-width: 250px;
  }

  .bz-custom-migration-tooltip {
    max-width: 250px;
  }

  .bz-logs-filter {
    .filter-options.left {

      .bz-date-filter-main,
      .bz-dateFilter-container {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width:500px) {
  .bz-chargeback-summary-grid {
    colgroup {
      col:nth-child(1) {
        width: 130px;
      }

      col:nth-child(2) {
        width: 80px;
      }

      col:nth-child(3) {
        width: 110px;
      }

      col:nth-child(4) {
        width: 100px;
      }
    }
  }
}

@media only screen and (min-width: 700px) and (max-width: 820px) {
  .bz-logs-filter {
    .filter-level-2 {
      .k-multiselect {
        width: 12rem !important;
      }
    }
  }
}

@media only screen and (min-width: 480px) and (max-width: 992px) {
  .bz-chargeback-dialog .k-dialog .k-dialog-content>div {
    margin: auto;
  }
}

@media screen and (max-width:389px) {
  .bz-group-services-list .bz-elipsis-icon>.k-button-icon {
    margin: 0;
  }

  .bz-group-services-list.bz-past-GroupServices .bzc-sessions-list-card .bzc-sessions-list-row {
    grid-template-columns: calc(100% - 8rem) 8rem;
  }
}

@media screen and (max-width:379px) {
  .chargeback-card .main-grid .main-grid-item.bz-chargeback-second-row>.bz-btn-blk>.column-3 {
    .k-button {
      width: auto;
    }
  }
}

//quicklinks media query
@media screen and (max-width:450px) {
  .QuickLinksGrid-new {

    width: auto;

  }

}

@media screen and (max-width:375px) {
  .QuickLinksGrid-new {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 1rem;

    .QuickLinksParentGridItem {
      width: 100%;
    }

  }

}

// edit events page
@media screen and (max-width:1150px) {
  .bz-edit-appoinments {
    .bzc-edit-session-options-row-new {
      grid-template-columns: 24rem auto;
      justify-content: flex-start;

      .toggle-switch-blk {
        min-height: 2.375rem;
        display: flex;
        align-items: center;
      }
    }

    .bzc-edit-session-options-row {
      >.column-2:nth-child(2) {
        flex: 0 0 calc(100% - 25rem);
      }
    }
  }

  span.texteditapp {
    padding-left: 0px;
  }

  .editactionbuttons {
    width: 100%;
    justify-content: center;
    margin: 15px 0;
  }
}

@media screen and (max-width:730px) {
  .bz-edit-appoinments {
    .bzc-edit-session-options-row-new {
      grid-template-columns: 1fr 1fr;
      justify-content: flex-start;
    }

    .bzc-edit-session-options-row {
      display: flex;

      >.column-2:nth-child(1) {
        flex: 0 0 50%;
        margin-right: 1rem;
      }

      >.column-2:nth-child(2) {
        flex: 0 0 calc(50% - 1rem);
      }
    }
  }
}

@media screen and (max-width:600px) {
  .bz-edit-appoinments {
    padding: 0 .5rem;

    .bzc-edit-session-options-row-new,
    .bzc-edit-session-options-row {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      >.column-2 {
        width: 100%;

        .k-checkbox-label {
          padding-left: 0rem;
          margin-left: 0;
        }

        .k-timepicker,
        .k-dropdown,
        .d-flex,
        .k-numerictextbox,
        .d-flex>div.mr-md-3 {
          width: 100%;
        }

        .bzc-input-group {
          .k-numerictextbox {
            width: calc(100% - 107.83px);
          }

          .input-group-append {
            width: auto;
          }
        }

      }
    }

  }
}

@media screen and (max-width:600px) {
  .bz-subMember-overview {
    .btn-link {
      margin-left: 0;
    }
  }

  .bz-signature-dialog {
    >.k-dialog {
      width: 95%;

      .k-dialog-content {
        width: 100% !important;
        height: 100% !important;
        max-height: initial !important;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

        .bz-signaturepad-container {
          canvas {
            height: 495px !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width:500px) {
  .bz-subMember-overview {
    .bz-summary-profile {
      .user-details {
        flex: 0 0 100%;
        margin-top: 0.25rem;

        >.d-flex {
          flex-wrap: wrap;

          h6 {
            flex: 0 0 100%;
            margin-bottom: 0.25rem !important;
          }

          .contact {
            margin-bottom: 0.25rem;
          }
        }
      }
    }
  }

  .bz-checkout-service {
    .k-panelbar>.k-item>.k-link {
      padding: 1rem 0.9375rem;
    }
  }
}

//scheduler responsive css
@media only screen and (max-width: 1148px) {

  //scheduler new changes css
  .Booking-Main {
    height: auto;

    .second-panel.bz-availableTimesCard,
    .two-panel.bz-thirdPanelActive {
      padding-bottom: 0 !important;

      .calendar-item {
        display: none;
      }

      .availableTimes-Item {
        margin-top: 0 !important;

        .bz-selected-date {
          font-size: 1rem;
        }

        .render-time-duration {
          >.bz-time-card {
            margin-bottom: 0.625rem !important;
          }

        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {

  //scheduler new changes css
  .Booking-Main {
    height: auto;

    .second-panel.bz-availableTimesCard,
    .two-panel.bz-thirdPanelActive {
      padding-bottom: 0 !important;

      .calendar-item {
        display: none;
      }

      .availableTimes-Item {
        margin-top: 0 !important;

        .bz-selected-date {
          font-size: 1rem;
        }

        .render-time-duration {
          >.bz-time-card {
            margin-bottom: 0.625rem !important;
          }

        }
      }
    }

    .two-panel.bz-thirdPanelActive {
      padding: 0;

      .calendar-main {
        border-bottom: 0;
      }

      .recurrence-selection {
        margin-top: 0;
      }
    }

    .two-panel.hideTimeSlots-mbl {
      .appt-submit-btn {
        display: none !important;
      }

    }

    .two-panel {
      .calendar-main .availableTimes-Item .render-time-duration {
        margin-bottom: 0.5rem;
      }
    }

    .two-panel.no-recurrence {
      .calendar-main .availableTimes-Item .render-time-duration {
        max-height: 400px;
        margin-bottom: 1rem;
      }
    }

    .hideTimeSlots-mbl+.three-panel {
      width: 18rem;
      margin-left: 2.5rem;

      .bz-selected-appoinment-container {
        display: initial;
      }
    }

    .three-panel {
      padding: 0;
    }
  }

  .purchaseapptsquickslinks .k-card-header .bz-classes-action {
    justify-content: start;
  }

  .purchaseapptsquickslinks .bz-classes-action .bz-classes-search {
    margin-left: 0;
  }

  .bz-Self-CheckIn {
    .self-checkbox {
      padding: 1rem;

      .bz-appoinments-info-selfechekin {
        display: grid;
        grid-template-columns: 1fr;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .Booking-Main {
    .two-panel.hideTimeSlots-mbl {

      .availableTimes-Item,
      .appt-submit-btn {
        display: none !important;
      }

    }
  }

  .site-checkin-livestream .bzc-checkins-list>.k-listview-content>.ba-activity-card .livestream_list>.row {
    grid-template-columns: 1fr;
  }
}

@media only screen and (min-width: 760px) and (max-width:990px) {

  .Booking-Main {
    .two-panel.bz-thirdPanelActive {
      .recurrence-selection {
        .form-group {
          width: 100%;
        }

        .bz-recurence-btn {
          width: 100%;

          .k-button {
            width: 100%;
            margin: 0;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 755px) {

  //scheduler new changes css
  .Booking-Main {
    height: auto;

    >.first-panel,
    >.one-panel {
      padding: 0;
      position: relative;
      margin-bottom: 1rem;
      border: 0;
      border-bottom: 1px solid #cccfd6 !important;
      border-right: 0 !important;
      padding-bottom: 0.5rem;

      >.firstPanelContent {
        padding-top: 0;

        >.back-btn-item {
          position: absolute;
          left: 0;
          top: 0;
        }

        >.provider-items {
          text-align: center;
          gap: 0.2rem;
          width: calc(100% - 3rem);
          padding-left: 3rem;

          .selected-service-info {
            align-items: center;
            order: 3;

            >h6 {
              line-height: 1.5;
              font-size: 1.25rem;
              font-weight: 700;
            }
          }

          .selected-Items-booking {
            order: 1;

            >.selected-provider-info {
              align-items: center;
              margin-bottom: 0.25rem;

              .btn-link {
                display: none;
              }
            }
          }

          >h6 {
            order: 2;
            line-height: 1.5;
            margin-bottom: 0;
            font-weight: 600;
            color: #84898c;

            >.bz-provider-label {
              display: none;
            }
          }

          >.bz-duration {
            order: 4;
          }

          .text-capitalize.date {
            order: 5;
          }

          >.text-muted:nth-last-of-type(1) {
            order: 7;
          }

          >.text-muted:nth-last-of-type(2) {
            order: 6;
          }

          >.text-muted {
            font-weight: 500;
          }

        }
      }

      .upcoming-appts-collapse-card {
        background-color: transparent;
        color: #008db9;
        justify-content: center !important;
        align-items: center;
        padding: 0;
        margin-bottom: 0.625rem;

        >.btn-link {
          margin-left: 0.5rem;

          .svg-inline--fa {
            color: #008db9;
            font-size: 1rem;
          }
        }

      }
    }

    >.first-panel.bz-availableTimesCard,
    .one-panel {
      .timezone-mobile {
        .bz-timezone-dropdown {
          display: flex !important;
        }

        >span {
          display: none;
        }
      }
    }

    >.first-panel.client-page,
    >.first-panel.bz-availableTimesCard,
    .one-panel {
      >.firstPanelContent {
        >.provider-items {
          padding-left: 0;
          width: 100%;

          .selected-provider-info {
            padding-left: 3rem;
            width: calc(100% - 3rem);
          }

          .css-26l3qy-menu {
            top: initial;
            bottom: initial;
          }
        }
      }
    }

    .second-panel.bz-thirdPanelNotActive,
    .two-panel.bz-thirdPanelActive {
      margin: 0;

      >.my-bookings_booking-body_trainers {
        padding: 0;
      }
    }

    .second-panel,
    .two-panel {
      .bz-provider-lists {
        .bz-trainers-card {
          min-height: initial;

          img.bz-user-img {
            width: 3rem;
          }
        }
      }

      >.calendar-main {
        padding: 0;

        >.calendar-item {
          .calendar-section-heading {
            padding-left: 0;
            padding-top: 0;

            >h5 {
              font-weight: 600;
              margin-bottom: 0.75rem !important;
              line-height: 1.5;
              text-align: center;
            }
          }

          .bz-calender-sec {
            .k-calendar {
              .k-calendar-header {
                .k-calendar-title {
                  font-size: 1rem !important;
                }
              }

              .k-calendar-monthview {
                >.k-calendar-content {
                  >.k-calendar-thead {
                    >.k-calendar-tr {
                      >.k-calendar-th {
                        height: auto !important;
                        line-height: 1;
                      }
                    }
                  }
                }
              }
            }

          }
        }
      }

      .client-creation-page {
        padding: 0;

        h4 {
          font-size: 1.25rem;
          font-weight: 600;
          margin-bottom: 1rem;
        }

        .k-form-fieldset.form-row {
          width: 100%;

          .form-group {
            >.k-form-field {
              margin-top: 0;
            }
          }
        }

        .k-form-buttons {
          >.k-button {
            width: 100%;
            margin: 0;
          }
        }
      }
    }

    .second-panel.bz-availableTimesCard {
      .appt-submit-btn {
        padding: 0;

        .submit-btn {
          width: 100%;
          margin: 0;
          margin-top: 1rem;
        }
      }
    }

    .two-panel.bz-thirdPanelActive.hideTimeSlots-mbl {
      >.recurrence-selection {
        display: none !important;
      }
    }

    .hideTimeSlots-mbl+.three-panel {
      margin-left: 0;
      width: 23rem;
      margin-top: 0.5rem;

      .bz-selected-appoinment-container {
        max-height: calc(100vh - 24rem);
        margin-bottom: 0.5rem;
      }
    }

    .hideTimeSlots-mbl.no-recurrence+.three-panel {
      .bz-selected-appoinment-container {
        max-height: 17.5rem;
      }
    }

    .three-panel {
      .selected-slot-footer>.k-button {
        width: 100%;
        margin: 0;
      }
    }

    .scheduler-location-dropdown {
      order: 8;

      .k-textbox-container {
        padding-top: 0;

        .k-dropdown {

          &:focus,
          &:focus-within {
            box-shadow: none;
          }

          border: 0;
          background-color: transparent;

          .k-dropdown-wrap {
            justify-content: center;
          }

          .k-input {
            font-size: 1rem;
            font-weight: 500;
            justify-content: center;
            width: auto;
            padding: 0;
            height: auto;

            >span:nth-child(1) {
              color: #84898c;

              SVG {
                font-size: 1.25rem;
              }
            }

            >span:nth-child(2) {
              max-width: 13rem;
              color: #84898c;
            }
          }

          .k-select {
            .k-icon {
              margin-top: 0.1rem;
            }
          }
        }

        .k-label {
          display: none;
        }
      }
    }

    .location-blk-text {
      justify-content: center;

      >span:nth-child(2) {
        max-width: 12rem;
      }
    }
  }

  .Booking-Main.prospect-user-main .second-panel.bz-availableTimesCard {
    margin-left: 0;
  }

  .prev-selected-slot {
    align-items: center;
  }
}

@media only screen and (max-width:500px) {
  .Booking-Main.bz-provider-not-selected {
    .second-panel {
      .bz-provider-lists {
        max-height: calc(100vh - 16.5rem);
      }
    }
  }

  .Booking-Main.bz-provider-selected {

    >.first-panel,
    >.one-panel {
      margin-bottom: 0.5rem;
      width: 100%;

      .firstPanelContent {
        padding-bottom: 0.5rem;
      }
    }
  }

  .Booking-Main {

    .second-panel,
    .two-panel {
      >.calendar-main {
        .calendar-item .calendar-section-heading>h5 {
          margin-bottom: 0 !important;
        }

      }
    }

    .second-panel.bz-availableTimesCard.bz-thirdPanelNotActive {
      .availableTimes-Item .render-time-duration {
        max-height: calc(100vh - 20.5rem);
      }
    }

  }

  .bz-openPath-credintials {

    .credentialopenpath .k-textbox-container,
    .form-control.k-textbox {
      max-width: initial !important;
    }
  }

  .bzc-purchase-class-list-view {
    .bz-purchase-class-list {
      .k-listview {
        .k-listview-content {
          .bz-group-events-new {
            .bzc-sessions-list-row-new {
              padding-bottom: 2.8rem;

              >.section-6 {
                position: absolute;
                bottom: 0;
                right: 0;
              }

              .section-5.rate {
                bottom: 0;
              }

              .section-5.rate.free-class {
                bottom: 0.3rem;
              }
            }
          }
        }
      }
    }

  }
}

//notes page css
@media only screen and (min-width:960px) {
  .k-window.bz-agreement-window {
    width: 950px !important;
  }
}

@media only screen and (max-width:959px) {
  .k-window.bz-agreement-window {
    width: 100% !important;
  }
}

@media only screen and (min-width:601px) {
  .barcode-container {
    .bz-send-qr-icon {

      small,
      .send-to-text {
        display: none;
      }
    }
  }

  .bz-book-Appoinment-dialog.bz-add-tax-dialog {
    .k-dialog {
      max-width: 30rem;
    }
  }

  .bz-import-file-container {
    width: 35rem;
  }
}

@media only screen and (max-width:600px) {
  .bz-notes-dialog>.k-dialog {
    min-width: 95%;

    .k-dialog-content {
      p {
        margin-bottom: 1rem !important;
      }
    }
  }

  .bz-overview-appoinmentsInfo {
    .bz-appoinments-card-status {
      .badge.bzo-service-status {
        margin-top: 0 !important;
      }
    }
  }

  .linked-subscription-grid {
    overflow: auto;

    >table {
      width: 700px;
      margin-bottom: 0.25rem;
    }
  }

  .bz-payment-method-grid {
    >.d-flex {
      flex-direction: column;
      margin-bottom: 0.5rem;

      p {
        margin-bottom: 0.5rem;
      }

      >.arrow-toggle {
        justify-content: flex-end;
      }
    }

    .availble-payment-methods {
      overflow: auto;
      margin-bottom: 1rem;

      >table {
        width: 700px;
        margin-bottom: 0.25rem;
      }
    }
  }

  .bz-package-utilization>.bz-card-new {
    flex: 0 0 45%;
  }

  .barcode-container {
    max-width: 5rem;

    .qr-buzops {
      padding: 0;
    }

    .bz-send-qr-icon {
      display: flex !important;
      flex-direction: column;
      align-items: center !important;
      justify-content: flex-start;
      max-width: 6rem;
      padding: initial;

      .btn-click-sendqr {
        padding: 0.25rem 0.5rem;
        font-size: 0.75rem;

        .fa-envelope {
          margin-right: 0.25rem;
        }
      }
    }
  }

  .mobile-onsite-checkins+.row {
    >.col-sm-6:last-child {
      justify-content: space-between !important;

      .k-button {
        margin-left: 0;
      }
    }
  }


  .bz-overview-page-row .appointment-info-tabs .k-animation-container {
    min-height: 44.25rem;
  }

  .bz-checkins-config-dialog {
    padding-right: 0 !important;

    .k-widget.k-window.k-dialog {
      margin: 1%;

      .k-window-titlebar {
        justify-content: space-between;
      }

      .k-window-title {
        line-height: initial;
      }

      .bz-checkins-config {
        padding: 0;

        .bz-mt-2rem.bz-mb-2_5rem {
          margin: 1rem 0;
        }

        .bz-checkins-settings-container {
          >.col-12 {
            padding: 0;
          }
        }
      }
    }
  }

  .bz-openpath-settings-container {

    .k-dropdown,
    .k-textbox {
      width: 100%;
    }
  }

  .bz-checkins-settings-container {
    .k-numerictextbox {
      width: 100%;
    }
  }

  .one-time-charge {
    .bz-content-body {

      .k-dropdown,
      .k-textbox,
      .k-numerictextbox {
        width: 100% !important;
      }
    }

    .bz-content-body.payment-profile {
      grid-template-columns: calc(100% - 5.5rem) 5.5rem;
    }
  }

  .bz-import-file-container {
    width: 100%;
  }

  .bz-configure-payout-filter>.btn-container {
    justify-content: flex-end;
  }

  .session-table .edit-events-btn {
    position: unset;
  }

  .bzc-grid.bz-selectSession {
    padding-bottom: 0.5rem;
  }

  .bz-pakage-overview.bz-subsection-row {
    >.right-section {
      >.bz-classes-tag-card-container {
        flex-direction: column-reverse;

        .bz-dropdown-group>.bz-schedule-btn>.k-button {
          margin-left: 0 !important;
          margin-right: 0.5rem !important;
        }
      }
    }
  }
}

@media screen and (max-width: 479px) {
  .bz-notes-dialog.onsiteaccessmodal>.k-dialog {
    width: 90% !important;
  }

}

@media screen and (max-width: 575px) {

  .mt-sm-4-openpath {
    margin-top: 0.5rem !important;
  }

  .prorate-tooltip {
    max-width: 7.2rem;
  }

  .fixed-calendarheader {
    position: sticky;
    padding: 0;
  }

  .availability .fixed-calendarheader {
    padding: 0 10px;
  }

  .buzops-fullcalendar .fc .fc-toolbar.fc-header-toolbar {
    margin-top: 0;
    top: 145px;
    margin-bottom: 0;
  }

  .buzops-fullcalendar .fc .fc-view-harness-active>.fc-view {
    top: 8.2rem;
  }

  .fc .fc-toolbar.fc-header-toolbar div:nth-child(1) {
    flex-wrap: wrap;
  }

  .fc .fc-toolbar.fc-header-toolbar h2.fc-toolbar-title {
    margin: 1rem 0 0;
    width: 100%;
  }

  .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:last-child {
    width: 100%;
  }

}

@media screen and (max-width: 360px) {

  .bz-calender-sec>.k-calendar>.k-calendar-view .k-calendar-td.k-today>.k-link:before {
    top: 1.75rem;
    margin-top: 0.5rem !important;
  }

  .bz-classList-container>.k-card-header .bz-classes-action {
    flex-wrap: wrap;
  }

  .filtersr {
    flex-direction: column;
  }
}

//media query based on height
@media screen and (max-height: 522px) {
  .bz-add-bank {
    max-height: 20rem;
    overflow-y: auto;
    padding-right: 1rem;
  }
}


//new media query based on mobile first approach

@media screen and (max-width:600px) {
  .bz-group-scheduled {
    .left-filter {
      width: 100% !important;

      >div {
        position: unset !important;
        height: 100% !important;
        max-width: 100% !important;
        border: 0 !important;
        padding: 0 !important;
        border-radius: 0 !important;
      }
    }

    .bz-purchase-class-filter {
      justify-content: space-between;
      flex-direction: row;

      >div {
        width: 48%;
      }

      .k-dropdown,
      .k-datepicker {
        width: 100%;
      }
    }

    .bzc-sessions-list-row-new {
      .section-5.redeem {
        bottom: -0.2rem;

        >.bzc-session-price {
          align-items: flex-start;
        }
      }
    }
  }

  h5.bz-avail-providers {
    // margin-top: 1rem;
  }

  .bz-scheduleAppt-new {
    >div {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .bz-classList-container.k-card>.k-card-body .k-listview .bz-groupServices-list-new .section-1 {
    max-width: none;
  }

  .bz-staff-details .mb-3.mb-sm-0.staff-name-blk:first-child {
    flex-basis: auto;
  }
}

@media screen and (min-width:601px) and (max-width:960px) {
  .bz-group-scheduled {
    .left-filter {
      >div {
        display: flex;
        align-items: center;
        justify-content: space-between;

        >div {
          width: 48%;

          >.bz-row {
            display: none !important;
          }

          .clientautocomplete {
            margin-bottom: 0 !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width:960px) {
  .bz-group-scheduled {
    .groupappadmin {
      .checkout-panelbar-wrapper {
        padding-left: 0;
        padding-right: 0;
      }

      >.row {
        .bz-checkout-page {
          .payment-panel-form {
            padding-left: 0;
            padding-right: 0;
          }

          .order-summary-form {
            padding-left: 12px;
            padding-right: 12px;
          }
        }
      }
    }
  }
}

@media screen and (min-width:961px) {
  .bz-group-scheduled {
    .left-filter {
      width: 300px;

      >div {
        border: 1px solid rgb(219, 223, 234);
        padding: 15px;
        border-radius: 10px;
        height: 90vh;
        position: fixed;
        width: 100%;
        max-width: 290px;
      }
    }

    .bz-checkout-page {
      padding-left: 0;
    }
  }

  .purchasegrouplisting {
    padding-left: 1rem;
  }
}

@media screen and (min-width:961px) and (max-width:1249px) {}

@media screen and (max-width: 575px) {
  .buzops-fullcalendar .fc .fc-toolbar.fc-header-toolbar {
    top: 77px;
  }

}

@media (min-width:600px) and (max-width:1166px) {

  .bz-classList-container.k-card>.k-card-body .k-listview .bz-packages-list-new .section-7 {
    position: absolute;
    top: 37px;
    right: 12px;
  }
}

// footer handling CSS
@media (max-width:1200px) {
  .public-layout .appsidebaronlinepage.App-body {
    padding-bottom: 0px !important;
  }
}

@media (min-width:1200px) {
  .appsidebaronlinepage.App-body {
    height: calc(100% - 320px);
    padding-bottom: 2rem !important;
  }

  .bz-footer-adjustment {
    min-height: calc(100vh - 320px - 380px);
  }
}

@media (max-width:1199px) {
  .appsidebaronlinepage.App-body {
    height: calc(100% - 320px);
  }

  .bz-footer-adjustment {
    min-height: calc(100vh - 320px - 350px);
  }
}

@media (min-width:769px) and (max-width:991px) {
  .bz-footer-adjustment {
    min-height: calc(100vh - 502px - 350px);
  }
}

@media (min-width:601px) and (max-width:768px) {
  .bz-footer-adjustment {
    min-height: calc(100vh - 580px - 420px);
  }
}

@media (min-width:576px) and (max-width:600px) {
  .bz-footer-adjustment {
    min-height: calc(100vh - 548px - 420px);
  }
}

@media (min-width:381px) and (max-width:575px) {
  .bz-footer-adjustment {
    min-height: calc(100vh - 596px - 420px);
  }
}

@media (max-width:380px) {
  .appsidebaronlinepage.App-body {
    height: auto;
  }
}