.react-form-builder-form {

  .rfb-item {
    label {
      span {
        // background: white;
        font-weight: 600;
        font-size: 13px;

        color: #071437;
        // padding: 0 10px;
      }

      span.label-required.asterisk {
        color: red;
      }

    }

    label.MultiRowLabel {
      span {
        background: #ffffff;
        padding: 0px 0px;

      }
    }

    .row {
      padding: 0px;
      border: 0px solid #d4d4d4;
      border-radius: 10px;
      margin-top: 0px;
    }
  }
}

.react-form-builder {
  max-height: 88vh;
  overflow: hidden;

  .react-form-builder-preview {
    // position: relative;
    // width: 70%;
    border: 1px solid #ddd;
    background: #fafafa;
    padding: 10px;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
    min-height: 85vh;
    width: 100%;

    .preview-content {
      max-height: 90vh;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .preview-page-break {
      padding: 5px 0;
      border-top: 2px dotted #ccc;
      border-bottom: 2px dotted #ccc;
      background: #eee;
      text-align: center;
      width: 100%;
      font-weight: bold;
      margin: 5px 0;
      background: repeating-linear-gradient(45deg,
          #fff,
          #fff 10px,
          #eee 10px,
          #eee 20px);
    }

    label {
      font-weight: normal;
    }

    .bold {
      font-weight: bold;
    }

    .italic {
      font-style: italic;
    }

    .no-image {
      background: #eee;
      width: 100px;
      height: 100px;
      border: 1px solid #ddd;
      text-align: center;
      padding-top: 35px;
    }

    .option-inline {
      display: inline-block !important;
      margin-right: 10px !important;
    }

    .form-label {
      display: block !important;
    }

    .edit-form {
      position: fixed;
      background: #fafafa;
      padding: 30px;
      border-left: 0;
      box-shadow: none;
      top: 0;
      left: -1000px;
      height: 100%;
      // width: 0;
      width: 715px;
      transition: .5s;
      overflow-y: auto;

      h4,
      .dismiss-edit {
        margin-bottom: 30px;
      }

      .dismiss-edit {
        cursor: pointer;
        margin-top: 10px;
        font-size: 125%;
      }

      textarea {
        width: 100%;
      }

      .form-group {
        .form-group-range {
          label {
            display: block;
          }

          input {
            display: inline-block;
            width: 21%;
            margin-left: 0;

            &:last-of-type {
              width: 78%;
              margin-left: 1%;
            }

            &:first-of-type {
              width: 21%;
              margin-left: 0;
            }
          }
        }
      }

      .dynamic-option-list {
        margin-bottom: 15px;

        ul {
          list-style-type: none;
          margin: 0;
          padding: 0;

          li {
            margin-top: 10px;

            input {
              &.form-control {
                width: 84.26%;
                float: left;

                &[type="checkbox"] {
                  border: none;
                  box-shadow: none;
                  margin-top: 0;
                }
              }

              margin-right: 10px;
            }

            button {
              float: left;
              margin-right: 5px;
            }

            .dynamic-options-actions-buttons {
              margin-left: 20px;
            }
          }
        }
      }
    }

    .Sortable {
      transition: opacity .25s ease-in;
      position: relative;
      cursor: move;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      .rfb-item {

        padding: 10px 20px;

        &.SortableItem {
          position: relative;

          .form-group {
            pointer-events: none;

            label,
            select,
            input,
            a {
              cursor: move;
            }

            input[type='date'] {
              height: 42px;
            }

            .react-star-rating {
              display: block;
            }

            .checkbox-label,
            .radio-label {
              font-weight: normal;
              display: block;
            }

            .label-required {
              margin-left: 5px;
            }
          }

          &.is-dragging {
            position: absolute;
            z-index: 1688;
            border: 2px #ccc dashed;
            background: #fff;
            cursor: move;
            padding: 10px 20px;
          }
        }

        &.is-placeholder {
          display: block;
          z-index: auto;
          opacity: .4;
          border: 2px #ccc dashed;
        }

        .toolbar-header {
          opacity: 0; // visible
          z-index: 100;
          top: 13px;
          position: relative;

          .badge {
            margin: 0;
          }

          .label {
            position: absolute;
            left: 20px;
          }

          .toolbar-header-buttons {
            position: absolute;
            right: 0px;
            margin-top: -20px;
          }
        }

        &:hover {
          box-shadow: inset 0 0 5px #ddd;
          background: #fff;

          select,
          input {
            cursor: move;
            pointer-events: none;
          }

          .toolbar-header {
            opacity: 1;
          }
        }
      }
    }

    .is-editing {

      // .Sortable {
      //   opacity: .5;
      //   transition: opacity .25s ease-in;
      // }
      .edit-form {
        z-index: 2000;
        border-right: 1px solid #ddd;
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
        // width: 80%;
        left: 0;
        transition: .5s;
      }
    }
  }

  .react-form-builder-toolbar {
    width: 100%;
    background: #fff;
    // margin-top: -30px;

    h4 {
      margin-top: 0;
      text-align: center;
    }

    .form-group {
      padding: 10px;
      border: 0px solid #008db9;
    }

    ul {
      padding: 0;

      li {
        cursor: pointer;
        list-style: none;
        margin: 5px;
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;

        i {
          margin: 0 15px 0 10px;
        }
      }
    }

    .FieldTemplateList {
      max-height: 80vh;
      overflow: auto;

      .FieldTemplateItems {
        display: grid;
        grid-template-columns: 1fr 1fr;
        font-size: 10px;

        li.FieldTemplateItem {
          background-color: #fff;
          color: #008DB9;
          font-weight: 600;
        }
      }
    }
  }
}

.react-form-builder .react-form-builder-toolbar ul.k-tabstrip-items li {
  border-radius: 0;
  width: 46%;
  padding: 4px !important;
}

.image-upload-container {
  position: relative;
  height: 175px;

  .image-upload {
    position: relative;
    opacity: 0;
    z-index: 2;
    height: 55px;
    width: 90%;
  }

  .image-upload-control {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    margin-bottom: 15px;
    width: 100%;
  }
}

.image-upload-preview {
  border: 4px solid #fff;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
}

.file-upload-preview {
  border: 1px dashed #a8adaf;
  padding: 10px;
  background-color: rgba(0, 110, 255, 0.041);
  border-radius: 10px;
}

.btn-image-clear {
  margin: 15px 0;
  padding: 15px;
}

.btn-file-upload-clear {
  margin: auto;
  padding: 10px;
}

@media (max-width: $screen-sm-min ) {

  .formcreateintake .col-sm-12 {
    margin-bottom: 10px;
  }

  .react-form-builder {
    position: relative;
    max-height: none;

    .react-form-builder-preview {
      width: 100%;

      .edit-form {
        width: 100%;
        overflow: scroll;

        .btn {
          margin-top: 10px;
        }

        .dynamic-option-list ul li input.form-control {
          width: 100%;
        }
      }

      .Sortable .rfb-item .toolbar-header .toolbar-header-buttons {
        right: -15px;

        .btn {
          margin-right: 5px;
          border-radius: 50%;
          padding: 7px 0px 0 3px;
          width: 35px;
          height: 35px;
        }
      }
    }

    .react-form-builder-toolbar {

      h4 {
        padding: 10px;
        background: #7a0019;
        color: #fff;
      }

      width: 100%;
      // position: fixed;
      z-index: 900;
      padding: 0px;

      -webkit-transition: transform 0.5s;
      transition: transform 0.5s;

      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);

      right: 0;
      border-top: 2px solid #ccc;

      ul {
        max-height: none;
        // overflow-y: scroll;
      }
    }

    .active-toolbar {
      -webkit-transform: translate(0, -300px);
      transform: translate(0, -300px);
    }
  }

  .m-signature-pad {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    min-width: 250px;
    min-height: 140px;
    margin: 0;
  }
}

.visible_marks {
  display: block;
  width: 100%;
  padding: 0 4px;

  label {
    text-align: center;
    display: inline-block;
    padding: 0;
    margin: 0;
    font-weight: normal;

    &:first-of-type {
      text-align: left;
    }

    &:last-of-type {
      text-align: right;
    }
  }
}

.m-signature-pad {
  font-size: 10px;
  width: auto;

  .m-signature-pad--body {
    width: auto;
    height: 200px;
    border: 1px solid #e8e8e8;
    background-color: #fff;
    height: 200px;
    border-radius: 4px;

    canvas {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.02) inset;
    }
  }

  .m-signature-pad--footer {
    position: relative;
    margin-top: 10px;
  }
}

.form-place-holder {
  margin: 0px;
  border: 2px dashed #bbb;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  padding: 25px;
  text-align: center;
  font: 20pt bold, "Vollkorn";
  color: #bbb
}

.form-place-holder-hidden {
  min-height: 50px;
}

.required-asterisk {
  color: red !important;
}


.viewFormBuilder {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 5px;
  align-items: center;
  background: #f8f9fa !important;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
}

.sidebarrightheading {
  font-weight: 600;
}

.fieldslabels {
  font-size: 13px !important;
}

.fieldstabs li {
  width: 50%;
}

.formlistheading {
  font-size: 12px !important;
  font-weight: 700 !important;
}

.actiobtnform {
  background-color: transparent;
  border-color: transparent;
  outline-color: transparent;
  outline-width: 0px !important;
  display: flex;
}

.action-icons {
  >div {
    display: inline-flex;
  }

  .actiobtnform {
    &:hover {

      svg,
      i {
        opacity: 0.6;
      }
    }
  }
}

.actiobtnform img {
  width: 18px;
  margin-right: 5px;
}

.bz-classList-container.k-card>.k-card-body .k-listview .bz-forms-list-new.formlist {
  grid-template-columns: 1.7fr 1fr 1fr 1.5fr 1.2fr 2fr 1.5fr auto;
  border-color: #eaeaea !important;
  box-shadow: none !important;
}

.bz-main-provider>.bz-provider-name>h6.responses {
  text-align: center;
  color: #008DB9 !important;
  font-weight: 600;
}

span.responses {
  text-align: center;
  color: #008DB9 !important;
  font-weight: 600;
}

.bz-hyperlink {
  text-align: center;
  color: #008DB9 !important;
  font-weight: 600;
  text-decoration: underline;
}

.ft600 {
  font-weight: 600;
}

.bz-classList-container.k-card>.k-card-body .k-listview .bz-saved-forms-list-new.responselist {
  grid-template-columns: 2fr 2fr 1fr 1fr 2fr auto;
  border-color: #eaeaea !important;
  box-shadow: none !important;
}

.bz-classList-container.k-card>.k-card-body .k-listview .bz-saved-forms-list-new.responselist.bz-saved-forms-referral {

  grid-template-columns: 2fr 1.5fr 1fr 1fr 1fr 2fr auto;
  align-items: start;
}

.react-form-builder-toolbar .k-tabstrip-top>.k-tabstrip-items-wrapper .k-item.k-state-active .k-link {
  color: #008db9
}

.disable-shortcode .actiobtnform,
.disable-shortcode small {
  cursor: none;
  opacity: .4;
}

.react-form-builder .k-tabstrip-items {
  display: flex !important;
}

.borderfiledadd {
  border: 1px solid #000;
  padding: 2px;
  font-size: 18px;
  border-radius: 2px;
}

.react-form-builder-toolbar .k-tabstrip-top>.k-tabstrip-items-wrapper .k-item.k-state-active .borderfiledadd {
  border: 1px solid #008db9;
}

.editformbuzops {
  color: #737070
}

.deleteformbuzops {
  color: #ed1716
}

.bzo-class-window .k-button.preview {
  background: transparent;
  border: 0px solid #d9d9d9;
  color: #555555;
  border-right: 1px solid #d1cdce;
  margin: 0;
}

.bzo-class-window .preveiwform {
  background: #f8f8f8;
  border: 1px solid #d9d9d9;
  color: #555555;
  border-radius: 5px;
  display: flex;
  min-height: 35px;
}

.bzo-class-window .draftbtn .k-button {
  margin: 0;
  border: 0px solid transparent;
  background-color: #eeeeee !important;
  color: #555555 !important;
}

.react-form-builder-toolbar .form-group label {
  font-size: 13px;
}

.bz-form-response-table {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  border: 1px solid #eee;

  div {
    padding: 7px;
  }
}

.bz-form-response-table .form-description,
.bz-form-response-table .form-values {
  width: 50%;
}

.bz-form-response-table .form-values {
  border-left: 2px solid #eee;
}

.signform {
  width: 200px;
}

.btnformsuccess {
  padding: 0px 5px !important;
  box-shadow: none !important;
  background-color: #0268a9 !important;
  border-radius: 50px;
  margin-right: 5px;
}

.btnformdanger {
  padding: 0px 5px !important;
  box-shadow: none !important;
  background-color: #d73539 !important;
  border-radius: 50px;
  margin-right: 5px;
}

.dynamic-options-actions-buttons {
  padding: 5px 0;
}

@media (min-width:768px) and (max-width:1024px) {
  .react-form-builder .react-form-builder-toolbar ul li {
    padding: 4px;
    font-size: 12px !important;
  }

  .react-form-builder .react-form-builder-preview .Sortable .rfb-item.SortableItem .form-group input {
    height: 35px;
  }

  .bz-classList-container.k-card>.k-card-body .k-listview .bz-forms-list-new.formlist {
    grid-template-columns: 1fr 1fr 1fr;

    .bz-main-provider>.bz-provider-name {
      padding-left: 0;
    }
  }

  .react-form-builder .react-form-builder-toolbar .FieldTemplateList .FieldTemplateItems {
    grid-template-columns: 1fr;
  }

  .bz-price-section.pl-10 {
    padding-left: 0;
  }
}

.bz-classList-container.k-card>.k-card-body .k-listview .bz-forms-list-new {
  max-height: none;
}

@media (min-width:480px) and (max-width:768px) {
  .bz-classList-container.k-card>.k-card-body .k-listview .bz-forms-list-new.formlist {
    grid-template-columns: 1fr 1fr;

    .bz-main-provider>.bz-provider-name {
      padding-left: 0;
    }

  }

  .bz-price-section.pl-10 {
    padding-left: 0;
  }


  .react-form-builder .react-form-builder-toolbar ul li {
    padding: 4px;
    font-size: 12px !important;
  }

  .react-form-builder .react-form-builder-preview .Sortable .rfb-item.SortableItem .form-group input {
    height: 35px;
  }

  .react-form-builder .react-form-builder-toolbar .FieldTemplateList .FieldTemplateItems {
    grid-template-columns: 1fr;
  }



}

@media screen and (min-width:320px) and (max-width:480px) {

  .bz-classList-container.k-card>.k-card-body .k-listview .bz-forms-list-new.formlist {
    grid-template-columns: 1fr;

    .bz-main-provider>.bz-provider-name {
      padding-left: 0;
    }
  }

  .react-form-builder .react-form-builder-toolbar .FieldTemplateList .FieldTemplateItems {
    grid-template-columns: 1fr;
  }

  .react-form-builder .react-form-builder-toolbar ul li {
    padding: 4px;
    font-size: 12px !important;
  }

  .react-form-builder .react-form-builder-preview .Sortable .rfb-item.SortableItem .form-group input {
    height: 35px;
  }
}

.online-form-generator textarea.form-control {
  height: auto !important;
}

.online-form-generator .form-control {
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  color: #4b5675;
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .425rem;
  box-shadow: false;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.online-form-generator .form-control {
  font-size: 14px !important;
}

.online-form-generator .form-control:focus {

  border-color: #9da4b9;
  color: #4b5675;
  transition: color .2s ease;
}

.react-form-builder-form {
  padding: 0 10px;
  min-height: 450px;
}

.react-form-builder-form .rfb-item label {
  padding: 0;
}

.borderheadingfroms {
  border-bottom: 1px solid #EBEDF3;
  padding: 10px;
  margin-bottom: 25px;
  margin-top: 10px;
}

.borderlogoforms {
  border-right: 1px solid #eee;
}

.react-form-builder-form .form-group {
  display: flex;
  margin-bottom: 0;
  flex-direction: column;
}


.react-form-builder-form .col-md-6.MultiRowLabel {
  width: 100%;
}

.react-form-builder-form .form-control {
  font-size: 14px !important;
  width: 100%;
}

.w-70 {
  width: 100% !important;
}

.react-datepicker-wrapper .form-control {
  width: 100%;
}

.pl-10 {
  padding-left: 10px;
}

.react-form-builder-form .rfb-item label.custom-control-label {
  width: 100%;
}

.image-upload-control {
  text-align: center;
  background-color: rgba(0, 110, 255, 0.041);
  padding: 10px;
  border-radius: 10px;
}

.btnuploadform {
  width: 100%;
  border: 1px dashed #a8adaf;
  height: 100px;
  border-radius: 10px;
}

.uploadformicon svg {
  height: 50px;
  display: block;
  margin: auto;
}

.formuploadpar {
  background: rgba(0, 110, 255, 0.075);
  padding: 5px;
  border-radius: 10px;
  margin-top: 9px;
  margin-bottom: 0;
}

.custom-control {
  padding-right: 1.5rem;
}

.SortableItem.rfb-item .col-md-6.MultiRowLabel:first-child>span {
  font-weight: 600 !important;
}

@media screen and (max-width: 768px) {
  .formradioresposive {
    display: flex;
    flex-direction: column;
  }
}

.react-form-builder-preview .selectcontainer {
  width: 100%;
}

.selectcontainer {
  width: 100%;

}

.react-form-builder-form .selectcontainer select.form-control {
  width: 100%;
}

@media screen and (max-width: 480px) {
  .react-form-builder-form .selectcontainer {
    width: 100%;
  }
}

.bz-classList-container.k-card>.k-card-body .k-listview .bz-saved-forms-list-new.responselist.general-response-grid {
  grid-template-columns: 2fr 2fr 1fr 1fr auto;
}

@media screen and (max-width: 1024px) {
  .bz-classList-container.k-card>.k-card-body .k-listview .bz-saved-forms-list-new.responselist.bz-saved-forms-referral {
    grid-template-columns: 1fr 1fr 1fr;
    max-height: none;
  }

  .bz-main-provider>.bz-provider-name {
    padding-left: 0;
  }

  .bz-classList-container.k-card>.k-card-body .k-listview .bz-saved-forms-list-new.responselist.general-response-grid {
    grid-template-columns: 1fr 1fr;
    max-height: none;

    .section-5 {
      padding: 7px;
      position: static;
      margin-bottom: 0;
    }
  }

  .bz-classList-container.k-card>.k-card-body .k-listview .bz-saved-forms-list-new.responselist.bz-saved-forms-referral {
    .section-5.icons {
      padding-top: 10px;
      position: static;

      .k-button {
        margin-left: 0;
      }
    }
  }
}


@media screen and (max-width: 768px) {
  .bz-classList-container.k-card>.k-card-body .k-listview .bz-saved-forms-list-new.responselist.bz-saved-forms-referral {
    grid-template-columns: 1fr;

  }

  .bz-classList-container.k-card>.k-card-body .k-listview .bz-saved-forms-list-new.responselist.general-response-grid {
    grid-template-columns: 1fr;
  }

  .bz-main-provider>.bz-provider-name>h6.responses {
    text-align: left;
  }

  .actiobtnform.editform {
    padding-left: 0;
  }

  .bz-price-section.pl-10 {
    padding-left: 0 !important;
  }

}

.responsesfilters .bz-date-filter-textBox {
  height: 35px;
}

@media screen and (max-width: 680px) {
  .responsesfilters {
    gap: 0px;
    display: block !important;
  }

  .filterdates,
  .bz-date-filter-main,

  .bz-date-filter-main>.bz-dateFilter-container {
    width: 100%;
    margin: 0 !important;
  }

  .version-dropdown {

    display: inline-block;

    .k-widget.k-dropdown {
      width: 100px !important;
    }
  }

  .download {
    float: right;
    margin-top: 20px;
  }

  .sorllist,
  .sorlistdp {
    margin-left: 0 !important;
  }

  .group-event-flex a.sorlistdp {
    margin-right: 0 !important;
  }
}

@media screen and (max-width: 768px) {
  .bz-classList-container.k-card>.k-card-header.formsaddveiw>.bz-classes-action.group-event-flex {
    display: block;
    width: 100%;
  }

  .sorllist,
  .sorlistdp {
    margin-left: 0 !important;
  }

  .group-event-flex a.sorlistdp {
    margin-right: 0 !important;
  }

  .bz-classList-container.k-card>.formsaddveiw.k-card-header>.bz-classes-action.group-event-flex .group-event-top-blk {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  .formlistdp .k-widget.k-dropdown,
  .sorllist .k-widget.k-dropdown {
    width: 100%;
  }

  .formsaddveiw .bz-classes-action .bz-classes-search {
    margin: 0 !important;
  }
}

@media screen and (max-width: 480px) {
  .bz-classList-container.k-card>.formsaddveiw.k-card-header>.bz-classes-action.group-event-flex .group-event-top-blk {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }
}

.form-categoryheading {
  color: #071437;
  font-size: 18px;
  font-weight: 600;
}

.form-category-item-sharedlink {


  padding: 15px;
  border: 1px solid #F1F1F4;
  border-radius: 8px;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  gap: 0px;
}

.k-button.k-primary.sharedlinkcopy {
  background: #f1f1f4 !important;
  border-color: #f1f1f4 !important;
  color: #071437 !important;
}

.sharedlinkinput.k-textbox,
.sharedlinkinput.k-textarea>.k-input {
  min-height: 40px;
  border-color: #dbdfe9 !important;
  color: #071437;
  font-size: 12px !important;
  font-weight: 600 !important;
}

.previewlinktab {
  color: #008DB9;
  font-size: 12px;
}

@media screen and (min-width:1173px) and (max-width: 1600px) {
  .bz-classList-container.k-card {
    >.k-card-body {
      .k-listview {
        .bz-forms-list-new.bz-forms-list {
          grid-template-columns: 10rem 9rem 6rem 8.5rem 10.5rem 11rem 9rem auto;
          justify-content: space-between;
        }
      }
    }
  }
}

@media screen and (min-width:1025px) and (max-width: 1172px) {
  .bz-classList-container.k-card {
    >.k-card-body {
      .k-listview {
        .bz-forms-list-new.bz-forms-list {
          grid-template-columns: 1fr 1fr 1fr 0.8fr;
          grid-row-gap: 0.5rem;

          .bz-provider-name {
            padding-left: 0;
          }

          .section-2 {
            .responses {
              text-align: left;
            }
          }

          .catagory {
            grid-area: 2 / 1 / 3 / 2;
          }

          .status {
            grid-area: 2 / 4 / 3 / 5;

            .bz-price-section {
              padding-left: 0 !important;
            }
          }

          .date {
            grid-area: 1 / 4 / 2 / 5;
          }

          .responses {
            grid-area: 1 / 2 / 2 / 3;
          }

          .versions {
            grid-area: 2 / 2 / 3 / 3;
          }

          .shotcode {
            grid-area: 1 / 3 / 2 / 4;

            .actiobtnform {
              padding-left: 0;
              margin-right: 0.25rem;
            }
          }

          .action {
            grid-area: 2 / 3 / 3 / 4;
          }
        }
      }
    }
  }
}